// FormElements.jsx

import React from 'react';
import {
    Formik,
    Form as FormikForm,
    Field,
    ErrorMessage,
    useFormikContext,
} from 'formik';
import * as utils from '../store/Utils';
import { Input, Button, Grid, TextField, Select, MenuItem, InputLabel, TextareaAutosize } from '@mui/material';
import moment from 'moment';

export function Form(props) {
    return (
        <Formik
            {...props}
        >
            <FormikForm className="needs-validation" noValidate="">
                {props.children}
            </FormikForm>
        </Formik>)
}

export function TextField2(props) {
    const { name, label, placeholder, ...rest } = props
    return (
        <>
            <Field
                name={name}>
                {({ field, form, meta }) =>
                (
                    <TextField
                        label={label}
                        variant="standard"
                        autoFocus
                        {...field}
                        {...rest}
                    />
                )
                }
            </Field>
            <ErrorMessage name={name} render={msg => <div style={{ color: 'red' }} >{msg}</div>} />
        </>
    )
}

export function SelectField(props) {
    const { name, label, options } = props
    return (
        <>
            <InputLabel shrink id={`${name}_label`}>
                {label}
            </InputLabel>
            <Field
                name={name}>
                {({ field, form, meta }) =>
                (
                    <Select
                        labelId={`${name}_label`}
                        input={<Input />}
                        MenuProps={utils.MenuProps}
                        {...field}
                    >
                        <MenuItem value="" >Choose...</MenuItem>
                        {options.map((optn, index) =>
                            <MenuItem value={optn.value}>{optn.label || optn.value}</MenuItem>
                        )}
                    </Select>
                )
                }
            </Field>
            <ErrorMessage name={name} render={msg => <div style={{ color: 'red' }} >{msg}</div>} />
        </>
    )
}

export function TextAreaField(props) {
    const { name, label, placeholder, ...rest } = props
    return (
        <>
            <Field
                name={name}>
                {({ field, form, meta }) =>
                (
                    <TextareaAutosize
                        rowsMin={3}
                        label={label}
                        className="textarea"
                        autoFocus
                        {...field}
                        {...rest}
                    />
                )
                }
            </Field>

            <ErrorMessage name={name} render={msg => <div style={{ color: 'red' }} >{msg}</div>} />
        </>
    )
}

export function MoneyField(props) {
    const { name, label, placeholder, options, ...rest } = props
    return (
        <>
            <Grid container alignItems="flex-end">
                <Grid item xs={6}>
                    <Field
                        name={name}>
                        {({ field, form, meta }) =>
                        (
                            <TextField
                                label={label}
                                autoFocus
                                variant="standard"
                                {...field}
                                {...rest}
                            />
                        )
                        }
                    </Field>
                    <ErrorMessage name={name} render={msg => <div style={{ color: 'red' }} >{msg}</div>} />
                </Grid>
                <Grid item xs={6}>
                    <Field
                        name={`${name}_ccy`}>
                        {({ field, form, meta }) =>
                        (
                            <Select
                                labelId={`${name}_ccy_label`}
                                input={<Input />}
                                MenuProps={utils.MenuProps}
                                {...field}
                            >
                                <MenuItem value="" ></MenuItem>
                                {options.map((optn, index) =>
                                    <MenuItem value={optn.value}>{optn.label || optn.value}</MenuItem>
                                )}
                            </Select>
                        )
                        }
                    </Field>
                    <ErrorMessage name={`${name}_ccy`} render={msg => <div style={{ color: 'red' }} >{msg}</div>} />
                </Grid>
            </Grid>
        </>
    )
}




export function DateTimeField(props) {
    const { name, label, placeholder, ...rest } = props    
    return (
        <>
            <Field
                name={name}>
                {({ field, form, meta }) => {
                field = {
                    ...field,
                    value: moment(field.value).format("YYYY-MM-DDTkk:mm")
                }
                return (                    
                    <TextField
                        label={label}
                        type="datetime-local"
                        variant="standard"
                        fullWidth
                        autoFocus
                        InputLabelProps={{
                            shrink: true,
                        }}
                        {...field}
                        {...rest}
                    />
                )
                }}
            </Field>
            <ErrorMessage name={name} render={msg => <div style={{ color: 'red' }} >{msg}</div>} />
        </>
    )
}

export function SubmitButton(props) {
    const { title, ...rest } = props;
    const { isSubmitting } = useFormikContext();

    return (
        <Button variant="contained"
            color="primary"
            type="submit" {...rest}
            disabled={isSubmitting}
        >
            {title}
        </Button>
    )
}