import * as React from 'react';
import { 
    Grid, 
    Button, 
    Input,     
    Select, 
    MenuItem,
    Box, 
    Tooltip,
    Card, 
    CardHeader,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
 } from '@mui/material';
 
import { 
    PrivacyTip as PrivacyTipIcon,
} from '@mui/icons-material';
import * as utils from '../store/Utils';
import { useAppDispatch } from '../store/configureStore';
import { useEffect, useState } from 'react';
import { useGetTransportRequestSettingsQuery, useUpdateTransportRequestAllocationMutation, useUpdateTransportRequestStatusMutation } from '../store/apiSlice';
import { setError } from '../store/ApiInterface2';
import StatusCard from './Awb/statusBar/statusCard';
import { Paper } from './StyledComponents'
import * as Models from "../models/Models";

interface PropsToPass {
    transport_id: number;
    status_list: Models.TransportRequestStatus[];
    users_list: Models.User[];
    depots_list: Models.Depot[];
    onCloseCallback(): void;
}

export const TransportRequestSettings : React.FC<PropsToPass> = (props) => {

    const dispatch = useAppDispatch();

    const [state, setState] = useState<{
        status: number,
        allocation_direct_user_id: number,
        depot: number,
        allocation_to_depot_user_id: number,
        allocation_from_depot_user_id: number,
    }>({
        status: 0,
        allocation_direct_user_id: 0,
        depot: 0,
        allocation_to_depot_user_id: 0,
        allocation_from_depot_user_id: 0,
    });

    //const params = useParams();
    //const id = params.id != null ? params.id : "0";    
    
    const { data : transport_requests_settings, isLoading, error } = useGetTransportRequestSettingsQuery(props.transport_id);
    

    const [ updateStatus ] = useUpdateTransportRequestStatusMutation();
    const [ updateAllocation ] = useUpdateTransportRequestAllocationMutation();
    

    useEffect(() => {		
		if (transport_requests_settings){
            setState((prevState) => ({ ...prevState, status: transport_requests_settings.status}))
		}
	}, [transport_requests_settings]);

    const handleStatusChanged = (event: any) => {        
        setState((prevState) => ({ ...prevState, status: event.target.value as number }));
    }

    const handleAllocationDirectUserChanged = (event: any) => {        
        setState((prevState) => ({ ...prevState, allocation_direct_user_id: event.target.value as number }));
    }

    const handleAllocationDepotChanged = (event: any) => {        
        setState((prevState) => ({ ...prevState, depot: event.target.value as number }));
    }

    const handleAllocationToDepotUserChanged = (event: any) => {        
        setState((prevState) => ({ ...prevState, allocation_to_depot_user_id: event.target.value as number }));
    }

    const handleAllocationFromDepotUserChanged = (event: any) => {        
        setState((prevState) => ({ ...prevState, allocation_from_depot_user_id: event.target.value as number }));
    }

    const handleUpdateStatus = (e: any) => {
        e.preventDefault();
        if (!window.confirm("Confirm update status?"))
            return;
        else {
            updateStatus({transport_id: props.transport_id, status: state.status});
        }
    }

    
    const handleSaveAllocationModal = (allocationType: number) => {

        if (allocationType === 1) {
            if (state.allocation_direct_user_id === 0) {
                dispatch(setError("Please select the user"))
                return;
            }
        }
        if (allocationType === 2) {
            if (state.depot === 0) {
                dispatch(setError("Please select the depot"))
                return;
            }

            if (state.allocation_to_depot_user_id === 0) {
                dispatch(setError("Please select the user to depot"))
                return;
            }

            if (state.allocation_from_depot_user_id === 0) {
                dispatch(setError("Please select the user from depot"))
                return;
            }
        }

        updateAllocation({
            transport_id: props.transport_id,
            allocation_type: allocationType,
            user_id: state.allocation_direct_user_id,
            depot_id: state.depot,
            user_id_to_depot: state.allocation_to_depot_user_id,
            user_id_from_depot: state.allocation_from_depot_user_id,
        });
    }

    if (error) return <p>Error occured</p>;
  	if (isLoading) return <p>Loading...</p>;
    if (!transport_requests_settings) return <p>Data is null...</p>;

    return (
        <React.Fragment>
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                <Box sx={{ width: '100%', p:2}}>
                    <StatusCard caller = 'transport_request' status_journal={transport_requests_settings.status_journal} sx={{px:5, pt: 3}}>							
                    </StatusCard>
                </Box>
                <Box sx={{ width: '100%', p:2 }}>
                    <Card sx={{px:3, pb: 3}}>
                        <CardHeader
                            title="Change status"
                            size="small"
                            sx={{
                                mb: 1,
                            }}				
                        />
                        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                            <Grid container spacing={5}>
                                <Grid item xs={1}>
                                    New status:
                                </Grid>
                                <Grid item xs={2}>
                                    <Select
                                        id="status"
                                        name="status"
                                        value={props.status_list && state.status ? state.status : ''}
                                        input={<Input />}
                                        MenuProps={utils.MenuProps}
                                        onChange={handleStatusChanged}
                                    >
                                        {props.status_list?.map((status, index) => (
                                            <MenuItem key={status.status} value={status.status} >
                                                {status.description}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <Button variant="contained"
                                        onClick={handleUpdateStatus}>
                                        Update
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Card>
                </Box>
                <Box sx={{ width: '100%', p:2 }}>
                    <Card sx={{px:3, pb: 3}}>
                        <CardHeader
                            title="Allocations"
                            size="small"
                            sx={{
                                mb: 1,
                            }}				
                        />
                        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                            <Grid container spacing={5}>
                                <Grid item xs={12}>
                                    <TableContainer component={Paper}>
                                        <Table aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="right">Allocation Id</TableCell>
                                                    <TableCell align="right">Allocation Method</TableCell>
                                                    <TableCell align="right">User Name</TableCell>
                                                    <TableCell align="right">Status</TableCell>
                                                    <TableCell align="right">Action Description</TableCell>
                                                    <TableCell align="right">Location Description</TableCell>
                                                    <TableCell align="right">Navigation Order</TableCell>
                                                    <TableCell align="right">Distance</TableCell>
                                                    <TableCell align="right">Duration</TableCell>
                                                    <TableCell align="right">Estimated Time Arrival</TableCell>
                                                    <TableCell align="right">Status description</TableCell>
                                                    <TableCell align="right">Details</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {transport_requests_settings.allocations.map((row: Models.Allocation) => (
                                                    <TableRow key={row.allocation_id}>
                                                        <TableCell align="right">{row.allocation_id}</TableCell>
                                                        <TableCell align="right">{row.allocation_method}</TableCell>
                                                        <TableCell align="right">{row.user_name}</TableCell>
                                                        <TableCell align="right">{row.status_description}</TableCell>
                                                        <TableCell align="right">{row.action_description}</TableCell>
                                                        <TableCell align="right">{row.location_description}</TableCell>
                                                        <TableCell align="right">{row.navigation_order}</TableCell>
                                                        <TableCell align="right">{row.distance_total}</TableCell>
                                                        <TableCell align="right">{row.duration_total}</TableCell>
                                                        <TableCell align="right">{row.estimated_time_arrival}</TableCell>
                                                        <TableCell align="right">{row.in_time_status_description}</TableCell>
                                                        <TableCell align="right">
                                                            <Tooltip
                                                                title={
                                                                    <div style={{ whiteSpace: 'pre-line' }}>
                                                                        {`Address: ${row.display_details.long_desc}
                                                                        Sender: ${row.display_details.sender_name}
                                                                        Receiver: ${row.display_details.receiver_name}
                                                                        Sender Phone: ${row.display_details.sender_contact_phone}
                                                                        Receiver Phone: ${row.display_details.receiver_contact_phone}
                                                                        Ramurs: ${row.display_details.ramburs}
                                                                        Remarks: ${row.display_details.remarks}`}
                                                                    </div>
                                                                }
                                                            >
                                                                <PrivacyTipIcon/>
                                                            </Tooltip>

                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>


                                <Grid item xs={6}>
                                    <Grid container spacing={5}>
                                        <Grid item xs={3}>
                                            Allocate direct:
                                        </Grid>
                                        <Grid item xs={9}>
                                            <Select
                                                id="allocate-active-shippers-select"
                                                value={props.users_list ? state.allocation_direct_user_id : ''}
                                                input={<Input />}
                                                MenuProps={utils.MenuProps}
                                                onChange={handleAllocationDirectUserChanged}
                                            >                                                
                                                {props.users_list?.map((user, index) => (
                                                    <MenuItem key={user.user_id} value={user.user_id} >
                                                        {user.user_name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container
                                                style={{textAlign: "center"}}>
                                                <Grid item>
                                                    <Button variant="contained"
                                                        color="primary"
                                                        onClick={(e) => handleSaveAllocationModal(1)}>
                                                        Allocate direct
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6}>
                                    <Grid container spacing={5}>
                                        <Grid item xs={3}>
                                            Depot:
                                        </Grid>
                                        <Grid item xs={9}>
                                            <Select
                                                id="allocate-depot-select"
                                                value={props.depots_list ? state.depot : ''}
                                                input={<Input />}
                                                MenuProps={utils.MenuProps}
                                                onChange={handleAllocationDepotChanged}
                                            >
                                                <MenuItem key={0} value={0}>Select the depot</MenuItem>
                                                {props.depots_list.map((depot, index) => (
                                                    <MenuItem key={depot.depot_id} value={depot.depot_id} >
                                                        {depot.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={3}>
                                            User to depot:
                                        </Grid>
                                        <Grid item xs={9}>
                                            <Select
                                                id="allocate-user-to-depot"
                                                value={props.users_list ? state.allocation_to_depot_user_id : ''}
                                                input={<Input />}
                                                MenuProps={utils.MenuProps}
                                                onChange={handleAllocationToDepotUserChanged}
                                            >                                                
                                                {props.users_list?.map((user, index) => (
                                                    <MenuItem key={user.user_id} value={user.user_id} >
                                                        {user.user_name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </Grid>

                                        <Grid item xs={3}>
                                            User from depot:
                                        </Grid>
                                        <Grid item xs={9}>
                                            <Select
                                                id="allocate-user-from-depot"
                                                value={props.users_list ? state.allocation_from_depot_user_id : ''}
                                                input={<Input />}
                                                MenuProps={utils.MenuProps}
                                                onChange={handleAllocationFromDepotUserChanged}
                                            >                                                
                                                {props.users_list?.map((user, index) => (
                                                    <MenuItem key={user.user_id} value={user.user_id} >
                                                        {user.user_name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container
                                                style={{textAlign: "center"}}>
                                                <Grid item>
                                                    <Button variant="contained"
                                                        color="primary"
                                                        onClick={(e) => handleSaveAllocationModal(2)}>
                                                        Allocate through depot
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>


                            </Grid>
                        </Box>
                    </Card>
                </Box>
            </Box>
        </React.Fragment>
    );
}

export default TransportRequestSettings;
