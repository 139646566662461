import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import * as ApiInterface from './ApiInterface';
import { http_axios_get, http_axios_post } from './ApiInterface';
import * as Models from "../models/Models";
import * as utils from './Utils';
import { GridRowSelectionModel } from '@mui/x-data-grid';
import * as Documents from './Documents'

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface SettlementsState {
    navigateMore?: any;
    searchData: SearchData;
    searchResult: SearchResult;
    userList: Models.User[];
    pageSizeList: Models.ListValue[];    
    shopList: Models.Shop[];
    settlementStatus: SettlementStatus[];
    isLoading: boolean;
    isLoaded: boolean;
    isLoadingSearchBox: boolean;
    isLoadedSearchBox: boolean;
    triggerSearch: boolean;
    triggerRefresh: boolean;
}

export class SettlementStatus {
    status: number = 0;
    description: string = "";
}

export class SearchData extends Models.SearchPaginationInput {    
    status: number = 0;
    start_date: string = "";
    end_date: string = "";
    time_zone_offset: string = "";
    sender_name: string = "";
    user_id: number = 0;
    shop_id: number = 0;
    selectionModel: GridRowSelectionModel = [];
}

export class Settlement {
    id: number = 0;
    settlement_id: number = 0;
    transport_id: number = 0;
    shop_name: string = "";
    user_name: string = "";
    status: string = "";
    amount: string = "";
    amount_ccy: string = "";
    details: string = "";
    ins_date: string = "";
}

export class SearchResult extends Models.SearchPaginationResult {
    Data: Settlement[] = [];
}


// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.


export interface RequestDataAction {
    type: 'SETTLEMENTS_REQUEST_DATA';
    searchData: SearchData;
}
export interface ReceiveDataAction {
    type: 'SETTLEMENTS_RECEIVE_DATA';
    searchResult: SearchResult;
}

export interface RequestFiltersAction {
    type: 'SETTLEMENTS_FILTERS';
}

export interface RequestChangeStatusAction {
    type: 'SETTLEMENTS_REQUEST_CHANGE_STATUS';
}

export interface ReceiveFiltersAction {
    type: 'SETTLEMENTS_RECEIVE_FILTERS';
    userList: Models.User[];
    pageSizeList: Models.ListValue[];    
    shopList: Models.Shop[];
    settlementStatus: SettlementStatus[];
}

export interface ResetTriggerSearch {
    type: 'SETTLEMENTS_RESET_TRIGGER_SEARCH';
}

export interface SetTriggerRefresh {
    type: 'SETTLEMENTS_SET_TRIGGER_REFRESH';
}

export interface ResetTriggerRefresh {    
    type: 'SETTLEMENTS_RESET_TRIGGER_REFRESH';
}


// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = RequestDataAction |
    ReceiveDataAction |
    RequestFiltersAction |
    ReceiveFiltersAction |
    RequestChangeStatusAction |
    ResetTriggerSearch |
    SetTriggerRefresh |
    ResetTriggerRefresh;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).


export const actionCreators = {
    ...ApiInterface.actionCreators,

    requestFilters: (): AppThunkAction<KnownAction> => (dispatch, getState) => {

        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState) {
            //https://stackoverflow.com/questions/35297446/best-es6-way-to-get-name-based-results-with-promise-all

            Promise.all([
                http_axios_get('api/Settlements/GetUsers')
                    .then(users => {
                        return users;
                    }),
                http_axios_get('api/Lists/3')
                    .then(pageSize => {
                        return pageSize;
                    }),
                http_axios_get('api/Shops/GetAll/0/ALL')
                    .then(list => {
                        return list;
                    }),
                http_axios_get('api/SettlementsStatus')
                    .then(status => {
                        return status;
                    })
            ]).then(([users, pageSize, shops, status]) => {
                const usrList = users as Models.User[];
                const pageSizeList = pageSize as Models.ListValue[];
                const shopsList = shops as Models.Shop[];
                const statusList = status as SettlementStatus[];

                dispatch({
                    type: 'SETTLEMENTS_RECEIVE_FILTERS',
                    userList: usrList,
                    pageSizeList: pageSizeList,
                    shopList: shopsList,
                    settlementStatus: statusList
                });
            })
                .catch(function (e) {
                    console.log(e);
                });

            dispatch({ type: 'SETTLEMENTS_FILTERS' });
        }

    },
    requestData: (data: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)

        const appState = getState();
        if (appState) {

            var payload = JSON.stringify(data);

            http_axios_post('api/Settlements', payload)
                .then(response => {
                    return response as Promise<SearchResult>;
                })
                .then(data => {                    
                    dispatch({ type: 'SETTLEMENTS_RECEIVE_DATA', searchResult: data });
                })
                .catch(function (e) {
                    console.log(e);
                });
            
            var searchData = Object.assign(new SearchData(), data);
            dispatch({ type: 'SETTLEMENTS_REQUEST_DATA', searchData });
        }
    },
    refreshData: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)

        const appState = getState();
        if (appState) {

            var searchData: SearchData;
            searchData = Object.assign({}, appState.settlements?.searchData);

            var payload = JSON.stringify(searchData);

            http_axios_post('api/Settlements', payload)
                .then(response => {
                    return response as Promise<SearchResult>;
                })
                .then(data => {
                    dispatch({ type: 'SETTLEMENTS_RECEIVE_DATA', searchResult: data });
                })
                .catch(function (e) {
                    console.log(e);
                });
            
            searchData = Object.assign(new SearchData(), appState.settlements?.searchData);
            dispatch({ type: 'SETTLEMENTS_REQUEST_DATA', searchData });
        }
    },
    changeStatus: (data: any, status: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)

        const appState = getState();
        if (appState) {

            var obj = { settlements_ids: data, status: status };
            var payload = JSON.stringify(obj);


            http_axios_post('api/SettlementsStatus', payload)
                .then(response => {
                    dispatch({ type: 'SETTLEMENTS_SET_TRIGGER_REFRESH' });
                    

                    const untypedDispath = dispatch as any;
                    untypedDispath(Documents.actionCreators.setTriggerRefresh());

                })
                .catch(function (e) {
                    console.log(e);
                });
            
            dispatch({ type: 'SETTLEMENTS_REQUEST_CHANGE_STATUS' });
        }
    },    
    resetTriggerTripsSearch: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState) {
            dispatch({ type: 'SETTLEMENTS_RESET_TRIGGER_SEARCH' });
        }
    },
    resetTriggerRefresh: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState) {
            dispatch({ type: 'SETTLEMENTS_RESET_TRIGGER_REFRESH' });
        }
    },
    setTriggerRefresh: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState) {
            dispatch({ type: 'SETTLEMENTS_SET_TRIGGER_REFRESH' });
        }
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: SettlementsState = {
    searchData: {
        ...Models.getSearchPaginationInputDefaultValues(),
        status: 0,
        start_date: utils.getDateISO(-1),
        end_date: utils.getDateISO(),
        time_zone_offset: utils.getTimezoneOffset().toString(),
        sender_name: "",
        user_id: 0,
        shop_id: 0,
        selectionModel: []
    },
    searchResult: {
        ...Models.getSearchPaginationResultDefaultValues(),
        Data: []
    },
    userList: [],
    pageSizeList: [],    
    shopList: [],
    settlementStatus: [],
    isLoading: false,
    isLoaded: false,
    isLoadingSearchBox: false,
    isLoadedSearchBox: false,
    triggerSearch: false,
    triggerRefresh: false,

};

export const reducer: Reducer<SettlementsState> = (state: SettlementsState | undefined, incomingAction: Action): SettlementsState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'SETTLEMENTS_REQUEST_DATA':
            return {
                searchData: action.searchData,
                searchResult: state.searchResult,
                userList: state.userList,
                pageSizeList: state.pageSizeList,
                shopList: state.shopList,
                settlementStatus: state.settlementStatus,
                isLoading: true,
                isLoaded: false,
                isLoadingSearchBox: state.isLoadingSearchBox,
                isLoadedSearchBox: state.isLoadedSearchBox,
                triggerSearch: state.triggerSearch,
                triggerRefresh: state.triggerRefresh
            };
        case 'SETTLEMENTS_RECEIVE_DATA':
            return {
                searchData: state.searchData,
                searchResult: action.searchResult,
                userList: state.userList,
                pageSizeList: state.pageSizeList,
                shopList: state.shopList,
                settlementStatus: state.settlementStatus,
                isLoading: false,
                isLoaded: true,
                isLoadingSearchBox: state.isLoadingSearchBox,
                isLoadedSearchBox: state.isLoadedSearchBox,
                triggerSearch: state.triggerSearch,
                triggerRefresh: state.triggerRefresh,
            };
        case 'SETTLEMENTS_FILTERS':
            return {
                searchData: state.searchData,
                searchResult: state.searchResult,
                userList: state.userList,
                pageSizeList: state.pageSizeList,
                shopList: state.shopList,
                settlementStatus: state.settlementStatus,
                isLoadingSearchBox: true,
                isLoadedSearchBox: false,
                isLoading: state.isLoading,
                isLoaded: state.isLoaded,
                triggerSearch: state.triggerSearch,
                triggerRefresh: state.triggerRefresh,
            };
        case 'SETTLEMENTS_RECEIVE_FILTERS':
            return {
                searchData: state.searchData,
                searchResult: state.searchResult,
                userList: action.userList,
                pageSizeList: action.pageSizeList,
                shopList: action.shopList,
                settlementStatus: action.settlementStatus,
                isLoadingSearchBox: false,
                isLoadedSearchBox: true,
                isLoading: state.isLoading,
                isLoaded: state.isLoaded,
                triggerSearch: true,
                triggerRefresh: state.triggerRefresh,
            };
        case 'SETTLEMENTS_RESET_TRIGGER_SEARCH':
            return {
                searchData: state.searchData,
                searchResult: state.searchResult,
                userList: state.userList,
                pageSizeList: state.pageSizeList,
                shopList: state.shopList,
                settlementStatus: state.settlementStatus,
                isLoadingSearchBox: state.isLoadingSearchBox,
                isLoadedSearchBox: state.isLoadedSearchBox,
                isLoading: state.isLoading,
                isLoaded: state.isLoaded,
                triggerSearch: false,
                triggerRefresh: state.triggerRefresh,
            };
        case 'SETTLEMENTS_SET_TRIGGER_REFRESH':
            return {
                searchData: state.searchData,
                searchResult: state.searchResult,
                userList: state.userList,
                pageSizeList: state.pageSizeList,
                shopList: state.shopList,
                settlementStatus: state.settlementStatus,
                isLoadingSearchBox: state.isLoadingSearchBox,
                isLoadedSearchBox: state.isLoadedSearchBox,
                isLoading: state.isLoading,
                isLoaded: state.isLoaded,
                triggerSearch: state.triggerSearch,
                triggerRefresh: true,
            };
        case 'SETTLEMENTS_RESET_TRIGGER_REFRESH':
            return {
                searchData: state.searchData,
                searchResult: state.searchResult,
                userList: state.userList,
                pageSizeList: state.pageSizeList,
                shopList: state.shopList,
                settlementStatus: state.settlementStatus,
                isLoadingSearchBox: state.isLoadingSearchBox,
                isLoadedSearchBox: state.isLoadedSearchBox,
                isLoading: state.isLoading,
                isLoaded: state.isLoaded,
                triggerSearch: state.triggerSearch,
                triggerRefresh: false,
            };

        default:
            return state;
    }
};
