import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { userActions } from '../_actions';
import { alertActions } from '../_actions';
import * as Validator from "../../validator/Validator";

class RegisterPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            user: {
                FirstName: '',
                LastName: '',
                Email: '',
                UserUUID: '',
                Password1: '',
                Password2: '',
                CurrentPassword: ''
            },
            submitted: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangePassword = this.handleChangePassword.bind(this);
    }

    handleChange(event) {
        const { name, value } = event.target;
        const { user } = this.state;
        this.setState({
            user: {
                ...user,
                [name]: value
            }
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        if (this.handleValidation()) {
            this.setState({ submitted: true });
            const { user } = this.state;

            if (this.props.profile)
                this.props.update(user);
            else
                this.props.register(user);
        }
        
    }


    handleChangePassword(event) {
        event.preventDefault();        
        const { user } = this.state;
        if (this.handleValidationChangePassword())
            this.props.changePassword(user);
    }

    handleValidation() {
        let result = true;
        let err = '';

        const { user } = this.state;

        var FirstName = user.FirstName;
        var LastName = user.LastName;
        var Email = user.Email;
        var Password1 = user.Password1;
        var Password2 = user.Password2;
        

        if (!Validator.isAlphaNumericAndSpace(FirstName)) {
            err += "First Name should be alpha numeric!\n";
            result = false;
        }
        if (FirstName.length <= 0 || FirstName.length > 50) {
            err += "First Name should be between 1 and 50 characters!\n";
            result = false;
        }

        if (!Validator.isAlphaNumericAndSpace(LastName)) {
            err += "Last Name should be alpha numeric!\n";
            result = false;
        }
        if (LastName.length <= 0 || LastName.length > 50) {
            err += "Last Name should be between 1 and 50 characters!\n";
            result = false;
        }

        if (!Validator.isEmail(Email)) {
            err += "Email is not valid!\n";
            result = false;
        }
        if (Email.length <= 0 || Email.length > 100) {
            err += "Email should be between 1 and 100 characters!\n";
            result = false;
        }

        if (!this.props.profile) {
            if (Password1.length <= 0) {
                err += "Password is required!\n";
                result = false;
            }
            if (Password1.length > 10) {
                err += "Password is too long!\n";
                result = false;
            }
            if (Password2.length <= 0) {
                err += "Confirm Password is required!\n";
                result = false;
            }
            if (Password2.length > 10) {
                err += "Confirm Password is too long!\n";
                result = false;
            }
        }

        if (!result) {
            console.log(err);
            this.props.error(err)
        }

        return result;
    }

    handleValidationChangePassword() {
        let result = true;
        let err = '';

        const { user } = this.state;

        var Password1 = user.Password1;
        var Password2 = user.Password2;
        var CurrentPassword = user.CurrentPassword;

        if (CurrentPassword.length <= 0) {
            err += "Current Password is required!\n";
            result = false;
        }
        if (CurrentPassword.length > 10) {
            err += "Current Password is too long!\n";
            result = false;
        }


        if (Password1.length <= 0) {
            err += "Password is required!\n";
            result = false;
        }
        if (Password1.length > 10) {
            err += "Password is too long!\n";
            result = false;
        }
        if (Password2.length <= 0) {
            err += "Confirm Password is required!\n";
            result = false;
        }
        if (Password2.length > 10) {
            err += "Confirm Password is too long!\n";
            result = false;
        }
        

        if (!result) {
            console.log(err);
            this.props.error(err)
        }

        return result;
    }

    componentDidMount() {
        if (this.props.profile) {            
            this.setState({ user: this.props.user });
        }
    }

    render() {
        const { isSaving } = this.props;
        const { user } = this.state;
        return (
            <div className="form-group row">
                <div className="col-md-3">
                </div>
                <div className="col-md-6">
                    <div className="form-group row">
                        <div className="col-md-6">
                            <fieldset>
                                <legend>
                                    {this.props.profile ? 'Update user details' : 'Register'}
                                </legend>
                                
                                <form name="form" onSubmit={this.handleSubmit}>                    
                                    <div className="form-group row" >
                                        <label htmlFor="FirstName">First Name</label>
                                        <input required type="text" className="form-control" name="FirstName" value={user.FirstName} onChange={this.handleChange} />
                                    </div>
                                    <div className="form-group row" >
                                        <label htmlFor="LastName">Last Name</label>
                                        <input required type="text" className="form-control" name="LastName" value={user.LastName} onChange={this.handleChange} />
                                    </div>
                                    <div className="form-group row" >
                                        <label htmlFor="Email">Email</label>
                                        <input required type="text" className="form-control" name="Email" value={user.Email} onChange={this.handleChange} />
                                    </div>
                                    {this.props.profile ? '' :
                                        <div>
                                            <div className="form-group row" >
                                                <label htmlFor="Password1">Password</label>
                                                <input required type="password" className="form-control" name="Password1" value={user.Password1} onChange={this.handleChange} />
                                            </div>
                                            <div className="form-group row" >
                                                <label htmlFor="Password2">Confirm Password</label>
                                                <input required type="password" className="form-control" name="Password2" value={user.Password2} onChange={this.handleChange} />
                                            </div>
                                        </div>
                                    }
                                    <div className="form-group" >
                                        <button className="btn btn-primary">
                                            {this.props.profile ? 'Update' : 'Register'}
                                        </button>
                                        {isSaving &&
                                            <img alt='' src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                        }
                                        <Link to="/login" className="btn btn-link">Cancel</Link>
                                    </div>
                                </form>
                            </fieldset>
                        </div>
                        <div className="col-md-6">
                            {!this.props.profile ? '' :
                                <fieldset>
                                    <legend>
                                        Change password
                                    </legend>
                                    <form name="formChangePassword" onSubmit={this.handleChangePassword}>
                                        <div className="form-group row" >
                                            <label htmlFor="Password1">Current Password</label>
                                            <input required type="password" className="form-control" name="CurrentPassword" value={user.CurrentPassword} onChange={this.handleChange} />
                                        </div>
                                        <div className="form-group row" >
                                            <label htmlFor="Password1">Password</label>
                                            <input required type="password" className="form-control" name="Password1" value={user.Password1} onChange={this.handleChange} />
                                        </div>
                                        <div className="form-group row" >
                                            <label htmlFor="Password2">Confirm Password</label>
                                            <input required type="password" className="form-control" name="Password2" value={user.Password2} onChange={this.handleChange} />
                                        </div>
                                        <div className="form-group" >
                                            <button className="btn btn-primary">Change Password</button>
                                        </div>
                                    </form>
                                </fieldset>
                            }
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                </div>
                
            </div>
        );
    }
}

function mapState(state) {
    const { isSaving, authentication } = state;
    const { user } = authentication;
    return { isSaving, user };
}

const actionCreators = {
    register: userActions.register,
    update: userActions.update,
    changePassword: userActions.changePassword,
    error: alertActions.error,
}

const connectedRegisterPage = connect(mapState, actionCreators)(RegisterPage);
export { connectedRegisterPage as RegisterPage };