import './assets/css/style.css';
import '@fontsource/rubik/300.css';
import '@fontsource/rubik/400.css';
import '@fontsource/rubik/500.css';
import '@fontsource/rubik/700.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';


import PageHeader from './pageHeader';
import AwbTypography from './awbTypography';
import MUITheme from './theme';
import { useParams } from 'react-router-dom';
import { useGetAwbQuery } from '../../store/apiSlice';

//luata de la
//https://github.com/Tono2007/slim-free-react-mui-template


function ThemeTypography() {

	const params = useParams();

    const traking_number = params.traking_number != null ? params.traking_number : '';
    
    const { data, isLoading, error } = useGetAwbQuery(traking_number);

	if (error) return <p>Error occured</p>;
  	if (isLoading) return <p>Loading...</p>;

	if (data == null) return <p>Awb not found...</p>;
	
	return (
		<MUITheme>
			<PageHeader title="AWB">
			</PageHeader>
			<AwbTypography awb={data} />
		</MUITheme>
	);
}

export default ThemeTypography;
