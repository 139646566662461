import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import * as ApiInterface from './ApiInterface';
import { http_axios_post, http_axios_get, http_axios_put } from './ApiInterface';
import * as Models from "../models/Models";
import { UserData } from './FetchUsers';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface EditUserState {
    isLoading: boolean;
    userData: UserData;
    companies: Models.Company[];
    shops: Models.Shop[];
    triggerLoad: boolean;
    triggerLoadState: boolean;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.


export interface RequestEditUserDataAction {
    type: 'REQUEST_EDIT_USER_DATA';
}
export interface ReceiveEditUserDataAction {
    type: 'RECEIVE_EDIT_USER_DATA';
    companies: Models.Company[];
    shops: Models.Shop[];
    userData: UserData;
    triggerLoadState: boolean;

}
export interface RequestSaveUserDataAction {
    type: 'REQUEST_SAVE_USER_DATA';
}
export interface ReceiveSaveUserDataAction {
    type: 'RECEIVE_SAVE_USER_DATA';
    triggerLoad: boolean;
}
export interface ResetTriggerLoadUser {
    type: 'RESET_TRIGGER_LOAD_USER';
}
export interface ResetTriggerLoadUserState {
    type: 'RESET_TRIGGER_LOAD_USER_STATE';
}
// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = ApiInterface.SetErrorAction |
    ApiInterface.SetInfoAction |
    RequestEditUserDataAction |
    ReceiveEditUserDataAction |
    RequestSaveUserDataAction |
    ReceiveSaveUserDataAction |
    ResetTriggerLoadUser |
    ResetTriggerLoadUserState;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    ...ApiInterface.actionCreators,
    requestEditData: (userId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState) {

            //https://stackoverflow.com/questions/35297446/best-es6-way-to-get-name-based-results-with-promise-all
            Promise.all([
                http_axios_get('api/Companies/GetAll/0')
                    .then(list => {
                        return list;
                    }),
                http_axios_get('api/Shops/GetAll/0')
                    .then(list => {
                        return list;
                    }),
                http_axios_get('api/Users', { "userId": userId })
                    .then(response =>  {
                        return response;
                    })
            ]).then(([company, shops, usrData]) => {                
                const companiesList = company as Models.Company[];
                const shopsList = shops as Models.Shop[];
                const userData = usrData as UserData;

                dispatch({ type: 'RECEIVE_EDIT_USER_DATA', companies: companiesList, shops: shopsList, userData: userData, triggerLoadState: true });
            })
            .catch(function (e) {
                    console.log(e);
                });

            
            dispatch({ type: 'REQUEST_EDIT_USER_DATA' });
        }
    },
    requestSaveData: (data: any, triggerLoad: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        
        if (appState) {
            
            http_axios_post('api/Users', data)
                .then(response => {
                    dispatch({ type: 'RECEIVE_SAVE_USER_DATA', triggerLoad: triggerLoad }); /* the form is updated because of the location change event */
                })
                .catch(function (e) {
                    console.log(e);
                });
            
            dispatch({ type: 'REQUEST_SAVE_USER_DATA' });
        }
    },
    requestChangePassword: (data: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        var url = ''
        if (appState) {

            url = 'api/Users/ChangePassword';
            http_axios_put(url, data)
                .catch(function (e) {
                    console.log(e);
                });
        }
    },
    resetTriggerLoad: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState) {
            dispatch({ type: 'RESET_TRIGGER_LOAD_USER' });
        }
    },
    resetTriggerLoadState: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState) {
            dispatch({ type: 'RESET_TRIGGER_LOAD_USER_STATE' });
        }
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: EditUserState = {
    isLoading: false,
    companies: [],
    shops: [],
    userData: {
        UserId: 0,
        UserName: '',
        Type: '',
        Company: 0,
        Shop: 0,
        Email: '',
        EmailConfirmed: false,
        FirstName: '',
        LastName: '',
        PhoneNumber: '',
        PhoneNumberConfirmed: false,
        UserEnabled: false,
        Roles: [],
        Password1: '',
        Password2: ''
    },    
    triggerLoad: false,
    triggerLoadState: false
};

export const reducer: Reducer<EditUserState> = (state: EditUserState | undefined, incomingAction: Action): EditUserState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_EDIT_USER_DATA':
            return {
                companies: unloadedState.companies,
                shops: unloadedState.shops,
                userData: unloadedState.userData,
                isLoading: true,
                triggerLoad: unloadedState.triggerLoad,
                triggerLoadState: unloadedState.triggerLoadState
            };        
        case 'RECEIVE_EDIT_USER_DATA':
            return {
                companies: action.companies,
                shops: action.shops,
                userData: action.userData,
                isLoading: false,
                triggerLoad: state.triggerLoad,
                triggerLoadState: action.triggerLoadState
            };
        case 'REQUEST_SAVE_USER_DATA':
            return state;
        case 'RECEIVE_SAVE_USER_DATA':
            return {
                companies: state.companies,
                shops: state.shops,
                userData: state.userData,
                isLoading: state.isLoading,
                triggerLoad: action.triggerLoad,
                triggerLoadState: state.triggerLoadState
            };
        case 'RESET_TRIGGER_LOAD_USER':
            return {
                companies: state.companies,
                shops: state.shops,
                userData: state.userData,
                isLoading: state.isLoading,
                triggerLoad: false,
                triggerLoadState: state.triggerLoadState
            };
        case 'RESET_TRIGGER_LOAD_USER_STATE':
            return {
                companies: state.companies,
                shops: state.shops,
                userData: state.userData,
                isLoading: state.isLoading,
                triggerLoad: state.triggerLoad,
                triggerLoadState: false
            };

        default:
            return state;
    }
};
