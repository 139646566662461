import * as Models from "../models/Models";
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import * as utils from './Utils';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface  FetchTransportRequestsState {
    formData: FormData;
}

export class FormData extends Models.SearchPaginationInput {
  start_date: string = "";
  end_date: string = "";
  transport_id: string = ""; //in ecranul de search il pastram string a.i. sa putem sa trimitem string vid (pt cazul in care nu cautam dupa el)
  awb: string = "";
  ext_ref: string = "";
  tracking_number: string = "";
  status: Array<number> = [];
  status_string: string = "";
  time_zone_offset: string = "";
  sender_name: string = "";
  payment_type: string = "";
  user_name: string = "";
  company_id: number = 0;
  show_settings_dialog: boolean = false;
  show_settings_dialog_transport_id: number = 0;
}


const unloadedState: FetchTransportRequestsState = {
    formData: {
        ...Models.getSearchPaginationInputDefaultValues(),
        start_date: utils.getDateTimeISO(-1),
        end_date: utils.getDateTimeISO(),
        transport_id: "",
        awb: "",
        ext_ref: "",
        tracking_number: "",
        status: [],
        status_string: "",
        time_zone_offset: utils.getTimezoneOffset().toString(),
        sender_name: "",
        payment_type: "ALL",
        user_name: "",
        company_id: 0,
        show_settings_dialog: false,
        show_settings_dialog_transport_id: 0,
    }
};

/* new reducer */

const slice = createSlice({
    name: 'FetchTransportRequests',
    initialState: unloadedState,
    reducers: {
      setFetchTransportRequestsFormData(state, action: PayloadAction<FormData>) {
        state.formData = action.payload
      },
    },
  })
  
  // Extract the action creators object and the reducer
  const { actions, reducer } = slice
  // Extract and export each action creator by name
  export const { setFetchTransportRequestsFormData } = actions
  // Export the reducer, either as a default or named export
  export default reducer