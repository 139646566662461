import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import * as ApiInterface from './ApiInterface';
import { http_axios_delete, http_axios_get } from './ApiInterface';
import * as Models from "../models/Models";

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface  FetchUsersState {
    searchData: SearchData;
    searchResult: SearchResult;
    yesNoList: Models.ListValue[];
    pageSizeList: Models.ListValue[];
    isLoadingUsers: boolean;
    isLoadingSearchBox: boolean;
    triggerSearch: boolean;
}

export class SearchData extends Models.SearchPaginationInput {
    FirstName: string = "";
    LastName: string = "";
    UserName: string = "";
    Email: string = "";
    Phone: string = "";
    Enabled: string = "0";
    EmailConfirmed: string = "0";
    PhoneConfirmed: string = "0";
}



export class UserData {
    UserId: number = 0;
    UserName: string = "";
    Type: string = "";
    Company: number = 0;
    Shop: number = 0;
    Email: string = "";
    EmailConfirmed: boolean = false;
    FirstName: string = "";
    LastName: string = "";
    PhoneNumber: string = "";
    PhoneNumberConfirmed: boolean = false;
    UserEnabled: boolean = false;
    Roles: Role[] = [];
    Password1: string = "";
    Password2: string = "";
}

export class Role {
    Value: string = "";
    Label: string = "";
    Selected: boolean = false;
}

export class SearchResult extends Models.SearchPaginationResult {
    Data: UserData[] = [];
}


// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.


export interface RequestUsersDataAction {
    type: 'REQUEST_USERS_DATA';
    searchData: SearchData;
}
export interface ReceiveUsersDataAction {
    type: 'RECEIVE_USERS_DATA';
    userSearchResult: SearchResult;
}

export interface RequestUsersSearchBoxAction {
    type: 'REQUEST_USERS_SEARCH_BOX_DATA';
}
export interface ReceiveUsersSearchBoxAction {
    type: 'RECEIVE_USERS_SEARCH_BOX_DATA';
    yesNoList: Models.ListValue[];
    pageSizeList: Models.ListValue[];
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = RequestUsersDataAction |
    ReceiveUsersDataAction |
    RequestUsersSearchBoxAction |
    ReceiveUsersSearchBoxAction;


// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).


export const actionCreators = {
    ...ApiInterface.actionCreators,
    requestUsersData: (data: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState) {
            http_axios_get('api/UsersSearch', data)
                .then(response => response as Promise<SearchResult>)
                .then(data => {
                    dispatch({ type: 'RECEIVE_USERS_DATA', userSearchResult: data });
                })
                .catch(function (e) {
                    console.log(e);
                });

            //console.log(data);
            var searchData = Object.assign(new SearchData(), data);
            dispatch({ type: 'REQUEST_USERS_DATA', searchData });
        }
    },    
    requestDeleteUsers: (userId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)            
        
        http_axios_delete('api/Users/' + userId)
            .then(response => {

                //https://github.com/aspnet/JavaScriptServices/issues/1321
                const untypedDispath = dispatch as any;
                const jsonSearchForm = getState().fetchUsers?.searchData;
                untypedDispath(actionCreators.requestUsersData(jsonSearchForm));
            })
            .catch(function (e) {
                console.log(e);
            })
    },
    requestSearchBoxData: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState) {
            //https://stackoverflow.com/questions/35297446/best-es6-way-to-get-name-based-results-with-promise-all

            Promise.all([
                http_axios_get('api/Lists/1')
                    .then(list => {
                        return list;
                    }),
                http_axios_get('api/Lists/3')
                    .then(list => {
                        return list;
                    })
            ]).then(([listYesNo, pageSize]) => {
                const yesNoList = listYesNo as Models.ListValue[];
                const pageSizeList = pageSize as Models.ListValue[];
                dispatch({ type: 'RECEIVE_USERS_SEARCH_BOX_DATA', yesNoList: yesNoList, pageSizeList: pageSizeList });
            })
            .catch(function (e) {
                console.log(e);
            });

            dispatch({ type: 'REQUEST_USERS_SEARCH_BOX_DATA' });
        }
        
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: FetchUsersState = {
    searchResult: {
        ...Models.getSearchPaginationResultDefaultValues(),
        Data: []
    },
    yesNoList: [],
    pageSizeList: [],
    isLoadingUsers: false,
    isLoadingSearchBox: false,
    triggerSearch: false,
    searchData: {
        ...Models.getSearchPaginationInputDefaultValues(),
        FirstName: "",
        LastName: "",
        UserName: "",
        Email: "",
        Phone: "",
        Enabled: "0",
        EmailConfirmed: "0",
        PhoneConfirmed: "0"
    }
};

export const reducer: Reducer<FetchUsersState> = (state: FetchUsersState | undefined, incomingAction: Action): FetchUsersState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_USERS_DATA':
            return {
                searchData: action.searchData,
                searchResult: state.searchResult,
                isLoadingUsers: true,
                yesNoList: state.yesNoList,
                pageSizeList: state.pageSizeList,
                isLoadingSearchBox: state.isLoadingSearchBox,
                triggerSearch: false,
            };
        case 'RECEIVE_USERS_DATA':
            return {
                searchData: state.searchData,
                searchResult: action.userSearchResult,
                isLoadingUsers: false,
                yesNoList: state.yesNoList,
                pageSizeList: state.pageSizeList,
                isLoadingSearchBox: state.isLoadingSearchBox,
                triggerSearch: state.triggerSearch,
            };
        case 'REQUEST_USERS_SEARCH_BOX_DATA':
            return {
                searchData: state.searchData,
                searchResult: state.searchResult,
                isLoadingUsers: state.isLoadingUsers,
                yesNoList: state.yesNoList,
                pageSizeList: state.pageSizeList,
                isLoadingSearchBox: true,
                triggerSearch: state.triggerSearch,
            };
        case 'RECEIVE_USERS_SEARCH_BOX_DATA':
            return {
                searchData: state.searchData,
                searchResult: state.searchResult,
                isLoadingUsers: state.isLoadingUsers,
                yesNoList: action.yesNoList,
                pageSizeList: action.pageSizeList,
                isLoadingSearchBox: false,
                triggerSearch: true,
            };


        default:
            return state;
    }
};
