import React from 'react';
import { Navigate } from 'react-router-dom';

export const PrivateRoute = ({ ...props }) => (
    
        localStorage.getItem('loginData')
        ? props.component
        : <Navigate to={{ pathname: '/login', state: { from: props.location } }} />

        
)