import * as React from 'react';
import { useSelector } from 'react-redux'

import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import * as Models from './models/Models';
import { Layout } from './components/Layout';
import CookieConsent from "react-cookie-consent";

import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import authService from './components/api-authorization/AuthorizeService';
import { ApplicationPaths, LoginActions, LogoutActions } from './components/api-authorization/ApiAuthorizationConstants';
import Navigation from './components/Navigation';
import ApiInterface from './components/ApiInterface';

import { ThemeProvider, StyledEngineProvider } from '@mui/material';
import theme from './theme';

import { ErrorBoundary } from './components/ErrorBoundary';
import { Login } from './components/api-authorization/Login'
import { Logout } from './components/api-authorization/Logout'

import { Home } from './components/Home';
import FetchUsers from './components/FetchUsers';
import EditUser from './components/EditUser';


import Map from './components/Map';
import MobileApp from './components/MobileApp';
import EditParameters from './components/EditParameters';
import FetchCompanies from './components/FetchCompanies';
import EditCompany from './components/EditCompany';
import FetchShops from './components/FetchShops';
import EditShop from './components/EditShop';


import TransportRequest from './components/TransportRequest';
import SearchTransportRequests from './components/SearchTransportRequests';
import Upload from './components/Upload';
import Settlements from './components/Settlements';
import Documents from './components/Documents';
import Reports from './components/Reports';


import { PrivateRoute } from './_users/_components';
import { HomePage } from './_users/HomePage';
import { LoginPage } from './_users/LoginPage';
import { RegisterPage } from './_users/RegisterPage';


//import 'semantic-ui-css/semantic.min.css'
import './css/custom.css'

import * as utils from './store/Utils';
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { RootState, useAppDispatch } from './store/configureStore';
import { checkTokenValidTryRefreshIfNot, parseAxiosError } from './utils/axiosUtils';
import { setError } from './store/ApiInterface2';
import { setAuthState, setOnline } from './store/App';
import Awb from './components/Awb';
import AwbLayout from './components/Awb/awbLayout';
import Dashboard from './components/dashboard/dashboard/Dashboard';
import Zones from './components/Zones';


    

export const App : React.FC = (props) => {
    const _subscription = useRef<number>(0);

    const storeState = useSelector((state: RootState) => state.App)
    
    const dispatch = useAppDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    const [state, setState] = useState<Models.AuthState | null>(null);

    const populateState = useCallback((event: string) => {

        if (storeState.online &&
            location.pathname !== ApplicationPaths.LoginCallback && //la login inca nu este autentificat si da eroare /UserInfo
            location.pathname !== ApplicationPaths.LogOut //la logout da eroare /UserInfo pt ca token-ul este invalid
            ){

            Promise.all([
                authService.isAuthenticated(),
                authService.getUser(),
            ])
                .then(([isAuthenticated, user]) => {

                    if (isAuthenticated) {                        
                        
                        checkTokenValidTryRefreshIfNot().then(token_valid => {
                            if (!token_valid) {
                                // daca token-ul este invalid apelam totusi API-ul; 
                                // acopera cazul in care aplicatia este offline si nu se poate reinnoi token-ul
                                // daca o sa returneze 401 atunci facem redirect la logout prin "parseAxiosError(e)"
                                console.log('token not valid (2)'); 
                                
                            }

                            Promise.all([
                                
                                authService.getAccessToken()
                                    .then(token => {
                                        return token;
                                    })
                            ]).then(([token]) => {                               

                                var roles = [];

                                if (token != null) {
                                    var jsonObject = utils.decodeTokenAsJson(token);
                                    var jsonRolesObject = jsonObject['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];

                                    if (isArray(jsonRolesObject))
                                        for (const role of jsonRolesObject) {
                                            roles.push(role)
                                        }
                                    else
                                        roles.push(jsonRolesObject)
                                    
                                }

                                setState({
                                    isAuthenticated: isAuthenticated,
                                    full_name: "",
                                    work_place: "",
                                    profile_picture_base64: "",
                                    roles: roles,
                                    force_change_password: false,
                                });
                            })
                            .catch(function (e) {
                                //inlocuim navigate to logout cu parseAxiosError - doar daca e eroare 401 facem redirect la logout
                                //pt ca se poate intampla ca aplicatia sa fie offline (de ex la o actualizare de aplicatie) la momentul cand se apeleaza UserInfo - caz in care returneaza 502 - in acest caz nu trebuie redirect la logout ci asteptat pana aplicatia revine online
                                //navigate(ApplicationPaths.LogOut);
                                parseAxiosError(e);
                                dispatch(setError(e))
                                console.log(e);
                            });
                            
                        })
                    }
                    else {
                        setState({
                            isAuthenticated: false,
                            full_name: "",
                            work_place: "",
                            profile_picture_base64: "",
                            roles: [],
                            force_change_password: false,
                        });
                    }
                });

        }
    }, [location.pathname, dispatch, storeState.online])

    useEffect(() => {
        if (!storeState.online){
            navigate('/')
        }
    
    }, [storeState.online, navigate]);

    useEffect(() => {
        //facem o copie in redux ca sa putem folosi info de autentificare usor in restul aplicatiei
        if (state){
            dispatch(setAuthState(state))
        }
    
    }, [state, dispatch]);

    useEffect(() => {
        _subscription.current = authService.subscribe(() => { populateState('populateState/subscribe'); });
        populateState('populateState/useEffect');
        
        return () => {
            // cleanun state mapped to props            
            authService.unsubscribe(_subscription.current);
        };
    
    }, [populateState]);

    
    // Scroll to top if path changes
    useLayoutEffect(() => {
        //cand navigheaza la una din paginile de mai jos facem scroll top pt ca daca foloseste link-ul din footer o sa pastreze pozitia scrool-ului din pagina anterioara (adica jos si cand deschide pagina nou)
        if (location.pathname === '/' || 
            location.pathname === '/contact' ||
            location.pathname === '/terms'){
            window.scrollTo(0, 0);
        }
    }, [location.pathname]);
    

    const setOnlineState = useCallback(() => {
        dispatch(setOnline(true))
    }, [dispatch])

    const setOfflineState = useCallback(() => {
        dispatch(setOnline(false))
    }, [dispatch])

    useEffect(() => {
        window.addEventListener('online', setOnlineState);
        window.addEventListener('offline', setOfflineState);

        return () => {
            window.removeEventListener('online', setOnlineState);
            window.removeEventListener('offline', setOfflineState);
        };
    }, [setOnlineState, setOfflineState]);

    //https://stackoverflow.com/questions/951483/how-to-check-if-a-json-response-element-is-an-array
    const isArray = (what: any) => {
        return Object.prototype.toString.call(what) === '[object Array]';
    }
    
/*
    const onPasswordChangedCallback = () => {
        populateState('password changed')
    }
*/
    
    return (
        <React.Fragment>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <ApiInterface />
                    <Navigation />
                    <ErrorBoundary>

                        <Routes>
                            <Route path="/awb" element={
                                <AwbLayout />
                                }>
                                <Route path="/awb/:traking_number" element={
                                    <AuthorizeRoute component={<Awb />}>
                                    </AuthorizeRoute>
                                } />
                            </Route>
                            <Route path='/' element={<Layout auth={state} />}>

                                <Route path={ApplicationPaths.Login} element={loginAction(LoginActions.Login)} />
                                <Route path={ApplicationPaths.LoginFailed} element={loginAction(LoginActions.LoginFailed)} />
                                <Route path={ApplicationPaths.LoginCallback} element={loginAction(LoginActions.LoginCallback)} />
                                <Route path={ApplicationPaths.Profile} element={loginAction(LoginActions.Profile)} />
                                <Route path={ApplicationPaths.Register} element={loginAction(LoginActions.Register)} />
                                <Route path={ApplicationPaths.LogOut} element={logoutAction(LogoutActions.Logout)} />
                                <Route path={ApplicationPaths.LogOutCallback} element={logoutAction(LogoutActions.LogoutCallback)} />
                                <Route path={ApplicationPaths.LoggedOut} element={logoutAction(LogoutActions.LoggedOut)} />

                                <Route path="/" element={
                                    <Home />
                                } />

                                <Route path="/fetchusers" element={
                                    <AuthorizeRoute component={<FetchUsers />}>
                                    </AuthorizeRoute>
                                } />

                                <Route path="/user/edit/:id" element={
                                    <AuthorizeRoute component={<EditUser />}>
                                    </AuthorizeRoute>
                                } />


                                <Route path="/map" element={
                                    <AuthorizeRoute component={<Map />}>
                                    </AuthorizeRoute>
                                } />

                                <Route path="/zones" element={
                                    <AuthorizeRoute component={<Zones />}>
                                    </AuthorizeRoute>
                                } />

                                <Route path="/mobileapp" element={
                                    <AuthorizeRoute component={<MobileApp />}>
                                    </AuthorizeRoute>
                                } />

                                <Route path="/parameters" element={
                                    <AuthorizeRoute component={<EditParameters />}>
                                    </AuthorizeRoute>
                                } />

                                <Route path="/searchtransportrequests" element={
                                    <AuthorizeRoute component={<SearchTransportRequests />}>
                                    </AuthorizeRoute>
                                } />

                                <Route path="/transportrequest/edit/:id" element={
                                    <AuthorizeRoute component={<TransportRequest />}>
                                    </AuthorizeRoute>
                                } />

                                <Route path="/upload" element={
                                    <AuthorizeRoute component={<Upload />}>
                                    </AuthorizeRoute>
                                } />

                                <Route path="/settlements" element={
                                    <AuthorizeRoute component={<Settlements />}>
                                    </AuthorizeRoute>
                                } />

                                <Route path="/documents" element={
                                    <AuthorizeRoute component={<Documents />}>
                                    </AuthorizeRoute>
                                } />

                                <Route path="/reports" element={
                                    <AuthorizeRoute component={<Reports />}>
                                    </AuthorizeRoute>
                                } />

                                <Route path="/dashboard" element={
                                    <AuthorizeRoute component={<Dashboard />}>
                                    </AuthorizeRoute>
                                } />



                                <Route path="/companies" element={
                                    <AuthorizeRoute component={<FetchCompanies />}>
                                    </AuthorizeRoute>
                                } />

                                <Route path="/company/edit/:id" element={
                                    <AuthorizeRoute component={<EditCompany />}>
                                    </AuthorizeRoute>
                                } />

                                <Route path="/shops" element={
                                    <AuthorizeRoute component={<FetchShops />}>
                                    </AuthorizeRoute>
                                } />

                                <Route path="/shop/edit/:id" element={
                                    <AuthorizeRoute component={<EditShop />}>
                                    </AuthorizeRoute>
                                } />


                                <Route path="/home" element={
                                    <PrivateRoute component={<HomePage />}>
                                    </PrivateRoute>
                                } />


                                <Route path="/login" element={
                                    <LoginPage>
                                    </LoginPage>
                                } />

                                <Route path="/register" element={
                                    <RegisterPage profile={false}>
                                    </RegisterPage>
                                } />

                                <Route path="/profile" element={
                                    <RegisterPage profile={true}>
                                    </RegisterPage>
                                } />

                            </Route>
                        </Routes>

                    </ErrorBoundary>
                    <CookieConsent
                        location="bottom"
                        buttonText="I understand"
                        cookieName="consent"
                        style={{ background: "#2B373B", zIndex: "2000" }}
                        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
                        expires={150}
                    >
                        This website uses cookies to enhance the user experience.{" "}
                    </CookieConsent>
                </ThemeProvider>
            </StyledEngineProvider>
        </React.Fragment>
    );    
}

function loginAction(name: string) {
    return (<Login action={name}></Login>);
}

function logoutAction(name: string) {
    return (<Logout action={name}></Logout>);
}

export default App;