import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import * as ApiInterface from './ApiInterface';
import { http_axios_post, http_axios_get } from './ApiInterface';
import * as Models from "../models/Models";
import { CompanyData } from './FetchCompanies';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface EditCompanyState {
    isLoading: boolean;
    companyData: CompanyData;
    ccyList: Models.ListValue[];
    triggerLoad: boolean;
    triggerLoadState: boolean;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.


export interface RequestEditCompanyDataAction {
    type: 'REQUEST_EDIT_COMPANY_DATA';
}
export interface ReceiveEditCompanyDataAction {
    type: 'RECEIVE_EDIT_COMPANY_DATA';
    companyData: CompanyData;
    ccyList: Models.ListValue[];
    triggerLoadState: boolean;

}
export interface RequestSaveCompanyDataAction {
    type: 'REQUEST_SAVE_COMPANY_DATA';
}
export interface ReceiveSaveCompanyDataAction {
    type: 'RECEIVE_SAVE_COMPANY_DATA';
    triggerLoad: boolean;
}
export interface ResetTriggerLoad {
    type: 'RESET_TRIGGER_LOAD_COMPANY';
}
export interface ResetTriggerLoadState {
    type: 'RESET_TRIGGER_LOAD_COMPANY_STATE';
}
// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = ApiInterface.SetErrorAction |
    ApiInterface.SetInfoAction |
    RequestEditCompanyDataAction |
    ReceiveEditCompanyDataAction |
    RequestSaveCompanyDataAction |
    ReceiveSaveCompanyDataAction |
    ResetTriggerLoad |
    ResetTriggerLoadState;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    ...ApiInterface.actionCreators,
    requestEditData: (company_id: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState) {

            //https://stackoverflow.com/questions/35297446/best-es6-way-to-get-name-based-results-with-promise-all
            Promise.all([
                http_axios_get('api/Companies', { "company_id": company_id })
                    .then(response =>  {
                        return response;
                    }),
                http_axios_get('api/Lists/5')
                    .then(list => {
                        return list;
                    })
            ]).then(([compData, listCcy]) => {
                const companyData = compData as CompanyData;
                const ccyList = listCcy as Models.ListValue[];

                dispatch({
                    type: 'RECEIVE_EDIT_COMPANY_DATA',
                    companyData: companyData,
                    ccyList: ccyList,
                    triggerLoadState: true
                });
            })
            .catch(function (e) {
                    console.log(e);
                });

            
            dispatch({ type: 'REQUEST_EDIT_COMPANY_DATA' });
        }
    },
    requestSaveData: (data: any, triggerLoad: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();        
        if (appState) {

            http_axios_post('api/Companies', data)
                .then(response => {

                    dispatch({ type: 'RECEIVE_SAVE_COMPANY_DATA', triggerLoad: triggerLoad });
                })
                .catch(function (e) {
                    console.log(e);
                });
            
            dispatch({ type: 'REQUEST_SAVE_COMPANY_DATA' });
        }
    },
    resetTriggerLoad: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState) {
            dispatch({ type: 'RESET_TRIGGER_LOAD_COMPANY' });
        }
    },
    resetTriggerLoadState: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState) {
            dispatch({ type: 'RESET_TRIGGER_LOAD_COMPANY_STATE' });
        }
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: EditCompanyState = {
    isLoading: false,
    companyData: {
        id: 0,
        company_id: 0,
        name: '',
        delivery_price: 0,        
        delivery_price_ccy: '',        
        enabled: false,
    },
    ccyList: [],
    triggerLoad: false,
    triggerLoadState: false
};

export const reducer: Reducer<EditCompanyState> = (state: EditCompanyState | undefined, incomingAction: Action): EditCompanyState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_EDIT_COMPANY_DATA':
            return {
                companyData: unloadedState.companyData,
                ccyList: unloadedState.ccyList,
                isLoading: true,
                triggerLoad: unloadedState.triggerLoad,
                triggerLoadState: unloadedState.triggerLoadState
            };        
        case 'RECEIVE_EDIT_COMPANY_DATA':
            return {
                companyData: action.companyData,
                ccyList: action.ccyList,
                isLoading: false,
                triggerLoad: state.triggerLoad,
                triggerLoadState: action.triggerLoadState
            };
        case 'REQUEST_SAVE_COMPANY_DATA':
            return state;
        case 'RECEIVE_SAVE_COMPANY_DATA':
            return {
                companyData: state.companyData,
                ccyList: state.ccyList,
                isLoading: state.isLoading,
                triggerLoad: action.triggerLoad,
                triggerLoadState: state.triggerLoadState
            };
        case 'RESET_TRIGGER_LOAD_COMPANY':
            return {
                companyData: state.companyData,
                ccyList: state.ccyList,
                isLoading: state.isLoading,
                triggerLoad: false,
                triggerLoadState: state.triggerLoadState
            };
        case 'RESET_TRIGGER_LOAD_COMPANY_STATE':
            return {                
                companyData: state.companyData,
                ccyList: state.ccyList,
                isLoading: state.isLoading,
                triggerLoad: state.triggerLoad,
                triggerLoadState: false
            };

        default:
            return state;
    }
};
