import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import * as ApiInterface from './ApiInterface';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface MobileAppState {
    message: string;
    downloading: boolean;
    progress: number;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.


export interface DownloadAction {
    type: 'MOBILE_APP_DOWNLOAD';    
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = ApiInterface.SetErrorAction |
    ApiInterface.SetInfoAction |
    DownloadAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    ...ApiInterface.actionCreators,
    download: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState) {
            dispatch({ type: 'MOBILE_APP_DOWNLOAD' });
        }
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: MobileAppState = {
    message: "",
    downloading: false,
    progress: 0,
};

export const reducer: Reducer<MobileAppState> = (state: MobileAppState | undefined, incomingAction: Action): MobileAppState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {        
        case 'MOBILE_APP_DOWNLOAD':
            return state;
        default:
            return state;
    }
};
