import * as React from 'react';
import { connect } from 'react-redux'
import { ApplicationState } from '../store';
import * as MapStore from '../store/Map';
import { withWindowDimensions } from '../hocs'
import {
    FormControl,
    TextField,
    FormControlLabel,
    Checkbox,    
    Grid,
    MenuItem,
    Input,
    Select,
    Button,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@mui/material';



import {    
    FindInPageOutlined as FindInPageOutlinedIcon,
    Refresh as RefreshIcon,
    AspectRatio as AspectRatioIcon,
    Settings as SettingsIcon,
} from '@mui/icons-material';

import * as utils from '../store/Utils';
import * as Models from "../models/Models";

import TransportRequest from './TransportRequest'
import { Dialog, DialogTitle, DialogContent, DialogActions, Paper, } from './StyledComponents'
import TransportRequestItem from './MapTransportRequestItem';
import { refreshTransportRequests, recomputeRoute } from './Map';
import * as store from '../store/Map'; //conflict de denumire obiect TransportRequest cu componenta TransportRequest - de aia importam ca "store"


interface TransportRequestsProps {
    handleFitAll:() => void;
    refreshTransportRequests: refreshTransportRequests;
    setVisibleTranportRequest: (transport_id: string, visible: boolean) => void;
    recomputeRoute: recomputeRoute;
    transportRequests: Array<store.TransportRequest>;
    transportRequestsDate: string;
}

// At runtime, Redux will merge together...
type MapTransportRequestsProps =
    utils.IClasses
    & TransportRequestsProps
    & MapStore.MapState // ... state we've requested from the Redux store    
    & typeof MapStore.actionCreators; // ... plus action creators we've requested
    



interface MapTransportRequestsState {    
    showAllocationModal: boolean;
    showDetailsModal: boolean;
    showFilterRequestsModal: boolean;    
    showStatusModal: boolean;
    filterTransportRequests: MapStore.FilterTransportRequests;    
    allocationUser: number;
    allocationUserToDepot: number;
    allocationUserFromDepot: number;
    allocationDepot: number;
    changedStatus: number;    
    viewAllocationTransportId: string;
    viewRequestDetailsTransportId: string;
    viewStatusTransportId: string;
}


const divStyleToast: React.CSSProperties = {
    position: 'absolute',
    top: '70px',
    bottom: '0px',
    overflowY: 'scroll',
    width: '100%',
    padding: '10px',
};

const divFixed: React.CSSProperties = {
    position: 'sticky',
    padding: '10px',
};



class MapTransportRequests extends React.PureComponent<MapTransportRequestsProps, MapTransportRequestsState> {   
    

    constructor(props: Readonly<MapTransportRequestsProps>) {        

        super(props);

        this.state = {                        
            showAllocationModal: false,
            showDetailsModal: false,
            showFilterRequestsModal: false,
            showStatusModal: false,
            filterTransportRequests: {...this.props.filterTransportRequests},
            
            allocationUser: 0,
            allocationUserToDepot: 0,
            allocationUserFromDepot: 0,
            allocationDepot: 0,
            changedStatus: 0,            
            viewAllocationTransportId: "",
            viewRequestDetailsTransportId: "",
            viewStatusTransportId: "",
        };        
        
        
        this.handleRefreshTransportRequests = this.handleRefreshTransportRequests.bind(this);        
        this.handleRecomputeRoute = this.handleRecomputeRoute.bind(this);        
        this.handleSetVisibleTranportRequest = this.handleSetVisibleTranportRequest.bind(this);
        this.handleShowAllocationModal = this.handleShowAllocationModal.bind(this);
        this.handleCloseAllocationModal = this.handleCloseAllocationModal.bind(this);
        this.handleSaveAllocationModal = this.handleSaveAllocationModal.bind(this);
        this.handleShowDetailsModal = this.handleShowDetailsModal.bind(this);
        
        this.handleAllocationUserChange = this.handleAllocationUserChange.bind(this);
        this.handleAllocationUserToDepotChange = this.handleAllocationUserToDepotChange.bind(this);
        this.handleAllocationUserFromDepotChange = this.handleAllocationUserFromDepotChange.bind(this);
        this.handleAllocationDepotChange = this.handleAllocationDepotChange.bind(this);
        this.handleCloseDetailsModal = this.handleCloseDetailsModal.bind(this);
        
        this.handleShowFilterRequestsModal = this.handleShowFilterRequestsModal.bind(this);
        this.handleCloseFilterRequestsModal = this.handleCloseFilterRequestsModal.bind(this);
        this.handleApplyFilterTrasportRequests = this.handleApplyFilterTrasportRequests.bind(this);


        this.handleFilterTransportId = this.handleFilterTransportId.bind(this);
        this.handleFilterSenderName = this.handleFilterSenderName.bind(this);
        this.handleFilterReceiverName = this.handleFilterReceiverName.bind(this);
        this.handleFilterFromAnyTimeChanged = this.handleFilterFromAnyTimeChanged.bind(this);
        this.handleFilterFromChanged = this.handleFilterFromChanged.bind(this);
        this.handleFilterToAnyTimeChanged = this.handleFilterToAnyTimeChanged.bind(this);
        this.handleFilterToChanged = this.handleFilterToChanged.bind(this);
        this.handleFilterStatusChanged = this.handleFilterStatusChanged.bind(this);
        this.handleFilterAllocatedToChange = this.handleFilterAllocatedToChange.bind(this);

        this.handleShowStatusModal = this.handleShowStatusModal.bind(this);
        this.handleCloseStatusModal = this.handleCloseStatusModal.bind(this);
        this.handleSaveStatusModal = this.handleSaveStatusModal.bind(this);
        this.handlePriorityUpdate = this.handlePriorityUpdate.bind(this);
        this.handleChangeStatusChanged = this.handleChangeStatusChanged.bind(this);

    }
    
    componentDidMount = async () => {        
        
    };

    public componentDidUpdate(prevProps: MapTransportRequestsProps, prevState: MapTransportRequestsState) {

    }

    
    handleRefreshTransportRequests() {
        this.props.refreshTransportRequests();
    }

    handleRecomputeRoute() {
        this.props.recomputeRoute();
    }


    handleSetVisibleTranportRequest(event: React.ChangeEvent<HTMLInputElement>, transport_id: string) {
        this.props.setVisibleTranportRequest(transport_id, event.target.checked);
    }
    handleAllocationUserChange(event: any) {
        this.setState({ allocationUser: event.target.value as number });
    }

    handleAllocationUserToDepotChange(event: any) {
        this.setState({ allocationUserToDepot: event.target.value as number });
    }

    handleAllocationUserFromDepotChange(event: any) {
        this.setState({ allocationUserFromDepot: event.target.value as number });
    }

    handleAllocationDepotChange(event: any) {
        this.setState({ allocationDepot: event.target.value as number });
    }    

    handleShowAllocationModal(transport_id: string) {
        this.setState({
            allocationUser: 0,
            allocationUserToDepot: 0,
            allocationUserFromDepot: 0,
            allocationDepot: 0,
            showAllocationModal: true,
            viewAllocationTransportId: transport_id,
        });
    }

    handleCloseAllocationModal() {
        this.setState({
            showAllocationModal: false,
            viewAllocationTransportId: "",
        });
    }

    handleSaveAllocationModal(allocationType: number) {

        if (allocationType === 1) {
            if (this.state.allocationUser === 0) {
                alert("Please select the user");
                return;
            }
        }
        if (allocationType === 2) {
            if (this.state.allocationDepot === 0) {
                alert("Please select the depot");
                return;
            }

            if (this.state.allocationUserToDepot === 0) {
                alert("Please select the user to depot");
                return;
            }

            if (this.state.allocationUserFromDepot === 0) {
                alert("Please select the user from depot");
                return;
            }
        }

        this.props.allocateRequest(this.state.viewAllocationTransportId, allocationType, this.state.allocationUser, this.state.allocationDepot, this.state.allocationUserToDepot, this.state.allocationUserFromDepot);
        this.setState({ showAllocationModal: false });
    }

    handleShowDetailsModal(transport_id: string) {
        this.setState({ viewRequestDetailsTransportId: transport_id, showDetailsModal: true });
    }

    handleCloseDetailsModal() {
        this.setState({ showDetailsModal: false });
    }



    handleFilterTransportId(event: any) {
        this.setState(prevState => ({
            filterTransportRequests: {
                ...prevState.filterTransportRequests,
                transport_id: event.target.value
            }
        }))
    }

    handleFilterSenderName(event: any) {
        this.setState(prevState => ({
            filterTransportRequests: {
                ...prevState.filterTransportRequests,
                sender_name: event.target.value
            }
        }))
    }

    handleFilterReceiverName(event: any) {
        this.setState(prevState => ({
            filterTransportRequests: {
                ...prevState.filterTransportRequests,
                receiver_name: event.target.value
            }
        }))
    }

    handleFilterFromAnyTimeChanged(event: React.ChangeEvent<HTMLInputElement>) {

        var checked = event.target.checked;

        this.setState(prevState => ({
            filterTransportRequests: {
                ...prevState.filterTransportRequests,
                fromAnyTime: checked
            }
        }))
    }

    handleFilterFromChanged(event: any) {
        var from = event.target.value;

        this.setState(prevState => ({
            filterTransportRequests: {
                ...prevState.filterTransportRequests,
                from: from
            }
        }))
    }

    handleFilterToAnyTimeChanged(event: React.ChangeEvent<HTMLInputElement>) {

        var checked = event.target.checked;

        this.setState(prevState => ({
            filterTransportRequests: {
                ...prevState.filterTransportRequests,
                toAnyTime: checked
            }
        }))
    }

    handleFilterToChanged(event: any) {
        var to = event.target.value;
        this.setState(prevState => ({
            filterTransportRequests: {
                ...prevState.filterTransportRequests,
                to: to
            }
        }))
    }

    handleFilterStatusChanged(event: any) {
        this.setState(prevState => ({
            filterTransportRequests: {
                ...prevState.filterTransportRequests,
                status: event.target.value as Array<number>
            }
        }))
    }

    handleFilterAllocatedToChange(event: any) {
        this.setState(prevState => ({
            filterTransportRequests: {
                ...prevState.filterTransportRequests,
                allocated_to: event.target.value as number
            }
        }))
    }

    /* filter requests */
    handleShowFilterRequestsModal() {
        this.setState({
            showFilterRequestsModal: true,
            filterTransportRequests: {...this.props.filterTransportRequests},
        });
    }

    handleCloseFilterRequestsModal() {
        this.setState({
            showFilterRequestsModal: false,
        });
    }

    handleApplyFilterTrasportRequests() {
        this.props.applyFilterTrasportRequests(this.state.filterTransportRequests);
        this.setState({ showFilterRequestsModal: false });
    }

    handleShowStatusModal(transport_id: string) {

        var req = this.props.transportRequests.find(x => x.transport_id === transport_id);
        var current_status = 0;

        if (req != null)
            current_status = req.status;


        this.setState({
            showStatusModal: true,
            viewStatusTransportId: transport_id,
            changedStatus: current_status,
        });
    }

    handleCloseStatusModal() {
        this.setState({
            showStatusModal: false,
            viewStatusTransportId: "",
            changedStatus: 0,
        });
    }

    handleChangeStatusChanged(event: any) {
        this.setState({ changedStatus: event.target.value as number });
    }

    handleSaveStatusModal() {

        if (this.state.changedStatus === 0) {
            alert('Select new status');
            return;
        }
        this.props.changeStatus(this.state.viewStatusTransportId, this.state.changedStatus);

    }

    handlePriorityUpdate(transport_id: number, priority: number) {
        this.props.updatePriority(transport_id, priority);
    }

    

    render() {
        return (
            <div>
                <Dialog fullScreen
                    onClose={this.handleCloseAllocationModal}
                    aria-labelledby="customized-dialog-title-allocation"
                    open={this.state.showAllocationModal}>
                    <DialogTitle id="customized-dialog-title-allocation" onClose={this.handleCloseAllocationModal}>
                        Request allocation
                    </DialogTitle>
                    <DialogContent dividers>
                        <div style={{ padding: 20 }}>
                            <Grid container spacing={5}>
                                <Grid item xs={12}>
                                    <TableContainer component={Paper}>
                                        <Table aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="right">Transport Id</TableCell>
                                                    <TableCell align="right">User Name</TableCell>
                                                    <TableCell align="right">Status</TableCell>
                                                    <TableCell align="right">Action Description</TableCell>
                                                    <TableCell align="right">Location Description</TableCell>
                                                    <TableCell align="right">Navigation Order</TableCell>
                                                    <TableCell align="right">Sender Name</TableCell>
                                                    <TableCell align="right">Sender Street</TableCell>
                                                    <TableCell align="right">Receiver Street</TableCell>
                                                    <TableCell align="right">Distance</TableCell>
                                                    <TableCell align="right">Duration</TableCell>
                                                    <TableCell align="right">ETA</TableCell>
                                                    <TableCell align="right">Precedence ETA</TableCell>
                                                    <TableCell align="right">Status description</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.props.transportRequests.find(x => x.transport_id === this.state.viewAllocationTransportId)?.allocations?.sort((a, b) => a.type - b.type).map((row: Models.Allocation) => (
                                                    <TableRow key={row.allocation_id}>
                                                        <TableCell align="right">{row.transport_id}</TableCell>
                                                        <TableCell align="right">{row.user_name}</TableCell>
                                                        <TableCell align="right">{row.status_description}</TableCell>
                                                        <TableCell align="right">{row.action_description}</TableCell>
                                                        <TableCell align="right">{row.location_description}</TableCell>
                                                        <TableCell align="right">{row.navigation_order}</TableCell>
                                                        <TableCell align="right">{row.details_first_line}</TableCell>
                                                        <TableCell align="right">{row.details_second_line}</TableCell>
                                                        <TableCell align="right">{row.details_third_line}</TableCell>
                                                        <TableCell align="right">{row.distance_total}</TableCell>
                                                        <TableCell align="right">{row.duration_total}</TableCell>
                                                        <TableCell align="right">{row.estimated_time_arrival}</TableCell>
                                                        <TableCell align="right">{row.precedence_estimated_time_arrival}</TableCell>
                                                        <TableCell align="right">{row.in_time_status_description}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                                <Grid item xs={3}>
                                    Sender name:
                                </Grid>
                                <Grid item xs={9}>
                                    {this.props.transportRequests.find(x => x.transport_id === this.state.viewAllocationTransportId)?.details.sender_name}
                                </Grid>
                                <Grid item xs={6}>
                                    <Grid container spacing={5}>
                                        <Grid item xs={3}>
                                            Allocate direct:
                                        </Grid>
                                        <Grid item xs={9}>
                                            <Select
                                                id="allocate-active-shippers-select"
                                                value={this.state.allocationUser}
                                                input={<Input />}
                                                MenuProps={utils.MenuProps}
                                                onChange={this.handleAllocationUserChange}
                                            >                                                
                                                {Object.entries(this.props.users).map(([key, value]) => (
                                                    <MenuItem key={value.user_id} value={value.user_id} >
                                                        {value.user_name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container
                                                style={{textAlign: "center"}}>
                                                <Grid item>
                                                    <Button variant="contained"
                                                        color="primary"
                                                        onClick={(e) => this.handleSaveAllocationModal(1)}>
                                                        Allocate direct
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6}>
                                    <Grid container spacing={5}>
                                        <Grid item xs={3}>
                                            Depot:
                                        </Grid>
                                        <Grid item xs={9}>
                                            <Select
                                                id="allocate-depot-select"
                                                value={this.state.allocationDepot}
                                                input={<Input />}
                                                MenuProps={utils.MenuProps}
                                                onChange={this.handleAllocationDepotChange}
                                            >
                                                <MenuItem key={0} value={0}>Select the depot</MenuItem>
                                                {this.props.depots.map((depot, index) => (
                                                    <MenuItem key={depot.depot_id} value={depot.depot_id} >
                                                        {depot.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={3}>
                                            User to depot:
                                        </Grid>
                                        <Grid item xs={9}>
                                            <Select
                                                id="allocate-user-to-depot-select"
                                                value={this.state.allocationUserToDepot}
                                                input={<Input />}
                                                MenuProps={utils.MenuProps}
                                                onChange={this.handleAllocationUserToDepotChange}
                                            >                                                
                                                {Object.entries(this.props.users).map(([key, value]) => (
                                                    <MenuItem key={value.user_id} value={value.user_id} >
                                                        {value.user_name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </Grid>

                                        <Grid item xs={3}>
                                            User from depot:
                                        </Grid>
                                        <Grid item xs={9}>
                                            <Select
                                                id="allocate-user-from-depot-select"
                                                value={this.state.allocationUserFromDepot}
                                                input={<Input />}
                                                MenuProps={utils.MenuProps}
                                                onChange={this.handleAllocationUserFromDepotChange}
                                            >
                                                {Object.entries(this.props.users).map(([key, value]) => (
                                                    <MenuItem key={value.user_id} value={value.user_id} >
                                                        {value.user_name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container
                                                style={{textAlign: "center"}}>
                                                <Grid item>
                                                    <Button variant="contained"
                                                        color="primary"
                                                        onClick={(e) => this.handleSaveAllocationModal(2)}>
                                                        Allocate through depot
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseAllocationModal}>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog fullScreen onClose={this.handleCloseDetailsModal} aria-labelledby="customized-dialog-title-details" open={this.state.showDetailsModal}>
                    <DialogTitle id="customized-dialog-title-details" onClose={this.handleCloseDetailsModal}>
                        Details
                    </DialogTitle>
                    <DialogContent dividers>
                        <TransportRequest {...{
                            transportId: this.state.viewRequestDetailsTransportId,
                            onCloseCallback: this.handleCloseDetailsModal
                        }}>
                        </TransportRequest>
                    </DialogContent>
                </Dialog>
                <Dialog onClose={this.handleCloseFilterRequestsModal}
                    aria-labelledby="customized-dialog-title-filter-requests"
                    open={this.state.showFilterRequestsModal}
                    fullWidth={true}>
                    <DialogTitle id="customized-dialog-title-filter-requests" onClose={this.handleCloseFilterRequestsModal}>
                        Filters
                    </DialogTitle>
                    <DialogContent dividers>
                        <div style={{ padding: 20 }}>
                            <Grid container spacing={5}>
                                <Grid item xs={3}>
                                    <strong>Transport Id:</strong>
                                </Grid>
                                <Grid item xs={8}>
                                    <TextField
                                        id="FilterTransportId"
                                        name="FilterTransportId"                                        
                                        type="number"
                                        variant="standard"
                                        value={this.state.filterTransportRequests.transport_id}
                                        onChange={this.handleFilterTransportId}
                                    />
                                </Grid>

                                <Grid item xs={3}>
                                    <strong>Sender Name:</strong>
                                </Grid>
                                <Grid item xs={8}>
                                    <TextField
                                        id="FilterSenderName"
                                        name="FilterSenderName"
                                        variant="standard"
                                        value={this.state.filterTransportRequests.sender_name}
                                        onChange={this.handleFilterSenderName}
                                    />
                                </Grid>

                                <Grid item xs={3}>
                                    <strong>Receiver Name:</strong>
                                </Grid>
                                <Grid item xs={8}>
                                    <TextField
                                        id="FilterReceiverName"
                                        name="FilterReceiverName"
                                        variant="standard"
                                        value={this.state.filterTransportRequests.receiver_name}
                                        onChange={this.handleFilterReceiverName}
                                    />
                                </Grid>

                                <Grid item xs={3}>
                                    <strong>From:</strong>
                                </Grid>
                                <Grid item xs={9}>
                                    <Grid container spacing={5}>
                                        <Grid item xs={4}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox name="fromAnyTime"
                                                        id="fromAnyTime"
                                                        color="primary"
                                                        checked={this.state.filterTransportRequests.fromAnyTime}
                                                        onChange={(e) => this.handleFilterFromAnyTimeChanged(e)} />}
                                                label="Any time"
                                            />

                                        </Grid>
                                        <Grid item xs={8}>
                                            <TextField
                                                id="From"
                                                name="From"
                                                label="From"
                                                type="datetime-local"
                                                variant="standard"
                                                disabled={this.state.filterTransportRequests.fromAnyTime}
                                                value={this.state.filterTransportRequests.from}
                                                onChange={this.handleFilterFromChanged}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            
                                <Grid item xs={3}>
                                    <strong>To:</strong>
                                </Grid>
                                <Grid item xs={9}>
                                    <Grid container spacing={5}>
                                        <Grid item xs={4}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox name="toAnyTime"
                                                        id="toAnyTime"
                                                        color="primary"
                                                        checked={this.state.filterTransportRequests.toAnyTime}
                                                        onChange={(e) => this.handleFilterToAnyTimeChanged(e)} />}
                                                label="Any time"
                                            />
                                        </Grid>
                                        <Grid item xs={8}>
                                            <TextField
                                                id="To"
                                                name="To"
                                                label="To"
                                                type="datetime-local"
                                                variant="standard"
                                                disabled={this.state.filterTransportRequests.toAnyTime}
                                                value={this.state.filterTransportRequests.to}
                                                onChange={this.handleFilterToChanged}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            
                                <Grid item xs={3}>
                                    <strong>Status:</strong>
                                </Grid>
                                <Grid item xs={9}>
                                    <FormControl sx={{ margin: 1, minWidth: "120px", maxWidth: "300px" }}>
                                        <Select
                                            id="filer-status-mutiple-select"
                                            multiple
                                            value={this.state.filterTransportRequests.status}
                                            input={<Input />}
                                            MenuProps={utils.MenuProps}
                                            onChange={this.handleFilterStatusChanged}
                                        >
                                            {this.props.transportRequestStatus.map((status, index) => (
                                                <MenuItem key={status.status} value={status.status} >
                                                    {status.description}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={3}>
                                    <strong>Allocated to:</strong>
                                </Grid>
                                <Grid item xs={9}>
                                    <Select
                                        id="filer-allocated-select"
                                        value={this.state.filterTransportRequests.allocated_to}
                                        input={<Input />}
                                        MenuProps={utils.MenuProps}
                                        onChange={this.handleFilterAllocatedToChange}
                                    >                                                
                                        {Object.entries(this.props.users).map(([key, value]) => (
                                            <MenuItem key={value.user_id} value={value.user_id} >
                                                {value.user_name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    
                                </Grid>
                            </Grid>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseFilterRequestsModal}>
                            Close
                        </Button>
                        <Button color="primary" onClick={this.handleApplyFilterTrasportRequests}>
                            Apply
                        </Button>
                    </DialogActions>
                </Dialog>
                
                <Dialog onClose={this.handleCloseStatusModal}
                    aria-labelledby="customized-dialog-title-status"
                    open={this.state.showStatusModal}
                    fullWidth={true}>
                    <DialogTitle id="customized-dialog-title-status" onClose={this.handleCloseStatusModal}>
                        Change Status
                    </DialogTitle>
                    <DialogContent dividers>
                        <div style={{ padding: 20 }}>
                            <Grid container spacing={5}>
                                <Grid item xs={2}>
                                    <strong>Status:</strong>
                                </Grid>
                                <Grid item xs={10}>
                                    <FormControl sx={{ margin: 1, minWidth: "120px", maxWidth: "300px" }}>
                                        <Select
                                            id="change-status-select"
                                            input={<Input />}
                                            value={this.state.changedStatus}
                                            MenuProps={utils.MenuProps}
                                            onChange={this.handleChangeStatusChanged}
                                        >
                                            {this.props.transportRequestStatus.map((status, index) => (
                                                <MenuItem key={status.status} value={status.status} >
                                                    {status.description}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseStatusModal}>
                            Close
                        </Button>
                        <Button color="primary" onClick={this.handleSaveStatusModal}>
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
                <div style={divFixed}>
                    <IconButton color="primary"
                        aria-label="Refresh"
                        onClick={(e) => this.handleRefreshTransportRequests()}>
                        <RefreshIcon />
                    </IconButton>
                    <IconButton color="primary"
                        aria-label="Fit all"
                        onClick={(e) => this.props.handleFitAll()}>
                        <AspectRatioIcon />
                    </IconButton>
                    <IconButton color="primary"
                        aria-label="Filter"
                        onClick={(e) => this.handleShowFilterRequestsModal()}>
                        <FindInPageOutlinedIcon />
                    </IconButton>
                    <IconButton color="primary"
                        aria-label="Recompute route"
                        onClick={(e) => this.handleRecomputeRoute()}>
                        <SettingsIcon />
                    </IconButton>
                    <Grid container spacing={0}>
                        <Grid item xs={10}>
                            <Grid item xs={10}>
                                <small>
                                    {"Upd time: " + (this.props.transportRequestsDate === "" ? "N/A" : this.props.transportRequestsDate)}
                                </small>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
                <div style={divStyleToast}>
                    {this.props.transportRequests.map((req: MapStore.TransportRequest, index) => {
                        return (
                            !utils.filterTransportRequest(req, this.props.filterTransportRequests)) ? "" :
                            <TransportRequestItem
                                key={req.transport_id}
                                req={req}
                                handleShowStatusModal={this.handleShowStatusModal}
                                handleSetVisibleTranportRequest={this.handleSetVisibleTranportRequest}
                                handleShowDetailsModal={this.handleShowDetailsModal}
                                handleShowAllocationModal={this.handleShowAllocationModal} 
                                handlePriorityUpdate={this.handlePriorityUpdate}
                            />
                    }
                    )}
                </div>
            

            </div>
        );
    }
}

export default connect(
    (state: ApplicationState) => state.map, // Selects which state properties are merged into the component's props
    MapStore.actionCreators // Selects which action creators are merged into the component's props
)(withWindowDimensions(MapTransportRequests)); //TODO lasam cu withWindowDimensions chair daca nu e folosit pt ca nu merge sa transmit "handleFitAll" ca props ... nu stiu ce are zice ca nu vede handleFitAll ...
