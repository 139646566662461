import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface SignalRState {
    isLoggedIn: boolean;
    triggerConnect: boolean;
    triggerDisconnect: boolean;
}


// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

export interface SetLoggedInAction {
    type: 'SIGNALR_SET_LOGGED_IN';
}

export interface SetLoggedOutAction {
    type: 'SIGNALR_SET_LOGGED_OUT';
}

interface ResetTriggerConnectAction {
    type: 'SIGNALR_RESET_TRIGGER_CONNECT';
}

interface ResetTriggerDisconnectAction {
    type: 'SIGNALR_RESET_TRIGGER_DISCONNECT';
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = SetLoggedInAction |
    SetLoggedOutAction |
    ResetTriggerConnectAction |
    ResetTriggerDisconnectAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    setLoggedIn: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'SIGNALR_SET_LOGGED_IN' });        
    },
    setLoggedOut: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'SIGNALR_SET_LOGGED_OUT' });
    },
    resetTriggerConnect: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'SIGNALR_RESET_TRIGGER_CONNECT' });
    },
    resetTriggerDisconnect: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'SIGNALR_RESET_TRIGGER_DISCONNECT' });
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: SignalRState = { isLoggedIn: false, triggerConnect: false, triggerDisconnect: false };

export const reducer: Reducer<SignalRState> = (state: SignalRState | undefined, incomingAction: Action): SignalRState => {
    if (state === undefined) {
        return unloadedState;
    }
    
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'SIGNALR_SET_LOGGED_IN':
            return {
                isLoggedIn: true,
                triggerConnect: true,
                triggerDisconnect: false,
            };
        case 'SIGNALR_SET_LOGGED_OUT':
            return {
                isLoggedIn: false,
                triggerConnect: false,
                triggerDisconnect: true,
            };
        case 'SIGNALR_RESET_TRIGGER_CONNECT':
            return {
                isLoggedIn: state.isLoggedIn,
                triggerConnect: false,
                triggerDisconnect: state.triggerDisconnect,
            };
        case 'SIGNALR_RESET_TRIGGER_DISCONNECT':
            return {
                isLoggedIn: state.isLoggedIn,
                triggerConnect: state.triggerConnect,
                triggerDisconnect: false,
            };
        default:
            return state;
    }
};