import React, { Component } from 'react';

export class Home extends Component {
    static displayName = Home.name;

    render() {
        return (
            <figure id="main-img">
                <img src={"/images/background.jpg"} alt="Italian Trulli"></img>
            </figure>
            /*
            <div style={{ height: '100%' }}>
                <div className="container-fluid homepage-bgimage"></div>
            </div>
            */
        );
    }
}

