import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import * as ApiInterface from './ApiInterface';
import { http_axios_post, http_axios_get, http_axios_put } from './ApiInterface';


// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface TransportRequestPropsToPass {    
    transportId: number;
    onCloseCallback(): void;
}

export interface TransportRequestState {    
    isLoadingData: boolean;
    isLoadedData: boolean;
    formSchema: Array<Array<Element>>;
    formValues: {};    
    triggerInitForm: boolean;
    triggerLoad: boolean;
    isSavining: boolean;
    transportRequest: TransportRequest;
    senderList: TransportRequestSender[];
}

export class Element {
    type: string = "";
    name: string = "";
    label: string = "";
    required: boolean = false;
    col: number = 1;
    options?: Array<OptionValue>;
}

export class OptionValue {
    label: string = "";
    value: string = "";
}

export class TransportRequest {
    TransportId: number = 0;
    Details: string = "";

}

export class TransportRequestSender {
    sender_id: number = 0;
    sender_name: string = "";
    details: string = "";
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.


export interface SaveAction {
    type: 'TRANSPORT_REQUESTS_SAVE';
}
export interface ReceiveSaveAction {
    type: 'TRANSPORT_REQUESTS_RECEIVE_SAVE';
    triggerLoad: boolean;
}

export interface RequestDataAction {
    type: 'TRANSPORT_REQUESTS_DATA';
}

export interface ReceiveDataAction {
    type: 'TRANSPORT_REQUESTS_RECEIVE_DATA';
    formSchema: Array<Array<Element>>;
    formValues: {};
    transportRequest: TransportRequest;
    senderList: TransportRequestSender[];
}

export interface ResetTriggerInitFormsAction {
    type: 'TRANSPORT_REQUESTS_RESET_TRIGGER_INIT_FORM';
}

export interface ResetTriggerLoadAction {
    type: 'TRANSPORT_REQUESTS_RESET_TRIGGER_LOAD';
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = ApiInterface.SetErrorAction |
    ApiInterface.SetInfoAction |
    SaveAction |
    ReceiveSaveAction |
    RequestDataAction |
    ReceiveDataAction |
    ResetTriggerInitFormsAction |
    ResetTriggerLoadAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    ...ApiInterface.actionCreators,
    requestSaveData: (transportId: number, values: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        var url = '';


        var request = new TransportRequest();
        request.TransportId = transportId;
        request.Details = values;

        var request_json = JSON.stringify(request);

        if (appState) {
            url = 'api/TransportRequests';

            if (transportId > 0) {

                url = url + '/' + transportId;

                http_axios_put(url, request_json)
                    .then(response => {
                        dispatch({ type: 'TRANSPORT_REQUESTS_RECEIVE_SAVE', triggerLoad: true });
                    })
                    .catch(function (e) {
                        console.log(e);
                    });
            }
            else {
                http_axios_post(url, request_json)
                    .then(response => {
                        dispatch({ type: 'TRANSPORT_REQUESTS_RECEIVE_SAVE', triggerLoad: false });
                    })
                    .catch(function (e) {
                        console.log(e);
                    });

                dispatch({ type: 'TRANSPORT_REQUESTS_SAVE' });
            }
        }
    },    
    requestEditData: (transportId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {        
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState) {
            Promise.all([
                http_axios_get(`api/FormSchemas/${transportId}`)
                    .then(list => {
                        return list;
                    }),
                http_axios_get('api/TransportRequests', { "transportId": transportId })
                    .then(response => {
                        return response;
                    }),
                http_axios_get('api/TransportRequestsSender')
                    .then(response => {
                        return response;
                    }),
            ]).then(([schema, request, sender]) => {                
                
                const formSchema = schema as Array<Array<Element>>;
                
                const transportRequest = request as TransportRequest;
                var formValues = JSON.parse(transportRequest.Details);

                const senderList = sender as TransportRequestSender[];

                dispatch({
                    type: 'TRANSPORT_REQUESTS_RECEIVE_DATA',
                    formSchema: formSchema,
                    formValues: formValues,
                    transportRequest: transportRequest,
                    senderList: senderList
                });
            })
                .catch(function (e) {
                    console.log(e);
                });

            dispatch({ type: 'TRANSPORT_REQUESTS_DATA' });
        }

    },
    resetTriggerInitForm: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState) {
            dispatch({ type: 'TRANSPORT_REQUESTS_RESET_TRIGGER_INIT_FORM' });
        }
    },
    resetTriggerLoad: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState) {
            dispatch({ type: 'TRANSPORT_REQUESTS_RESET_TRIGGER_LOAD' });
        }
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: TransportRequestState = {
    isSavining: false,
    isLoadingData: false,
    isLoadedData: false,
    formSchema: [],
    formValues: {},
    triggerInitForm: false,
    triggerLoad: false,
    transportRequest: { TransportId: 0, Details: "" },
    senderList: [],
};

export const reducer: Reducer<TransportRequestState> = (state: TransportRequestState | undefined, incomingAction: Action): TransportRequestState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {        
        case 'TRANSPORT_REQUESTS_SAVE':
            return {
                isLoadingData: state.isLoadingData,
                isLoadedData: state.isLoadedData,
                formSchema: state.formSchema,
                formValues: state.formValues,
                triggerInitForm: state.triggerInitForm,
                triggerLoad: state.triggerLoad,
                isSavining: true,
                transportRequest: state.transportRequest,
                senderList: state.senderList,
            };
        case 'TRANSPORT_REQUESTS_RECEIVE_SAVE':
            return {
                isLoadingData: state.isLoadingData,
                isLoadedData: state.isLoadedData,
                formSchema: state.formSchema,
                formValues: state.formValues,
                triggerInitForm: state.triggerInitForm,
                triggerLoad: action.triggerLoad,
                isSavining: false,
                transportRequest: state.transportRequest,
                senderList: state.senderList,
            };
        case 'TRANSPORT_REQUESTS_DATA':
            return {
                isLoadingData: true,
                isLoadedData: state.isLoadedData,
                formSchema: state.formSchema,
                formValues: state.formValues,
                triggerInitForm: state.triggerInitForm,
                triggerLoad: state.triggerLoad,
                isSavining: state.isSavining,
                transportRequest: state.transportRequest,
                senderList: state.senderList,
            };
        case 'TRANSPORT_REQUESTS_RECEIVE_DATA':
            return {
                isLoadingData: false,
                isLoadedData: true,
                formSchema: action.formSchema,
                formValues: action.formValues,
                transportRequest: action.transportRequest,
                triggerInitForm: true,
                triggerLoad: state.triggerLoad,
                isSavining: state.isSavining,
                senderList: action.senderList,
            };
        case 'TRANSPORT_REQUESTS_RESET_TRIGGER_INIT_FORM':
            return {
                isLoadingData: state.isLoadingData,
                isLoadedData: state.isLoadedData,
                formSchema: state.formSchema,
                formValues: state.formValues,
                triggerInitForm: false,
                triggerLoad: state.triggerLoad,
                isSavining: state.isSavining,
                transportRequest: state.transportRequest,
                senderList: state.senderList,
            };
        case 'TRANSPORT_REQUESTS_RESET_TRIGGER_LOAD':
            return {
                isLoadingData: state.isLoadingData,
                isLoadedData: state.isLoadedData,
                formSchema: state.formSchema,
                formValues: state.formValues,
                triggerInitForm: state.triggerInitForm,
                triggerLoad: false,
                isSavining: state.isSavining,
                transportRequest: state.transportRequest,
                senderList: state.senderList,
            };

        default:
            return state;
    }
};
