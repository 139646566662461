import { SxProps, Theme, useMediaQuery } from '@mui/material';

import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';

import * as Models from "../../../models/Models";
import moment from 'moment';


import {
	PlaylistAddOutlined as PlaylistAddOutlinedIcon,
	DoneAllOutlined as DoneAllOutlinedIcon,
	LocalShippingOutlined as LocalShippingOutlinedIcon,
	BuildOutlined as BuildOutlinedIcon,
	MoreHorizOutlined as MoreHorizOutlinedIcon
} from '@mui/icons-material';

import CardHeader from '../cardHeader';




const StatusCard : React.FC< { caller: string, status_journal: Models.StatusJournal[], children?: React.ReactNode, sx?: SxProps } > = (props) => {
	
	
	const is_mobile = useMediaQuery((theme:Theme) => theme.breakpoints.down('sm'));
	
	const dir_stepper = is_mobile ? "column": "row";

	const item_width =  is_mobile ? '100%' : `${1 / props.status_journal.length * 100}%`;

	return (
		<Card sx={{ ...props.sx }}>
			<CardHeader
				title="Status livrare"
				size="small"
				sx={{
					mb: 1,
				}}				
			/>
			<Stack direction={dir_stepper} spacing={0} sx={{width: 'w-full'}}>
				{props.status_journal.map((status, index) => (
					<ActivityListItem key={index} caller={props.caller} status={status} item_width={item_width} />
				))}				
			</Stack>
			{props.children}
		</Card>
	);
}

const ActivityListItem : React.FC< { caller: string, status: Models.StatusJournal, item_width: string } > = (props) => {

	const is_mobile = useMediaQuery((theme:Theme) => theme.breakpoints.down('sm'));
	const dir_stepper_content = is_mobile ? "row" : "column"; //desktop

	const sx_desktop = {
		'&::before': {
			content: '""',
			position: 'absolute',
			top: 25,
			right: 0,
			width: '100%',
			height: 2,
			backgroundColor: '#DEE2E6',
		}
	};
	
	const sx_mobile = {
		'&::before': {
			content: '""',
			position: 'absolute',
			top: 0,
			left: 25,
			height: '100%',
			borderLeft: 2,
			borderColor: '#DEE2E6',
		}		
	};

	const Icon  = props.caller === 'awb' ? 
					props.status.status === 1 ? PlaylistAddOutlinedIcon :
					props.status.status === 2 ? BuildOutlinedIcon :
					props.status.status === 3 ? LocalShippingOutlinedIcon :
					props.status.status === 4 ? DoneAllOutlinedIcon :
					MoreHorizOutlinedIcon
					:
					DoneAllOutlinedIcon
					;
	

	const color = props.status.date_stamp ? 'success.main' : 'text.secondary';

	

	return (
		<div style={{ width: props.item_width}}>
			<Stack
				direction={dir_stepper_content}
				position="relative"
				spacing={2}
				overflow="hidden"
				alignItems="center"
				sx={is_mobile ? sx_mobile : sx_desktop}
			>
				{Icon &&
				<Avatar
					sx={{
						bgcolor: color,
						width: 50,
						height: 50,
						mx: 'auto',
					}}
				>
					<Icon fontSize="medium" />
				</Avatar>
				}
				<Stack spacing={0.5} p={1} alignItems={is_mobile ? "left" : "center"} sx={{ minHeight: '65px' }}>
					<Typography variant="h6">{props.status.status_desc}</Typography>
					<Typography variant="body1" color="text.secondary">
						{props.status.date_stamp ? `${moment(props.status.date_stamp).format('DD.MM.YYYY HH:mm:ss')}` : '-'}
					</Typography>
					{props.status.user_name &&
						<Typography variant="body1" color="text.secondary">							
							{`by ${props.status.user_name}`}
						</Typography>
					}
				</Stack>
			</Stack>
		</div>
	);
}

export default StatusCard;
