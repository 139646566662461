import { PayloadAction, createSlice } from '@reduxjs/toolkit';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface  ApiInterfaceState {
    error?: string | null;
    info?: string | null;
}

const unloadedState: ApiInterfaceState = {
    error: null,
    info: null,
};

/* new reducer */

const slice = createSlice({
    name: 'ApiInterface',
    initialState: unloadedState,
    reducers: {
        setError(state, action: PayloadAction<string>) {
            state.error = action.payload        
        },
        resetError(state) {
            state.error = null
        },
        setInfo(state, action: PayloadAction<string>) {
            state.info = action.payload        
        },
        resetInfo(state) {
            state.info = null
        },
    },
  })
  
  // Extract the action creators object and the reducer
  const { actions, reducer } = slice
  // Extract and export each action creator by name
  export const { setError, resetError, setInfo, resetInfo } = actions
  // Export the reducer, either as a default or named export
  export default reducer