import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { useCallback } from "react";
import authService from "../components/api-authorization/AuthorizeService";
import queryString from "query-string";
import { useAppDispatch } from "../store/configureStore";
import { setError, setInfo } from "../store/ApiInterface2";
import type { BaseQueryFn } from '@reduxjs/toolkit/query'
import { ApplicationPaths } from "../components/api-authorization/ApiAuthorizationConstants";
import { navigateToUrl } from "../components/Navigation";

export const checkTokenValidTryRefreshIfNot = async () : Promise<boolean> => {
    var token_valid: boolean = false;

    //durata de executie in productie, pt cazul in care tokenul este valid este intre 3 si 10 ms

    if (authService.userManager) {
        const user = await authService.userManager.getUser();

        if (user != null) {
            if (!user.expired) {
                token_valid = true;
            }
            else {
                let retryStep = 0;
                while (retryStep < 5) {
                    retryStep += 1;
                    try {
                        await authService.ensureUserManagerInitialized();
                        const user = await authService.userManager.signinSilent();
                        authService.updateState(user);
                        token_valid = true;
                        break;
                    } catch (err) {
                        console.log(err);
                    }
                }
            }
        }
    }

    //
    // nu mai facem redirect la logout in checkTokenValidTryRefreshIfNot
    // pt ca se poate intampla ca aplicatia sa fie offline si sa nu poata obtine token
    // lasam redirect la logout doar in functia parseAxiosError - daca returneaza 401
    // pt asta nu mai validam daca raspunsul checkTokenValidTryRefreshIfNot este true / false - lasam sa apeleze API-ul chiar daca nu are token valid si daca returneaza 401 API-ul atunci facem redirect la logout
    /*
    if (!token_valid){
        navigateToUrl(ApplicationPaths.LogOut);
    }
    */

    return token_valid;
}

export const parseAxiosError = async (axiosError: any): Promise<{status: number, errMsg: string}> => {
    let err = axiosError as AxiosError
        
    var errMsg = ''    
    if (err.response && err.response.status === 401) {
        errMsg = 'You are not authorized';
        navigateToUrl(ApplicationPaths.LogOut);
    }
    else if (err.response && err.response.status === 403) {
        errMsg = 'Access forbidden';
    }
    else {

        const apiResponse = await err.response?.data as any;
        
        if (apiResponse?.error) //cazul in care eroarea este returnata prin ApiResponseUtil.BadRequestResponse
        {
            errMsg = apiResponse.error;
        }
        else if (apiResponse instanceof Blob && apiResponse.type === 'text/plain') //cazul apelurilor cu responseType: 'blob' si in care eroarea este retunata prin BadRequest(<error>); sunt apelurile in care se downlodeaza binar fisiere pdf sau xlsx
        {
            errMsg = await apiResponse.text();
        }
        else if (apiResponse != null && typeof apiResponse === 'string') //cazul in care eroarea este retunata prin BadRequest(<error>)
        {
            errMsg = apiResponse;
        }
        else {
            errMsg = 'Error occured processing API request';                
        }
    }
    
    return { status: err.response?.status || 0, errMsg }
}

//folosiat de rtk query
export const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: '' }
  ): BaseQueryFn<
    {
      url: string      
      method: AxiosRequestConfig['method']
      data?: AxiosRequestConfig['data']
      params?: AxiosRequestConfig['params']
      
    },
    unknown,
    unknown
  > =>
  async ({ url, method, data, params }, api) => {
    try {
        const token_valid = await checkTokenValidTryRefreshIfNot();

        if (!token_valid){
            console.log('token not valid (1)');
        }
        
        const token = await authService.getAccessToken();
        const result = await axios({ 
            url: baseUrl + url, 
            method, 
            data, 
            params, 
            headers:{
                'authorization': `Bearer ${token}`
            }
        })

        if (result.status === 200 /*OK*/ || result.status === 201 /*created*/) {
            const apiResponse = await result.data;
    
            if (apiResponse.error) {
                api.dispatch(setError(apiResponse.error));
                throw apiResponse.error;
            }
            
            if (apiResponse.info) {
                api.dispatch(setInfo(apiResponse.info));
            }
            
            if (result.headers['location']) {
                navigateToUrl(result.headers['location']);
            }
    
            return { data: JSON.parse(apiResponse.payload) }
        }
        else {
            throw new Error("Unknow status code");
        }
        
        
    }
    catch (axiosError) {

        const err = await parseAxiosError(axiosError);        

        api.dispatch(setError(err.errMsg));

        return {
            error: {
              status: err.status,
              data: err.errMsg,
            },
        }
    }
}

function isPdfViewerEnabled() {    
    // Modern borwsers
    // https://developer.mozilla.org/en-US/docs/Web/API/Navigator/pdfViewerEnabled
    //suportat de majoritatea browserlor la 28.12.2023; nu mai caut si prin navigator.mimeTypes pt ca este marcat ca deprecated
    if (navigator.pdfViewerEnabled !== undefined) {
        return navigator.pdfViewerEnabled;
    }

    return false;
}

export function useAxiosUtil() {
    
    const dispatch = useAppDispatch();

    const download = useCallback(async (request: RequestInfo, type: string, file_name: string, params?: any) => {
        var token = null;
        var response: AxiosResponse;
        var qs = '';

        
        token = await authService.getAccessToken();
        

        if (params != null) {
            qs = '?' + queryString.stringify(params);
        }

        try {        
            response = await axios.get(request.toString() + qs,
            {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
                responseType: 'blob', // Important
            });

            var content_type = response.headers["content-type"];            
            
            var filename = "";
            var disposition = response.headers['content-disposition'];
            if (disposition && disposition.indexOf('attachment') !== -1) {
                var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                var matches = filenameRegex.exec(disposition);
                if (matches != null && matches[1]) {
                    filename = matches[1].replace(/['"]/g, '');
                }
            }
            
            const hasPDFViewer = isPdfViewerEnabled(); //daca pdf view-erul nu e enabled atunci downlodam fisierul si o sa fie deschis manual din folderul downloads; daca e pdf si este view-ul enabled afisam pdf-ul intr-un tab nou fara a fi nevoie sa fie deschis manual dupa download

            //Create a Blob from the PDF Stream
            const file = new Blob([response.data], { type: content_type });
            //Build a URL from the file            
            const fileURL = URL.createObjectURL(file);

            if (hasPDFViewer === false || type === "xlsx") {                
                const link = document.createElement('a');                
                link.href = fileURL;
                link.setAttribute('target', '_blank');
                link.setAttribute('rel', 'noopener noreferrer');
                link.setAttribute('download', filename || file_name); //or any other extension
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
            else if (type === "pdf") {
                //Open the URL on new Window
                const pdfWindow = window.open();
                
                if (pdfWindow != null) {
                    pdfWindow.document.title = filename || file_name;
                    pdfWindow.location.href = fileURL;

                    setTimeout(function() {
                        pdfWindow.document.title = filename || file_name;
                    }, 500);
                }
            }

            URL.revokeObjectURL(fileURL);
        }
        catch (axiosError) {
            const err = await parseAxiosError(axiosError);            
            dispatch(setError(err.errMsg));
        }

    }, [dispatch]);

    const download_as_object_url = useCallback((request: RequestInfo) => new Promise<any>(async (resolve, reject) => {
        var token = null;
        var response: AxiosResponse;
        var qs = '';
        
        token = await authService.getAccessToken();
        

        try {        
            response = await axios.get(request.toString() + qs,
            {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
                responseType: 'blob', // Important
            });        

            const file = new Blob([response.data], { type: response.headers["content-type"] });
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);

            resolve(fileURL);
        }
        catch (axiosError) {
            const err = await parseAxiosError(axiosError);            
            dispatch(setError(err.errMsg));
            reject(err.errMsg);
        }

    }), [dispatch]);

    const axios_get = useCallback((request: RequestInfo, params?: any) => new Promise<any>(async (resolve, reject) => {
        var token = null;
        var response: AxiosResponse;
        var qs = '';
        
        token = await authService.getAccessToken();        

        if (params != null) {
            qs = '?' + queryString.stringify(params);
        }

        try {
            response = await axios.get(request.toString() + qs,
                {
                    headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
                });
            const apiResponse = await response.data;
            const result = JSON.parse(apiResponse.payload)
            resolve(result);
        }
        catch (axiosError) {
            const err = await parseAxiosError(axiosError);            
            dispatch(setError(err.errMsg));
            reject(err.errMsg);
        }

    }), [dispatch]);


    return { download, download_as_object_url, axios_get };
}

