import moment from 'moment';

export const joinArrayItems = (arr: Array<string | number | undefined>) : string => {
    //https://stackoverflow.com/questions/19902860/join-strings-with-a-delimiter-only-if-strings-are-not-null-or-empty
    return arr.filter(x => x).join('/'); //.filter(Boolean) (which is the same as .filter(x => x)) removes all "falsy" values (nulls, undefineds, empty strings etc)
}

export const ten = function (i: number) {
    return (i < 10 ? '0' : '') + i;
}

export function formatDate(date: Date | null): string {
    if (date == null)
        return ""
    else
        return moment(date).format('YYYY-MM-DD');
}

export function formatDateDDMMYYYY(date: string | null): string {
    if (date === null || date === '')
        return ""
    else
        return moment(date).format('DD.MM.YYYY');
}

export function formatDateYYYYMMDD(date: string | null): string | null {
    if (date === null || date === '')
        return null
    else
        return moment(date, 'DD.MM.YYYY').format('YYYY-MM-DD');
}

export function isDateValid(date: string): boolean {
    return moment(date, 'YYYY-MM-DD', true).isValid();
}

export const getDateISO = function (offset: number = 0) {
    var d = new Date();
    d.setDate(d.getDate() + offset);

    var ret = d.getFullYear() + "-" + ten(d.getMonth() + 1) + "-" + ten(d.getDate());

    return ret;
}

export const getDateTimeISO = function (offset: number = 0) {
    var d = new Date();
    d.setDate(d.getDate() + offset);

    var ret = d.getFullYear() + "-" + ten(d.getMonth() + 1) + "-" + ten(d.getDate()) + "T" + ten(d.getHours()) + ":" + ten(d.getMinutes());
    

    return ret;
}

export const getTimezoneOffset = function () {
    var d = new Date();
    return d.getTimezoneOffset();
}

//TODO: de vazut cum se face mai bine
//daca nu fac asa atunci instructiunea: < Grid item xs = { utils.getGridCol(element.col) } >
//da eroare cum ca xs nu accepta decat numar intre 1 si 12

type Col = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export const getGridCol = function (col: number): Col
{
    if (col === 1)
        return 1;
    else if (col === 2)
        return 2;
    else if (col === 3)
        return 3;
    else if (col === 4)
        return 4;
    else if (col === 5)
        return 5;
    else if (col === 6)
        return 6;
    else if (col === 7)
        return 7;
    else if (col === 8)
        return 8;
    else if (col === 9)
        return 9;
    else if (col === 10)
        return 10;
    else if (col === 11)
        return 11;
    else if (col === 12)
        return 12;
    else
        return 2;
        
}


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export function groupArrayOfObjects(list: any, key:any) {
    return list.reduce(function (rv: any, x: any) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};


export const pageVisibilityApi = () => {
    let hidden, visibilityChange;
    var doc: any = document;

    if (typeof doc.hidden !== "undefined") {
        // Opera 12.10 and Firefox 18 and later support
        hidden = "hidden";
        visibilityChange = "visibilitychange";
    } else if (typeof doc.msHidden !== "undefined") {
        hidden = "msHidden";
        visibilityChange = "msvisibilitychange";
    } else if (typeof doc.webkitHidden !== "undefined") {
        hidden = "webkitHidden";
        visibilityChange = "webkitvisibilitychange";
    }
    else {
        visibilityChange = "visibilitychange";
        hidden = "hidden";
    }

    return { hidden, visibilityChange };
};


export function getBoolean(value: any) {
    switch (value) {
        case true:
        case "true":
        case 1:
        case "1":
        case "on":
        case "yes":
        case "Yes":
            return true;
        default:
            return false;
    }
};


export function decodeTokenAsJson(token: string) {
    //https://stackoverflow.com/questions/38552003/how-to-decode-jwt-token-in-javascript-without-using-a-library
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    var jsonObject = JSON.parse(jsonPayload);

    return jsonObject;
};

//https://stackoverflow.com/questions/41431322/how-to-convert-formdatahtml5-object-to-json
export function formToJSON(elem?: FormData) {
    let output = {};
    if (elem != null) {
        elem.forEach(
            (value, key) => {
                // Check if property already exist
                if (Object.prototype.hasOwnProperty.call(output, key)) {
                    let current = output[key];
                    if (!Array.isArray(current)) {
                        // If it's not an array, convert it to an array.
                        current = output[key] = [current];
                    }
                    current.push(value); // Add the new value to the array.
                } else {
                    output[key] = value;
                }
            }
        );
    }
    return JSON.stringify(output);
}

//https://advancedweb.hu/how-to-serialize-calls-to-an-async-function/
export const serialize = (fn: (...args: any[]) => any ) => {
    let queue = Promise.resolve();

    return (...args: any[]) => {
        const res = queue.then(() => fn(...args));
        queue = res.catch(() => { });
        return res;
    };
};


export function formatSpeed(speed: number): string {
    if (speed === -1)
        return "N/A"
    else
        return (speed * 3.6).toFixed(0) + " km/h";
}

export function shallowCompare(obj1: any, obj2: any): boolean {
    var obj_1_null_undefined = false;
    var obj_2_null_undefined = false;

    if (obj1 === null || obj1 === undefined)
        obj_1_null_undefined = true;

    if (obj2 === null || obj2 === undefined)
        obj_2_null_undefined = true;

    if (obj_1_null_undefined === true && obj_2_null_undefined === true)
        return true;
    else if ((obj_1_null_undefined === true && obj_2_null_undefined === false) || (obj_1_null_undefined === false && obj_2_null_undefined === true))
        return false;
    else /*obj1 si obj2 nu sunt null*/
        return Object.keys(obj1).length === Object.keys(obj2).length &&
            Object.keys(obj1).every(key =>
                obj2.hasOwnProperty(key) && obj1[key] === obj2[key]);
}


export const toBase64 = (file:File) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

export const checkJpegPngSignature = (file: File) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    var result = false;
    reader.readAsArrayBuffer(file);
    reader.onloadend = (e: ProgressEvent<FileReader>) => {
        if (e.target != null && e.target.result != null) {
            var arr = (new Uint8Array(e.target.result as ArrayBuffer)).subarray(0, 4);
            var header = "";
            for (var i = 0; i < arr.length; i++) {
                header += arr[i].toString(16);
            }

            if (file.type === 'image/jpeg')
                if (header === "ffd8ffe0" ||
                    header === "ffd8ffe1" ||
                    header === "ffd8ffe2" ||
                    header === "ffd8ffe3")
                    result = true;

            if (file.type === 'image/png')
                if (header === "89504e47")
                    result = true;

            resolve(result);
            /*
            if (result)
                successCallback(file);
            else {
                alert('File is invalid');
            }
            */
        }
    };
    reader.onerror = error => reject(error);
});

export function generateAvatar(name: string) {

    if (name != null) {
        var initials = name.split(' ').map(function (str) { return str ? str[0].toUpperCase() : ""; }).join('');
        var canvas = document.createElement('canvas');
        var radius = 30;
        var margin = 5;
        canvas.width = radius * 2 + margin * 2;
        canvas.height = radius * 2 + margin * 2;

        // Get the drawing context
        var ctx = canvas.getContext('2d');
        if (ctx != null) {
            ctx.beginPath();
            ctx.arc(radius + margin, radius + margin, radius, 0, 2 * Math.PI, false);
            ctx.closePath();
            ctx.fillStyle = 'grey';
            ctx.fill();
            ctx.fillStyle = "white";
            ctx.font = "bold 30px Arial";
            ctx.textAlign = 'center';
            ctx.fillText(initials, radius + 5, radius * 4 / 3 + margin);
            //The canvas will never be added to the document.
            return canvas.toDataURL();
        }
    }
    return null;

}

export function delay(time: number) {
    return new Promise(resolve => setTimeout(resolve, time));
}

export function getStatusColor(status: number) {
    //New
    if (status === 1){
        return { color: 'orange', borderColor: 'orange' };
    }
    //Submitted
    else if (status === 2){
        return { color: 'indigo', borderColor: 'indigo' };
    }
    //Taken
    else if (status === 3){
        return { color: 'blue', borderColor: 'blue' };
    }
    //Rejected
    else if (status === 4){
        return { color: 'red', borderColor: 'red' };
    }
    //Finalized
    else if (status === 5){
        return { color: 'green', borderColor: 'green' };
    }

}