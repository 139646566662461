import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { LoginMenu } from './api-authorization/LoginMenu';

import authService from './api-authorization/AuthorizeService';
import './NavMenu.css';

import { AppBar, Toolbar, Typography, Menu, MenuItem, ListItem, Collapse, ListItemText, IconButton } from '@mui/material';
import { Menu as MenuIcon, ExpandLess, ExpandMore } from '@mui/icons-material';

import * as utils from '../store/Utils';

class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleShowAdmin = this.handleShowAdmin.bind(this);

        this.state = {
            collapsed: true,
            showTripsMenu: false,
            showUsersMenu: false,
            anchorEl: null,
            showAdminMenu: false,
        };
    }

    handleClick = (event) => {        
        this.setState({
            anchorEl: event.currentTarget
        });
    };

    handleClose = () => {
        this.setState({
            anchorEl: null,
            showAdminMenu: false,
        });
    };

    handleShowAdmin = () => {
        this.setState({
            showAdminMenu: !this.state.showAdminMenu
        });
    };
    

    //https://stackoverflow.com/questions/951483/how-to-check-if-a-json-response-element-is-an-array
    isArray(what) {
        return Object.prototype.toString.call(what) === '[object Array]';
    }
    

    componentDidMount() {
        (async () => {
            var token = null;
            var showTrips = false;
            var showUsers = false;

            token = await authService.getAccessToken();
            
            if (token != null) {
                var jsonObject = utils.decodeTokenAsJson(token);
                var jsonRolesObject = jsonObject['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];

                var roles = [];

                if (this.isArray(jsonRolesObject))
                    for (const role of jsonRolesObject) {
                        roles.push(role)
                    }
                else
                    roles.push(jsonRolesObject)

                for (const role of roles) {
                    if (role === 'Manager' || role === 'Admin'){
                        showTrips = true;
                        showUsers = true;
                    }
                }

                this.setState({ showTripsMenu: showTrips, showUsersMenu: showUsers });                
            }

        })();
    }

    render() {        
        return (
            <div sx={{ flexGrow: 1 }}>
                <AppBar position="static">
                    <Toolbar>
                        <IconButton edge="start"
                            sx={{ marginRight: 2 }}
                            color="inherit"
                            aria-label="menu"
                            onClick={this.handleClick}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="simple-menu"
                            anchorEl={this.state.anchorEl}
                            keepMounted
                            open={Boolean(this.state.anchorEl)}
                            onClose={this.handleClose}
                        >
                            <MenuItem onClick={this.handleClose} component={Link} to="/">Home</MenuItem>
                            <MenuItem onClick={this.handleClose} component={Link} to="/transportrequest/edit/0">Transport Request</MenuItem>
                            <MenuItem onClick={this.handleClose} component={Link} to="/searchtransportrequests">Search Requests</MenuItem>
                            <MenuItem onClick={this.handleClose} component={Link} to="/map">Map</MenuItem>
                            <MenuItem onClick={this.handleClose} component={Link} to="/zones">Zones</MenuItem>
                            <MenuItem onClick={this.handleClose} component={Link} to="/mobileapp">Mobile App</MenuItem>
                            <MenuItem onClick={this.handleClose} component={Link} to="/upload">Upload</MenuItem>
                            <MenuItem onClick={this.handleClose} component={Link} to="/settlements">Settlements</MenuItem>
                            <MenuItem onClick={this.handleClose} component={Link} to="/documents">Documents</MenuItem>
                            <MenuItem onClick={this.handleClose} component={Link} to="/reports">Reports</MenuItem>
                            <MenuItem onClick={this.handleClose} component={Link} to="/dashboard">Dashboard</MenuItem>

                            <MenuItem onClick={this.handleClose} component={Link} to="/fetchtrips">Trips (alternative login)</MenuItem>


                            <ListItem button="true" onClick={this.handleShowAdmin} >
                                <ListItemText primary={"Admin"} />
                                {this.state.showAdminMenu ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>

                            <Collapse component="li" in={this.state.showAdminMenu} timeout="auto" unmountOnExit>
                                <MenuItem onClick={this.handleClose} component={Link} to="/parameters" sx={{ paddingLeft: 4 }}>Parameters</MenuItem>
                                <MenuItem onClick={this.handleClose} component={Link} to="/companies" sx={{ paddingLeft: 4 }}>Companies</MenuItem>
                                <MenuItem onClick={this.handleClose} component={Link} to="/shops" sx={{ paddingLeft: 4 }}>Shops</MenuItem>
                            </Collapse>

                            

                            {this.state.showUsersMenu &&
                                <MenuItem onClick={this.handleClose} component={Link} to="/fetchusers">Manage Users</MenuItem>
                            }
                            {
                                /*
                                <NavItem>
                                    <NavLink tag={Link} className="text-dark" to="/deployProcess">Deploy process</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={Link} className="text-dark" to="/startProcess/list">Start Process</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={Link} className="text-dark" to="/tasklist">Tasklist</NavLink>
                                </NavItem>
                                */
                            }
                        </Menu>
                        <Typography variant="h6" sx={{ flexGrow: 1 }}>
                            New Motion
                        </Typography>
                        <LoginMenu>
                        </LoginMenu>
                        {/*
                        <LoginMenuAlternative>
                        </LoginMenuAlternative>
                        */}
                    </Toolbar>
                </AppBar>
            </div>
        );
    }
}

export default NavMenu