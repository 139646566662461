import { userConstants } from '../_constants';
import { authHeader } from '../_helpers';
import { decodeTokenAsJson } from '../../store/Utils';
import { store } from '../../store/configureStore';
import { navigateToUrl } from '../../components/Navigation';


export const userService = {
    login,
    clearLocalStorage,
    register,    
    update,
    changePassword,
};

async function login(username, password, refresh_token) {

    var user = {};
    var token = {};
    var loginData = {};
    var details = {};    

    if (refresh_token)
    {
        details = {
            'grant_type': 'refresh_token',
            'refresh_token': refresh_token,
            'client_id': 'client',
            'client_secret': 'secret'
        };
    }
    else {
        details = {
            'username': username,
            'password': password,
            'grant_type': 'password',
            'scope': 'openid profile ReactCamundaAppAPI offline_access',
            'client_id': 'client',
            'client_secret': 'secret'
        };
    }

    var formBody = [];
    for (var property in details) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(details[property]);
        formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
        body: formBody
    };

    return fetch('connect/token', requestOptions)
        .then(handleResponse)
        .then(userJson => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes           

            var access_token = userJson['access_token'];
            var refresh_token = userJson['refresh_token'];
            var token_type = userJson['token_type'];
            var expires_in = userJson['expires_in'];

            var json_access_token = decodeTokenAsJson(access_token);
            var UserName = json_access_token['name'];
            var FirstName = json_access_token['first_name'];
            var LastName = json_access_token['last_name'];
            var Email = json_access_token['email'];
            var UserUUID = json_access_token['user_uuid'];
            var exp = json_access_token['exp'];

            user = {
                UserName: UserName,
                FirstName: FirstName,
                LastName: LastName,
                Email: Email,
                UserUUID: UserUUID,
                Password1: '',
                Password2: '',
                CurrentPassword: '',
            };

            token = {
                access_token: access_token,
                refresh_token: refresh_token,
                exp: exp,
                token_type: token_type,
                expires_in: expires_in,
            }

            loginData = {
                user: user,
                token: token,
            }
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('loginData', JSON.stringify(loginData));

            return user;
        })
        .catch(error => {
            console.log(error);
            logout();
        });
}

function clearLocalStorage() {
    // remove user from local storage to log user out
    localStorage.removeItem('loginData');
}

function logout() {
    // remove user from local storage to log user out
    clearLocalStorage();
    store.dispatch({ type: userConstants.LOGOUT });
    navigateToUrl('/login');
}

function register(user) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };

    return fetch('api/UsersAlternative', requestOptions).then(handleResponse);
}

async function update(user) {

    const token = await authHeader();

    const requestOptions = {
        method: 'PUT',
        headers: { ...token, 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };

    return fetch('api/UsersAlternative', requestOptions).then(handleResponse);;
}

async function changePassword(user) {
    const token = await authHeader();

    const requestOptions = {
        method: 'PUT',
        headers: { ...token, 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };

    return fetch('api/UsersAlternative/ChangePassword', requestOptions).then(handleResponse);;
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {

            var error_message = (data && data.error) || "Error";

            if (response.status === 401 || response.status === 403) {
                // auto logout if 401 response returned from api
                logout();
                //location.reload(true);
                error_message = "Unauthorized";
            }
            
            

            if (response.status === 400) {
                if (data && data.error && data.error_description) {
                    error_message = data.error_description;
                    if (error_message === "invalid_username_or_password") {
                        error_message = "Invalid username or password";
                        logout();
                    }
                }
            }
            
            return Promise.reject(error_message);
        }

        return data;
    });
}