import * as React from 'react';
import { connect } from 'react-redux'
import { ApplicationState } from '../store';
import * as FetchShopStore from '../store/FetchShops';
import * as EditShopStore from '../store/EditShop';
import * as Validator from "../validator/Validator";
import {
    Box,
    Paper,
    Grid,
    TextField,
    Button,
    Switch,
    Select,
    MenuItem,
    Input,
    InputLabel,
    FormControlLabel,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@mui/material';
import { Dialog, DialogTitle, DialogContent, DialogActions } from './StyledComponents'
import * as utils from '../store/Utils';
import { withParams, withNavigation } from '../hocs'
import * as styled from './StyledComponents'

// At runtime, Redux will merge together...

type EditShopProps =
    utils.IClasses
    & EditShopStore.EditShopState // ... state we've requested from the Redux store
    & typeof EditShopStore.actionCreators; // ... plus action creators we've requested
    

interface EditShopState {
    shopData: FetchShopStore.ShopData;
    editShopCompany: FetchShopStore.ShopCompany;
    showEditShopCompaniesPopup: boolean;
}

export class EditShop extends React.PureComponent<EditShopProps, EditShopState> {

    id = 0;

    constructor(props: Readonly<EditShopProps>) {
        super(props);


        this.state = {
            shopData: {
                id: props.shopData.id,
                shop_id: props.shopData.shop_id,
                name: props.shopData.name,
                enabled: props.shopData.enabled,
                pickup_address_country: props.shopData.pickup_address_country,
                pickup_address_area: props.shopData.pickup_address_area,
                pickup_address_locality: props.shopData.pickup_address_locality,
                pickup_address_number: props.shopData.pickup_address_number,
                pickup_address_street: props.shopData.pickup_address_street,
                pickup_contact_person: props.shopData.pickup_contact_person,
                pickup_contact_phone: props.shopData.pickup_contact_phone,
            },
            editShopCompany: {
                id: 0,
                shop_id: 0,
                shop_name: "",
                company_id: 0,
                company_name: "",
                enabled: false,
                ins_user: "",
                ins_date: "",
                upd_user: "",
                upd_date: "",
            },
            showEditShopCompaniesPopup: false

        }


        // This binding is necessary to make "this" work in the callback  
        this.handleSave = this.handleSave.bind(this);
        this.handleClose = this.handleClose.bind(this);
        
        this.handleChangeName = this.handleChangeName.bind(this);
        this.handleChangeEnabled = this.handleChangeEnabled.bind(this);
        this.handleChangePickupAddressCountry = this.handleChangePickupAddressCountry.bind(this);
        this.handleChangePickupAddressArea = this.handleChangePickupAddressArea.bind(this);
        this.handleChangePickupAddressLocality = this.handleChangePickupAddressLocality.bind(this);
        this.handleChangePickupAddressNumber = this.handleChangePickupAddressNumber.bind(this);
        this.handleChangePickupAddressStreet = this.handleChangePickupAddressStreet.bind(this);
        this.handleChangePickupContactPerson = this.handleChangePickupContactPerson.bind(this);
        this.handleChangePickupContactPhone = this.handleChangePickupContactPhone.bind(this);

        this.handleShowShopCompaniesModal = this.handleShowShopCompaniesModal.bind(this);
        this.handleCloseShopCompaniesModal = this.handleCloseShopCompaniesModal.bind(this);
        this.handleChangeShopCompany = this.handleChangeShopCompany.bind(this);
        this.handleChangeShopCompanyEnabled = this.handleChangeShopCompanyEnabled.bind(this);
        this.handleDeleteShopCompany = this.handleDeleteShopCompany.bind(this);
        this.handleSaveShopCompanyModal = this.handleSaveShopCompanyModal.bind(this);

    }



    // This will handle the submit form event.  
    private async handleSave(event: any) {
        event.preventDefault();
        if (this.handleValidation()) {
            this.props.requestSaveData(this.state.shopData, this.id > 0);
        }
    }
    // This will handle Cancel button click event.  
    private handleClose(e: any) {
        e.preventDefault();
        this.props.navigate("/shops");
    }
    
    //https://goshakkk.name/controlled-vs-uncontrolled-inputs-react/    
    handleChangeName(event: any)
    {
        this.setState(prevState => ({
            shopData: {
                ...prevState.shopData,
                name: event.target.value
            }
        }));
    }
    handleChangeEnabled(event: any) {
        this.setState(prevState => ({
            shopData: {
                ...prevState.shopData,
                enabled: event.target.checked
            }
        }));
    }    
    handleChangePickupAddressCountry(event: any) {
        this.setState(prevState => ({
            shopData: {
                ...prevState.shopData,
                pickup_address_country: event.target.value
            }
        }));
    }
    handleChangePickupAddressArea(event: any) {
        this.setState(prevState => ({
            shopData: {
                ...prevState.shopData,
                pickup_address_area: event.target.value
            }
        }));
    }
    handleChangePickupAddressLocality(event: any) {
        this.setState(prevState => ({
            shopData: {
                ...prevState.shopData,
                pickup_address_locality: event.target.value
            }
        }));
    }
    handleChangePickupAddressNumber(event: any) {
        this.setState(prevState => ({
            shopData: {
                ...prevState.shopData,
                pickup_address_number: event.target.value
            }
        }));
    }
    handleChangePickupAddressStreet(event: any) {
        this.setState(prevState => ({
            shopData: {
                ...prevState.shopData,
                pickup_address_street: event.target.value
            }
        }));
    }
    handleChangePickupContactPerson(event: any) {        
        this.setState(prevState => ({
            shopData: {
                ...prevState.shopData,
                pickup_contact_person: event.target.value
            }
        }));
    }
    handleChangePickupContactPhone(event: any) {
        this.setState(prevState => ({
            shopData: {
                ...prevState.shopData,
                pickup_contact_phone: event.target.value
            }
        }));
    }

    handleChangeShopCompanyEnabled(event: any) {
        this.setState(prevState => ({
            editShopCompany: {
                ...prevState.editShopCompany,
                enabled: event.target.checked
            }
        }));
    }

    handleChangeShopCompany(event: any) {
        this.setState(prevState => ({
            editShopCompany: {
                ...prevState.editShopCompany,
                company_id: Number(event.target.value)
            }
        }));
    }

    handleShowShopCompaniesModal(id: number) {
        if (id === 0) {
            this.setState({
                showEditShopCompaniesPopup: true,
                editShopCompany: {
                    id: 0,
                    shop_id: this.id,
                    shop_name: "",
                    company_id: 0,
                    company_name: "",
                    enabled: true,
                    ins_user: "",
                    ins_date: "",
                    upd_user: "",
                    upd_date: "",
                }
            });
        }
        else {
            var shopCompany = this.props.shopCompanies.filter(v => {
                return v.id === id;
            });

            if (shopCompany != null) {
                this.setState({
                    showEditShopCompaniesPopup: true,
                    editShopCompany: {
                        id: shopCompany[0].id,
                        shop_id: shopCompany[0].shop_id,
                        shop_name: shopCompany[0].shop_name,
                        company_id: shopCompany[0].company_id,
                        company_name: shopCompany[0].company_name,
                        enabled: shopCompany[0].enabled,
                        ins_user: shopCompany[0].ins_user,
                        ins_date: shopCompany[0].ins_date,
                        upd_user: shopCompany[0].upd_user,
                        upd_date: shopCompany[0].upd_date,
                    }
                });
            }
        }
    }

    handleCloseShopCompaniesModal() {
        this.setState({ showEditShopCompaniesPopup: false });
    }

    handleDeleteShopCompany(id: number, name: string) {
        if (!window.confirm("Do you want to delete company: " + name))
            return;
        else {
            this.props.requestDeleteShopCompany(id);
        }
    }

    private async handleSaveShopCompanyModal(event: any) {
        event.preventDefault();
        if (this.handleValidationShopCompany()) {
            this.props.requestSaveShopCompany(this.state.editShopCompany);
        }
    }

    // This method is called when the component is first added to the document
    componentDidMount() {
        this.ensureDataFetched();
    }

    // This method is called when the route parameters change
    public componentDidUpdate(prevProps: any, prevState: any) {

        //cand se schimba parametrul "id" din url trebui incarcate datele (este cazul cand se face redirect cu useNavigate)
        if (this.props.params !== prevProps.params) {
            this.ensureDataFetched();
        }

        if (this.props.triggerLoad) {
            this.props.resetTriggerLoad();
            this.ensureDataFetched();
        }
        if (this.props.triggerLoadState) {
            this.props.resetTriggerLoadState();

            this.setState(prevState => ({
                shopData: {
                    id: this.props.shopData.id,
                    shop_id: this.props.shopData.shop_id,
                    name: this.props.shopData.name,
                    enabled: this.props.shopData.enabled,
                    pickup_address_country: this.props.shopData.pickup_address_country,
                    pickup_address_area: this.props.shopData.pickup_address_area,
                    pickup_address_locality: this.props.shopData.pickup_address_locality,
                    pickup_address_number: this.props.shopData.pickup_address_number,
                    pickup_address_street: this.props.shopData.pickup_address_street,
                    pickup_contact_person: this.props.shopData.pickup_contact_person,
                    pickup_contact_phone: this.props.shopData.pickup_contact_phone,
                }
            }));

        }
    }

    private ensureDataFetched() {
        var idS = "0";
        if (this.props.params.id)
            idS = this.props.params.id;

        this.id = parseInt(idS) || 0;

        this.props.requestEditData(this.id);

    }

    private handleValidationShopCompany() {
        let result = true;
        let err = '';

        var Company = this.state.editShopCompany.company_id;

        if (Company === 0) {
            err += "Select shop delivery company!\n";
            result = false;
        }

        if (!result) {
            console.log(err);
            this.props.setError(err)
        }

        return result;
    }

    private handleValidation() {
        let result = true;
        let err = '';

        var name = this.state.shopData.name;

        if (!Validator.isAlphaNumericAndSpaceUnderscore(name)) {
            err += "Name should be alpha numeric or underscore!\n";
            result = false;
        }
        if (name.length <= 0 || name.length > 50) {
            err += "Name should be between 1 and 50 characters!\n";
            result = false;
        }

        if (!result) {
            console.log(err);
            this.props.setError(err)
        }

        return result;
    }

    public render() {        
        return(
            <div>
                <h3>Shop management</h3>
                <hr />
                {
                    this.renderCreateForm()
                }
            </div>
        );
    }
    

    // Returns the HTML Form to the render() method.  
    private renderCreateForm() {
        return (
            <div>
                <Dialog fullWidth={true}
                    onClose={this.handleCloseShopCompaniesModal}
                    aria-labelledby="customized-dialog-title"
                    open={this.state.showEditShopCompaniesPopup}
                >
                    <DialogTitle id="customized-dialog-title" onClose={this.handleCloseShopCompaniesModal}>
                        Edit delivery company
                    </DialogTitle>
                    <DialogContent dividers>
                        <div style={{ padding: 20 }}>
                            <Grid container spacing={5}>
                                <Grid item xs={3}>
                                    Company Name:
                                </Grid>
                                <Grid item xs={9}>
                                    <InputLabel shrink id="labelCompany">
                                        Company
                                    </InputLabel>
                                    <Select
                                        id="Company"
                                        labelId="labelCompany"
                                        value={this.state.editShopCompany.company_id}
                                        input={<Input />}
                                        disabled={this.state.editShopCompany.id > 0 }
                                        MenuProps={utils.MenuProps}
                                        onChange={this.handleChangeShopCompany}
                                    >
                                        {this.props.companies.map(o =>
                                            <MenuItem value={o.company_id}>{o.name}</MenuItem>
                                        )}
                                    </Select>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={this.state.editShopCompany.enabled}
                                                onChange={(e) => this.handleChangeShopCompanyEnabled(e)}
                                                name="UserEnabled"
                                                color="primary"
                                            />
                                        }
                                        labelPlacement="end"
                                        label="Enabled"
                                    />
                                </Grid>
                            </Grid>

                        </div>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseShopCompaniesModal}>
                            Close
                        </Button>
                        <Button color="primary" onClick={this.handleSaveShopCompanyModal}>
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
                <fieldset>
                    <legend>Shop Details</legend>
                    <form name="formEditShop" id="formEditShop" onSubmit={this.handleSave}>
                        <Grid container spacing={5}>
                            <Grid item xs={4}>
                                <Grid container spacing={5}>
                                    <Grid item xs={4}>
                                        <strong>Name:</strong>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <TextField
                                            id="Name"
                                            name="Name"
                                            variant="standard"
                                            value={this.state.shopData.name}
                                            onChange={this.handleChangeName}
                                            required
                                        />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <strong>Enabled:</strong>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Switch
                                            checked={this.state.shopData.enabled}
                                            onChange={(e) => this.handleChangeEnabled(e)}
                                            name="EmailConfirmed"
                                            color="primary"
                                        />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <strong>Pickup Address Country:</strong>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <TextField
                                            id="PickupAddressCountry"
                                            name="PickupAddressCountry"
                                            variant="standard"
                                            value={this.state.shopData.pickup_address_country}
                                            onChange={this.handleChangePickupAddressCountry}
                                            required
                                        />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <strong>Pickup Address Area:</strong>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <TextField
                                            id="PickupAddressArea"
                                            name="PickupAddressArea"
                                            variant="standard"
                                            value={this.state.shopData.pickup_address_area}
                                            onChange={this.handleChangePickupAddressArea}
                                            required
                                        />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <strong>Pickup Address Locality:</strong>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <TextField
                                            id="PickupAddressLocality"
                                            name="PickupAddressLocality"
                                            variant="standard"
                                            value={this.state.shopData.pickup_address_locality}
                                            onChange={this.handleChangePickupAddressLocality}
                                            required
                                        />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <strong>Pickup Address Number:</strong>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <TextField
                                            id="PickupAddressNumber"
                                            name="PickupAddressNumber"
                                            variant="standard"
                                            value={this.state.shopData.pickup_address_number}
                                            onChange={this.handleChangePickupAddressNumber}
                                            required
                                        />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <strong>Pickup Address Street:</strong>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <TextField
                                            id="PickupAddressStreet"
                                            name="PickupAddressStreet"
                                            variant="standard"
                                            value={this.state.shopData.pickup_address_street}
                                            onChange={this.handleChangePickupAddressStreet}
                                            required
                                        />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <strong>Pickup Contact Person:</strong>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <TextField
                                            id="PickupContactPerson"
                                            name="PickupContactPerson"
                                            variant="standard"
                                            value={this.state.shopData.pickup_contact_person}
                                            onChange={this.handleChangePickupContactPerson}
                                            required
                                        />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <strong>Pickup Contact Phone:</strong>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <TextField
                                            id="PickupContactPhone"
                                            name="PickupContactPhone"
                                            variant="standard"
                                            value={this.state.shopData.pickup_contact_phone}
                                            onChange={this.handleChangePickupContactPhone}
                                            required
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Box sx={styled.buttonsBox}>
                                            <Button variant="contained" color="primary" type="submit">
                                                Save
                                            </Button>
                                            <Button variant="contained" color="primary" onClick={this.handleClose}>
                                                Close
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={8}>
                                <Grid>
                                    <Grid item xs={12}>
                                        <h1 id="tabelLabel">Delivery companies</h1>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={5} style={{ textAlign: "center" }}>
                                            <Grid item xs={12}>
                                                <TableContainer component={Paper}>
                                                    <Table aria-label="simple table">
                                                        <TableHead>
                                                            <TableRow>                                                                
                                                                <TableCell align="right">Company Name</TableCell>
                                                                <TableCell align="right">Enabled</TableCell>
                                                                <TableCell align="right">Created by</TableCell>
                                                                <TableCell align="right">Created at</TableCell>
                                                                <TableCell align="right">Updated by</TableCell>
                                                                <TableCell align="right">Updated at</TableCell>
                                                                <TableCell align="right"></TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {this.props.shopCompanies.map((row) => (
                                                                <TableRow key={row.id}>
                                                                    <TableCell align="right">{row.company_name}</TableCell>
                                                                    <TableCell align="right">{row.enabled ? "yes": "no"}</TableCell>
                                                                    <TableCell align="right">{row.ins_user}</TableCell>
                                                                    <TableCell align="right">{row.ins_date === "" ? "" : new Date(row.ins_date).toLocaleString()}</TableCell>
                                                                    <TableCell align="right">{row.upd_user}</TableCell>
                                                                    <TableCell align="right">{row.upd_date === "" ? "" : new Date(row.upd_date).toLocaleString()}</TableCell>
                                                                    <TableCell align="right">
                                                                        <Button color="primary" onClick={(e) => this.handleShowShopCompaniesModal(row.id)}>
                                                                            Edit
                                                                        </Button>
                                                                        <Button color="secondary" onClick={(e) => this.handleDeleteShopCompany(row.id, row.company_name)}>
                                                                            Delete
                                                                        </Button>
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container
                                                    style={{ textAlign: "center" }}>
                                                    <Grid item>
                                                        <Button variant="contained"
                                                            color="primary"
                                                            onClick={(e) => this.handleShowShopCompaniesModal(0)}>
                                                            Add new company
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </fieldset>
            </div>
        )
    }
}

export default connect(
    (state: ApplicationState) => state.editShop, // Selects which state properties are merged into the component's props
    EditShopStore.actionCreators // Selects which action creators are merged into the component's props
)(withParams(withNavigation(EditShop)));



