import * as React from 'react';
import { connect } from 'react-redux'
import { ApplicationState } from '../store';
import * as SettlementsStore from '../store/Settlements';
import { Grid, Button, Input, TextField, InputLabel, Select, MenuItem, FormControl } from '@mui/material';
import { DataGrid, GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import * as Navigation from "../navigation/Navigation";
import Documents from './Documents';
import * as utils from '../store/Utils';


// At runtime, Redux will merge together...
type LocalProps =
    utils.IClasses
    & SettlementsStore.SettlementsState // ... state we've requested from the Redux store        
    & typeof SettlementsStore.actionCreators; // ... plus action creators we've requested



export class Settlements extends React.PureComponent<LocalProps, SettlementsStore.SearchData> {

    columns: GridColDef[] = [
        { field: 'id', headerName: 'SID', width: 100 },
        { field: 'transport_id', headerName: 'TID', width: 100 },
        { field: 'shop_name', headerName: 'Shop Name', width: 200 },
        { field: 'user_name', headerName: 'User Name', width: 200 },
        { field: 'status', headerName: 'Status', width: 120 },
        {
            field: 'amount',
            headerName: 'Amount',
            width: 120,
        },
        {
            field: 'amount_ccy',
            headerName: 'Ccy',
            width: 100
        },
        { field: 'details', headerName: 'Details', width: 250 },

        {
            field: 'ins_date',
            headerName: 'Date',
            width: 150,
            valueFormatter: (value) =>
                (new Date(value as string).toLocaleString()),
        },
    ];

    constructor(props: Readonly<LocalProps>) {
        super(props);

        this.state = {
            PageSize: props.searchData.PageSize,
            PageNumber: props.searchData.PageNumber,
            AfterId: props.searchData.AfterId,
            status: props.searchData.status,
            start_date: props.searchData.start_date,
            end_date: props.searchData.end_date,
            time_zone_offset: props.searchData.time_zone_offset,
            sender_name: props.searchData.sender_name,
            user_id: props.searchData.user_id,
            shop_id: props.searchData.shop_id,
            selectionModel: props.searchData.selectionModel,
        };

        // This binding is necessary to make "this" work in the callback

        this.handleSearch = this.handleSearch.bind(this);
        this.handleNavigation = this.handleNavigation.bind(this);
        this.handleChangeStatus = this.handleChangeStatus.bind(this);

        this.handleChangeSenderName = this.handleChangeSenderName.bind(this);
        this.handleStatusChanged = this.handleStatusChanged.bind(this);
        this.handleChangeStartDate = this.handleChangeStartDate.bind(this);
        this.handleChangeEndDate = this.handleChangeEndDate.bind(this);
        this.handleChangeUserId = this.handleChangeUserId.bind(this);
        this.handleChangePageSize = this.handleChangePageSize.bind(this);
        this.handleChangeShop = this.handleChangeShop.bind(this);
        this.handleSelectionChanged = this.handleSelectionChanged.bind(this);

    }


    //https://goshakkk.name/controlled-vs-uncontrolled-inputs-react/
    handleChangeSenderName(event: any) {
        this.setState({ sender_name: event.target.value });
    }
    handleStatusChanged(event: any) {
        this.setState({ status: Number(event.target.value) });
    }

    handleChangeStartDate(event: any) {
        this.setState({ start_date: event.target.value });
    }
    handleChangeEndDate(event: any) {
        this.setState({ end_date: event.target.value });
    }
    handleChangeUserId(event: any) {
        this.setState({ user_id: Number(event.target.value) });
    }
    async handleChangePageSize(event: any) {
        await this.setState({ PageSize: event.target.value, PageNumber: 0 });
        this.search();
    }
    handleChangeShop(event: any) {
        this.setState({ shop_id: Number(event.target.value) });
    }
    handleSelectionChanged(selectionModel: GridRowSelectionModel) {
        this.setState({ selectionModel: selectionModel });
    }

    private async handleSearch(event: any) {
        event.preventDefault();
        await this.setState({ PageNumber: 0 });
        if (this.handleValidation()) {
            await this.search();
        }
    }

    private async handleChangeStatus(status: number) {
        var settlements_ids = JSON.stringify(this.state.selectionModel);
        await this.props.changeStatus(settlements_ids, status);

    }

    private async handleNavigation(pageNumber: number) {

        await this.setState({ PageNumber: pageNumber });

        if (this.handleValidation()) {
            this.search();
        }
    }



    private handleValidation() {
        let result = true;

        /*
        let err = '';        
        var Destination = this.state.Destination;
        var StartDate = this.state.StartDate;
        var EndDate = this.state.EndDate;
        var Comment = this.state.Comment;

        if (Destination != "" && Destination.length > 0 && !Validator.isAlphaNumericAndSpace(Destination)) {
            err += "Destination should be alpha numeric!\n";
            result = false;
        }
        if (Destination != null && Destination.length > 50) {
            err += "Destination should not exceed 50 characters!\n";
            result = false;
        }

        if (Comment != null && Comment.length > 0 && !Validator.isText(Comment)) {
            err += "Illegal character in Comment field!\n";
            result = false;
        }
        if (Comment != null && Comment.length > 200) {
            err += "Comment should not exceed 200 characters!\n";
            result = false;
        }

        if (!Validator.isDateISO(StartDate)) {
            err += "Start Date is invalid!\n";
            result = false;
        }

        if (!Validator.isDateISO(EndDate)) {
            err += "End Date is invalid!\n";
            result = false;
        }

        if (!result) {
            this.props.setError(err);
        }
        */
        return result;
    }

    private async search() {
        this.props.requestData(this.state);
    }


    // This method is called when the component is first added to the document
    public async componentDidMount() {
        if (!this.props.isLoadedSearchBox)
            this.props.requestFilters();
    }

    // This method is called when the route parameters change
    public async componentDidUpdate() {
        if (this.props.triggerSearch) {
            this.props.resetTriggerTripsSearch();
            await this.search();
        }

        if (this.props.triggerRefresh) {            
            this.props.resetTriggerRefresh();
            await this.props.refreshData();
        }
    }

    public render() {
        return (
            <React.Fragment>
                {this.renderSearchBox()}
                {this.renderSearchResultTable()}
                {this.renderDocuments()}
            </React.Fragment>
        );
    }

    private renderSearchBox() {
        return (
            this.props.isLoadingSearchBox ?
                <p><em>Loading search parameters...</em></p> :
                <fieldset>
                    <legend>Search criteria</legend>
                    <Grid container spacing={5}>
                        <Grid item xs={3}>
                            <TextField
                                id="start_date"
                                name="start_date"
                                label="Start Date"
                                type="date"
                                variant="standard"
                                value={this.state.start_date}
                                onChange={this.handleChangeStartDate}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                name="sender_name"
                                label="Sender Name"
                                variant="standard"
                                value={this.state.sender_name}
                                onChange={this.handleChangeSenderName} />
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl sx={{ margin: 1, minWidth: "120px", maxWidth: "300px" }}>
                                <InputLabel id="label-status">Status</InputLabel>
                                <Select
                                    labelId="label-status"
                                    id="status"
                                    name="status"
                                    input={<Input />}
                                    MenuProps={utils.MenuProps}
                                    value={this.state.status}                                    
                                    onChange={this.handleStatusChanged}
                                >
                                    {this.props.settlementStatus.map((status, index) => (
                                        <MenuItem key={status.status} value={status.status} >
                                            {status.description}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container spacing={5}>
                        <Grid item xs={3}>
                            <TextField
                                id="end_date"
                                name="end_date"
                                label="End Date"
                                type="date"
                                variant="standard"
                                value={this.state.end_date}
                                onChange={this.handleChangeEndDate}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <InputLabel id="label-shop">Shop</InputLabel>
                            <Select
                                labelId="label-shop"
                                id="shop_id"
                                name="shop_id"
                                input={<Input />}
                                MenuProps={utils.MenuProps}
                                value={this.state.shop_id}                                
                                onChange={this.handleChangeShop}
                            >
                                {this.props.shopList.map(shop =>
                                    <MenuItem key={shop.shop_id} value={shop.shop_id}>{shop.name}</MenuItem>
                                )}
                            </Select>
                        </Grid>
                        <Grid item xs={3}>
                            <InputLabel id="label-user">User</InputLabel>
                            <Select
                                labelId="label-user"
                                id="user_id"
                                name="user_id"
                                input={<Input />}
                                MenuProps={utils.MenuProps}
                                value={this.state.user_id}
                                onChange={this.handleChangeUserId}
                            >
                                {this.props.userList.map(user =>
                                    <MenuItem key={user.user_id} value={user.user_id}>{user.name}</MenuItem>
                                )}
                            </Select>
                        </Grid>
                        <Grid item xs={3}>
                            <InputLabel id="label-page-size">Page size</InputLabel>
                            <Select
                                labelId="label-page-size"
                                id="PageSize"
                                name="PageSize"
                                input={<Input />}
                                MenuProps={utils.MenuProps}
                                value={this.state.PageSize} onChange={this.handleChangePageSize}
                            >
                                {this.props.pageSizeList.map(pageSize =>
                                    <MenuItem key={pageSize.Value} value={pageSize.Value}>{pageSize.Description}</MenuItem>
                                )}
                            </Select>
                        </Grid>
                    </Grid>
                    <Grid container spacing={5}>
                        <Grid item xs={3}>
                            <Button variant="contained" color="primary" onClick={this.handleSearch}>
                                Search
                            </Button>
                        </Grid>
                    </Grid>
                </fieldset>
        );
    }

    private renderSearchResultTable() {
        return (
            <div>
                <fieldset>
                    <legend>Search results</legend>
                    <Grid container spacing={5}>
                        <Grid item xs={12}>
                            <DataGrid autoHeight
                                rows={this.props.searchResult.Data}
                                columns={this.columns}
                                rowCount={this.props.searchResult.RowCount}
                                checkboxSelection
                                onRowSelectionModelChange={(newSelection) => {
                                    this.handleSelectionChanged(newSelection);
                                }}
                                pagination
                                pageSizeOptions={[this.state.PageSize]}
                                paginationMode="server"
                                paginationModel= {{ pageSize: this.state.PageSize, page: this.state.PageNumber }}
                                onPaginationModelChange={(e) => this.handleNavigation(e.page)}
                            />
                            <div className="form-group text-center">
                                <Button variant="outlined" disabled={Navigation.isNavigationDisabled(this.props.searchResult.First, this.props.searchResult.Current)} onClick={(id) => this.handleNavigation(this.props.searchResult.First)}>{Navigation.displayNavigationText('First', this.props.searchResult.First)}</Button>
                                <Button variant="outlined" disabled={Navigation.isNavigationDisabled(this.props.searchResult.Prev, this.props.searchResult.Current)} onClick={(id) => this.handleNavigation(this.props.searchResult.Prev)}>{Navigation.displayNavigationText('Previous', this.props.searchResult.Prev)}</Button>
                                <Button variant="outlined" disabled={true}>{Navigation.displayNavigationText('Current', this.props.searchResult.Current)}</Button>
                                <Button variant="outlined" disabled={Navigation.isNavigationDisabled(this.props.searchResult.Next, this.props.searchResult.Current)} onClick={(id) => this.handleNavigation(this.props.searchResult.Next)}>{Navigation.displayNavigationText('Next', this.props.searchResult.Next)}</Button>
                                <Button variant="outlined" disabled={Navigation.isNavigationDisabled(this.props.searchResult.Last, this.props.searchResult.Current)} onClick={(id) => this.handleNavigation(this.props.searchResult.Last)}>{Navigation.displayNavigationText('Last', this.props.searchResult.Last)}</Button>
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <Button variant="contained" color="primary" onClick={(e) => this.handleChangeStatus(1)}>
                                Change selection to 'at courier'
                            </Button>
                        </Grid>
                        <Grid item xs={4}>
                            <Button variant="contained" color="primary" onClick={(e) => this.handleChangeStatus(2)}>
                                Change selection to 'at delivery company'
                            </Button>
                        </Grid>
                        <Grid item xs={4}>
                            <Button variant="contained" color="secondary" onClick={(e) => this.handleChangeStatus(3)}>
                                Change selection to 'paid to shop'
                            </Button>
                        </Grid>
                    </Grid>

                </fieldset>
            </div>
        );
    }

    private renderDocuments() {
        return (
            <div>
                <fieldset>
                    <legend>Documents</legend>
                    <Grid container spacing={5}>
                        <Grid item xs={12}>
                            <Documents />
                        </Grid>
                    </Grid>
                </fieldset>
            </div>
        );
    }
}


export default connect(
    (state: ApplicationState) => state.settlements, // Selects which state properties are merged into the component's props
    SettlementsStore.actionCreators // Selects which action creators are merged into the component's props
)(Settlements as any);
