export const lng_default = 26.090817904089153;
export const lat_default = 44.425960495078975;
export const zoom_default = 16;

export interface AuthState {
    isAuthenticated: boolean;
    full_name: string;
    work_place: string;
    profile_picture_base64: string;
    roles: string[];
    force_change_password: boolean;
}

export class ListValue {
    Value: number = 0;
    Description: string = "";
}

export class SearchPaginationResult {
    RowCount: number = 0;
    First: number = 0;
    Prev: number = 0;
    Current: number = 0;
    Next: number = 0;
    Last: number = 0;
    AfterIdNext: number = 0;
}


export class SearchPaginationInput {
    PageNumber: number = 0;
    PageSize: number = 0;
    AfterId: number = 0;
}

export class ReportType {
    type: number = 0;
    name: string = "";
}

export class Report {
    report_id: number = 0;
    type: string = "";
    description: string = "";
    ins_date: string = "";
    ins_user: string = "";
    age: string = "";
    has_pdf: boolean = false;
    has_xlsx: boolean = false;
}

export class ReportSave {
    report_type: number = 0;
    start_date: string = '';
    end_date: string = '';
    company_id: number = 0;
    description: string = '';
}


export class Company {
    company_id: number = 0;
    name: string = "";
}

export type LatLng = {
    lat: number;
    lng: number;
};

export class CompanyDeliveryZone {
    zone_id: number = 0;
    company_id: number = 0;
    name: string = '';
    zone: LatLng[] = [];
}

export class CompanyDeliveryZoneUser {
    company_id: number = 0;
    zone_id: number = 0;
    user_id: number = 0;    
    full_name: string = '';
}


export class Shop {
    shop_id: number = 0;
    name: string = "";
}

export class User {
    user_id: number = 0;
    name: string = "";
    user_name: string = "";
}


export class Depot {
    depot_id: number = 0;
    name: string = "";
}

export class StatusJournal {
    status: number = 0;
    status_desc: string = "";
    date_stamp: string = "";
    user_name: string = "";
}


export class AllocationDisplayDetails{
    short_desc?: string = "";
    long_desc?: string = "";
    sender_name?: string = "";
    receiver_name?: string = "";
    sender_contact_phone?: string = "";
    receiver_contact_phone?: string = "";
    ramburs?: string = "";
    remarks?: string = "";
}
export class Allocation {
    allocation_id: number = 0;
    allocation_method: string = "";
    transport_id: number = 0;
    group_id: number = 0;
    type: number = 0;
    status: number = 0;
    status_description: string = "";
    action_description: string = "";
    location_description: string = "";
    user_name: string = "";
    user_id: number = 0;
    vehicle_name: string = "";
    vehicle_speed: number = 0;
    allocation_date: string = "";
    accepted_date: string = "";
    rejected_date: string = "";    
    details: string = "";
    display_details: AllocationDisplayDetails = {};
    details_first_line: string = "";
    details_second_line: string = "";
    details_third_line: string = "";
    
    
    navigation_order: number = 0;
    initial_navigation_order: number = 0;
    latitude: number = 0;
    longitude: number = 0;
    estimated_time_arrival: string = "";
    precedence_estimated_time_arrival: string = "";
    distance_total: string = "";
    duration_total: string = "";
    in_time_status: number = 0;
    in_time_status_description: string = "";
}

export class Awb {
    awb: string = "";
    tracking_number: string = "";
    awb_date: string = "";
    tracking_realtime: boolean = false;
    sender_name: string = "";
    sender_contact_phone: string = "";
    sender_contact_person: string = "";
    sender_address: string = "";
    receiver_name: string = "";
    receiver_contact_phone: string = "";
    receiver_contact_person: string = "";
    receiver_address: string = "";
    ramburs: string = "";
    remarks: string = "";
    status_journal: StatusJournal[] = [];
    receiver_latitude: number = 0;
    receiver_longitude: number = 0;
    delivery_estimated_date: string = "";
    navigation_order: number = 0;
    shipper_latitude: number = 0;
    shipper_longitude: number = 0;
}

export class AwbTrackingRealTime {
    tracking_number: string = "";
    status_journal: StatusJournal[] = [];
    navigation_order: number = 0;
    shipper_latitude: number = 0;
    shipper_longitude: number = 0;
    delivery_estimated_date: string = "";    
}


export class TransportRequestStatus {
    status: string = "-1";
    description: string = "";
    badge_variant: string = "";
    tracking_real_time: number = 0;
}

export class TransportRequestSettings {
    status: number = 0;
    status_journal: StatusJournal[] = [];
    allocations: Allocation[] = [];
}


export class TransportRequestSearchResult {
    transport_id: number = 0;
    tracking_number: string = "";
    awb: string = "";
    status: string = "";
    ins_date: string = "";
    company: string = "";
    sender_name: string = "";
    payment_type: string = "";
}

export class TransportRequestsSearchResult extends SearchPaginationResult {
    Data: TransportRequestSearchResult[] = [];
}

export interface ReportsSearchResult extends SearchPaginationResult {
    Data: Report[];
}

export function getSearchPaginationResultDefaultValues(): SearchPaginationResult {
    let output: SearchPaginationResult = {
        RowCount: 0,
        First: -1,
        Prev: -1,
        Current: 0,
        Next: -1,
        Last: -1,
        AfterIdNext: -1,
    };
    
    return output;
}

export function getSearchPaginationInputDefaultValues(): SearchPaginationInput {
    let output: SearchPaginationInput = {
        PageSize: 20,
        PageNumber: 0,
        AfterId: 0,
    };
    return output;
}