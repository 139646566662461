import { userConstants } from '../_constants';
import { userService } from '../_services';
import { alertActions } from './';
import { navigateToUrl } from '../../components/Navigation';

export const userActions = {
    login,
    logout,
    register,
    update,
    changePassword,
};

function login(username, password) {
    return dispatch => {
        dispatch(request({ username }));

        userService.login(username, password)
            .then(
                user => { 
                    dispatch(success(user));
                    navigateToUrl('/');
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function logout() {
    userService.clearLocalStorage();
    return { type: userConstants.LOGOUT };
}

function register(user) {
    return dispatch => {
        dispatch(request(user));

        userService.register(user)
            .then(
                user => { 
                    dispatch(success());
                    navigateToUrl('/login');
                    dispatch(alertActions.success(user.info || "Registration completed"));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(user) { return { type: userConstants.REGISTER_REQUEST, user } }
    function success(user) { return { type: userConstants.REGISTER_SUCCESS, user } }
    function failure(error) { return { type: userConstants.REGISTER_FAILURE, error } }
}

function update(user) {
    return dispatch => {
        dispatch(request(user));

        userService.update(user)
            .then(
                user => {
                    dispatch(success());
                    dispatch(alertActions.success(user.info || "Update completed"));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(user) { return { type: userConstants.UPDATE_REQUEST, user } }
    function success(user) { return { type: userConstants.UPDATE_SUCCESS, user } }
    function failure(error) { return { type: userConstants.UPDATE_FAILURE, error } }
}

function changePassword(user) {
    return dispatch => {
        dispatch(request(user));

        userService.changePassword(user)
            .then(
                user => {
                    dispatch(success());
                    dispatch(alertActions.success(user.info || "Update completed"));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(user) { return { type: userConstants.CHANGE_PASSWORD_REQUEST, user } }
    function success(user) { return { type: userConstants.CHANGE_PASSWORD_SUCCESS, user } }
    function failure(error) { return { type: userConstants.CHANGE_PASSWORD_FAILURE, error } }
}