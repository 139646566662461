import * as utils from './Utils';
import * as Models from "../models/Models";
import { Shipper, ShipperLocation, TransportRequest, UserAllocationSummary, AllocationEstimation, Notification102, SignalrNotification, AllocationUpdateNavigationOrder, MyMap } from './Map'
import { desynchronizationDetected } from '../components/Map'

export var last_epoch: number = 0;
export var last_epochs: Map<number, number> = new Map<number, number>();
export var notifications_tank: SignalrNotification[] = [];

var last_notification_103: Map<number, SignalrNotification> = new Map<number, SignalrNotification>();
var last_epoch_103:Map<number, number> = new Map<number, number>();
export var last_proccessing: number = 0;

export const setLastEpoch = (epoch: number) => {
    last_epoch = epoch;
}

export const setNotificationsTank = (notifications_tank_new: SignalrNotification[]) => {
    notifications_tank = notifications_tank_new;
}

export const computeUsersGroup = (transport_requests: Array<TransportRequest>) : [MyMap<string, Models.Allocation[]>, MyMap<string, UserAllocationSummary>] => {
    
    var allocations: Array<Models.Allocation> = [];
    for (let i = 0; i < transport_requests.length; i++) {
        if (transport_requests[i].allocations && transport_requests[i].allocations.length > 0) {
            transport_requests[i].allocations.forEach(function (alloc: Models.Allocation) {
                allocations.push(alloc);
            });
        }
    }

    let group = utils.groupArrayOfObjects(allocations, "user_id") as MyMap<string, Array<Models.Allocation>>;
    Object.keys(group).forEach((key) => {
        group[key].sort((a: Models.Allocation, b: Models.Allocation) => (a.navigation_order > b.navigation_order) ? 1 : ((b.navigation_order > a.navigation_order) ? -1 : 0))
    });

    let user_allocation_summary: MyMap<string, UserAllocationSummary> = {};

    Object.keys(group).forEach((key) => {
        user_allocation_summary[key] = computeUsersAllocationSummary(group[key]);
    });
    

    return [group, user_allocation_summary]
}


export const computeUsersAllocationSummary = (allocations: Array<Models.Allocation>) => {
    let alloc_summary = new UserAllocationSummary();

    let accepted_alloc = allocations.filter((x: Models.Allocation) => x.status === 2);
    let new_alloc = allocations.filter((x: Models.Allocation) => x.status === 1);
    
    alloc_summary.number_of_accepted_allocations = accepted_alloc.length;
    alloc_summary.number_of_new_allocations = new_alloc.length;
    
    if (accepted_alloc.length > 0) {
        alloc_summary.distance_total = accepted_alloc[accepted_alloc.length - 1].distance_total;
        alloc_summary.duration_total = accepted_alloc[accepted_alloc.length - 1].duration_total;
    }
    else {
        alloc_summary.distance_total = "N/A";
        alloc_summary.duration_total = "N/A";
    }
    return alloc_summary;
}

export const proccessLocations = (notification: SignalrNotification) : [string, MyMap<number, ShipperLocation>] => {    
    
    const date = new Date(notification.Epoch).toLocaleTimeString();

    var json_shippers = JSON.parse(notification.Payload) as Array<ShipperLocation>;
    var shippersLocation: MyMap<number, ShipperLocation> = {};

    for (var i = 0; i < json_shippers.length; i++) {
        var shipperLocation = json_shippers[i];

        shippersLocation[shipperLocation.user_id] = shipperLocation;
    }
    return [date, shippersLocation]
    
}

export const proccessNotification_100 = (notification: SignalrNotification, mapShippersCurrent: MyMap<number, Shipper>) : [string, MyMap<number, Shipper>] => {
    let json_shippers = JSON.parse(notification.Payload);
    let shippers: MyMap<number, Shipper> = {};
    const date = new Date(notification.Epoch).toLocaleTimeString();
     

    for (let i = 0; i < json_shippers.length; i++) {
        let obj = json_shippers[i];
        let shipper = Object.assign(new Shipper(), obj);
        
        const shipperCurrent = mapShippersCurrent[shipper.user_id];
        if (shipperCurrent) {
            shipper.show_route = shipperCurrent?.show_route || false;
            shipper.visible = shipperCurrent?.visible || false;
        }
        else {
            shipper.show_route = false;
            shipper.visible = true;
        }
        shippers[Number(shipper.user_id)] = shipper;
    }
    return [date, shippers];
}

export const proccessNotification_101 = (trasportRequests: Array<TransportRequest>, notification: SignalrNotification) : [string, Array<TransportRequest>, MyMap<string, Array<Models.Allocation>>, MyMap<string, UserAllocationSummary>] => {
    const date = new Date(notification.Epoch).toLocaleTimeString();
    let estimations = JSON.parse(notification.Payload) as Array<AllocationEstimation>;
    
    var requests_to_sort: Set<number> = new Set();
    let desincronization = false;
    let missing_alloc_1 = '', missing_alloc_2 = '';

    for (let i = 0; i < estimations.length; i++) {
        let estimate = estimations[i] as AllocationEstimation;
        
        let estimate_found = false;

        //TO DO de imbunatatit aplicarea estimarilor noi in alocari - are 3 for-uri ...
        for (let j = 0; j < trasportRequests.length; j++){
            
            for (let k = 0; k < trasportRequests[j].allocations.length; k++){
                if (trasportRequests[j].allocations[k].allocation_id === estimate.allocation_id){
                    estimate_found = true;
                    if (estimate.navigation_order !== trasportRequests[j].allocations[k].navigation_order){
                        requests_to_sort.add(j);
                    }
                    trasportRequests[j].allocations[k] = {...trasportRequests[j].allocations[k], ...estimate}
                    break;
                }
            }
            if (estimate_found){
                break;
            }
        }        

        if (!estimate_found){
            desincronization = true;
            missing_alloc_1 += estimate.allocation_id + '; ';
        }
    }

    /* nu functioneaza pt ca vin estimari cu alocari care nu mai exista in lista cu transport_ids si sunt destul de multe - lasam fara deocamdata
    trasportRequests.forEach((t) => {
        t.allocations.forEach((a) => {
            let found = false;
            for (let i = 0; i < estimations.length; i++){
                if (a.allocation_id === estimations[i].allocation_id){
                    found = true;
                    break;
                }
            }
            if (!found){
                desincronization = true;
                missing_alloc_2 += a.allocation_id + '; ';
            }
        })
    })

    if (desincronization){
        var transports_json = JSON.stringify(trasportRequests)
        var description = `missing_alloc_1: ${missing_alloc_1} ; missing_alloc_2: ${missing_alloc_2}`;
        desynchronizationDetected(101, description, last_epoch, notification.Epoch, transports_json, notification.Payload || '');        
    }
    */

    requests_to_sort.forEach((element) => {
        //console.log('sort ' + element);
        trasportRequests[element].allocations = trasportRequests[element].allocations.sort((a, b) => a.navigation_order - b.navigation_order)
    })

    const [group, user_allocation_summary] = computeUsersGroup(trasportRequests)

    return [date, trasportRequests, group, user_allocation_summary]
}


export const proccessNotification_102 = (currentTransportRequests: Array<TransportRequest>, notification: SignalrNotification) : [string, Array<TransportRequest>, MyMap<string, Array<Models.Allocation>>, MyMap<string, UserAllocationSummary>] => {
    const date = new Date(notification.Epoch).toLocaleTimeString();
    let Payload_Json = JSON.parse(notification.Payload) as Notification102;
        
    var trasportRequests: Array<TransportRequest> = [];
    
    for (var i = 0; i < Payload_Json.requests.length; i++) {
        let request = Payload_Json.requests[i] as TransportRequest;

        const curr_req = currentTransportRequests.find(x => x.transport_id === request.transport_id);
        const last_epoch = last_epoch_103.get(Number(request.transport_id))        

        //se poate intampla ca o notificare 102 sa vina dupa o notificare 103
        //de exemplu cand se aloca cereri si imediat userul o accepta (la milisecunde)
        //intaii vine notificarea 103 (cu status acceptat - e si mai mica sa dimensiune)
        //iar ulterior vine notificarea 102 cu toate request-urile de la momentul alocarii (care are statusul neacceptat)
        //este necesar sa facem o verificare a epocii si aici a.i. sa nu punem date vechi in state
        if (last_epoch === undefined || Number(last_epoch) < notification.Epoch){
            request.visible = curr_req ? curr_req.visible : true;
            trasportRequests.push(request);
        }
        else{
            if (curr_req){
                if (curr_req.allocations_new !== request.allocations_new){
                    console.log('102 discarded new alloc:' , request.transport_id, 'curr alloc_new', curr_req.allocations_new, 'new alloc_new', request.allocations_new)
                }
                trasportRequests.push(curr_req);
            }
        }        
    }

    const [group, user_allocation_summary] = computeUsersGroup(trasportRequests)

    return [date, trasportRequests, group, user_allocation_summary]
}


export const proccessNotification_103_104 = (currentTransportRequests: Array<TransportRequest>, trasportRequests: Array<TransportRequest>, group: MyMap<string, Array<Models.Allocation>>, user_allocation_summary: MyMap<string, UserAllocationSummary>, notification: SignalrNotification, desynchronizationDetected: desynchronizationDetected) : [boolean, string, Array<TransportRequest>, MyMap<string, Array<Models.Allocation>>, MyMap<string, UserAllocationSummary>] => {
    
    const date = new Date(notification.Epoch).toLocaleTimeString();
    let notifications: SignalrNotification[] = [];
    var desynchronization : boolean = false;

    if (notification.Type === 103){
        notifications = [ notification ]
    }
    else if (notification.Type === 104){
        notifications = JSON.parse(notification.Payload) as SignalrNotification[];
    }

    //sortam in backend - nu mai e nevoie
    //notifications = notifications.sort((a, b) => a.Epoch - b.Epoch);    

    for (let k = 0; k < notifications.length; k++){

        let notif = JSON.parse(notifications[k].Payload) as Notification102;

        //pe alte transport id-uri permitem sa nu primim in ordine notificarile; insa pe acelasi transport id trebuie sa le avem in ordine ca sa se poata construi incremental corect transportul
        const last_epoch = last_epoch_103.get(notif.transport_id)

        if (last_epoch === undefined || Number(last_epoch) < notifications[k].Epoch){

            last_epoch_103.set(notif.transport_id, notifications[k].Epoch)
            last_notification_103.set(notif.transport_id, notifications[k]);
            last_epochs.set(103, notifications[k].Epoch)
            
            //cautam in alocarile userilor transport_request-ul din notificare si il stergem
            //daca va fi o notificare  de update/insert o sa adaugam alocarile noi mai jos
            Object.keys(group).forEach((key) => {                                    
                group[key] = group[key].filter((x: Models.Allocation)=> x.transport_id !== notif.transport_id)
                user_allocation_summary[key] = computeUsersAllocationSummary(group[key])                                    
            })

            if (notif.action === 'D'){
                //alocarile nu le tratam pt ca le stergem mai sus in toate cazurile
                trasportRequests = trasportRequests.filter(x => Number(x.transport_id) !== notif.transport_id)
            }
            else if (notif.action === 'I' || notif.action === 'U'){
                for (let i = 0; i < notif.requests.length; i++){
                    let request = notif.requests[i];
                    let index = 0;
                    let found = false;
                    for (let j = 0; j < trasportRequests.length; j++){
                        if (trasportRequests[j].transport_id === notif.requests[i].transport_id){
                            found = true;
                            break;
                        }
                        if (trasportRequests[j].transport_id > notif.requests[i].transport_id){
                            break;
                        }
                        index++;
                    }
                    
                    //punem flag-ul 'visible' asa cum a fost setat de user
                    const curr_req = currentTransportRequests.find(x => x.transport_id === request.transport_id);                                        
                    request.visible = curr_req ? curr_req.visible : true;
                    
                    console.log('2. 103_104', request.transport_id, request.allocations_new);
                    //stergem un element daca deja exista request-ul (ca sa il inlocuim)
                    trasportRequests.splice(index, found ? 1: 0, request)

                    console.log('3. trasportRequests', trasportRequests[index].transport_id, trasportRequests[index].allocations_new)

                    for(let i = 0; i < request.allocations.length; i++) {
                        let x = request.allocations[i];
                        let index = 0;
                        
                        if (group[x.user_id]){                                                
                            for(let i = 0; i < group[x.user_id].length; i++){                                                
                                if (group[x.user_id][i].navigation_order > x.navigation_order){
                                    break;
                                }
                                index++;
                            }
                            group[x.user_id].splice(index, 0, x);
                        }
                        else {                                                
                            group[x.user_id] = [x]
                        }                    
                        user_allocation_summary[x.user_id] = computeUsersAllocationSummary(group[x.user_id])
                    }
                }
            }
        }
        else if (last_epoch && Number(last_epoch) > notifications[k].Epoch){

            const last_notif = last_notification_103.get(notif.transport_id);
            if (last_notif){
                const last_notif_obj = JSON.parse(last_notif.Payload) as Notification102;
                
                //totusi nu e chair OK pt ca Epoch este data cand a fost trimisa notificarea (nu data cand s-a generat notificarea); exista sansa ca o notificare veche sa vina cu Epoch mai nou decat al notificarii noi
                //insa aceeasi problema e si invers: o notificare noua sa aiba Epoch mai mic decat o notificare veche
                //ar trebui schimbat in mecanisumul de generare de notificari
                //pana atunci lasa asa:
                //  - daca avem doua notificari de update si cea curenta e mai noua decat cea care trebuie parsata atunci sarim peste (avem date noi deja)
                //  - daca avem ultima notificare de delete si vine alta este OK (se intampla ca notificarea de tip I sau U sa vina cu intarziere)
                //  - pe restul cazurilor (cand intaii vine update si apoi insert marcam totusi desincronizare - cu toate ca ar merge sa ignoram insertul - parsarea trateaza caul in care este update si nu e gasit in lista ca insert)
                //  - practic am putea renunta de tot la desincronizare - si sa sarim peste notificare in caz ca e mai veche decat cea curenta
                if (last_notif_obj.action === 'D' || 
                    (last_notif_obj.action === 'U' && (notif.action === 'U' || notif.action === 'I'))){
                    console.log('4. last epoch failed continue', notif.transport_id, Number(last_epoch), notifications[k].Epoch, Number(last_epoch) - notifications[k].Epoch, notif.requests)
                    continue;
                }
                else{
                    //console.log(`desynchronization detected on notification ${notifications[k].Type} ${notification.Type} last epoch ${last_epoch} current epoch ${notifications[k].Epoch}`);
                    //console.log('last notification: ', last_notif)
                    //console.log('current notification: ', notifications[k])
                    const last_notif_json = JSON.stringify(last_notif);
                    const current_notif_json = JSON.stringify(notifications[k]);
                    desynchronizationDetected(notification.Type, '', last_epoch || 0, notifications[k].Epoch, last_notif_json || '', current_notif_json || '');
                    desynchronization = true;
                    console.log('5. last epoch failed desynchronizationDetected', notif.transport_id, Number(last_epoch), notifications[k].Epoch, Number(last_epoch) - notifications[k].Epoch, notif.requests)
                    break;
                }
            }
        }

        
        
    }

    return [ desynchronization, date, trasportRequests, group, user_allocation_summary ]


}

export const proccessNotification_105 = (trasportRequests: Array<TransportRequest>, group: MyMap<string, Array<Models.Allocation>>, notification: SignalrNotification) : [string, Array<TransportRequest>, MyMap<string, Array<Models.Allocation>>] => {
    const date = new Date(notification.Epoch).toLocaleTimeString();
    const allocations_to_update = JSON.parse(notification.Payload) as AllocationUpdateNavigationOrder[];   
    

    var requests_to_sort: Set<number> = new Set();
    var users_to_sort: Set<number> = new Set();

    for (let i = 0; i < allocations_to_update.length; i++){
        const alloc = allocations_to_update[i];
        const t_idx = trasportRequests.findIndex(x => Number(x.transport_id) === alloc.transport_id);
        if (t_idx >= 0){
            const t = trasportRequests[t_idx];                                    
            const a = t.allocations.find(x => x.allocation_id === alloc.allocation_id);
            if (a){
                if (a.navigation_order !== alloc.navigation_order){
                    a.navigation_order = alloc.navigation_order;
                    requests_to_sort.add(t_idx)
                }
            }
        }

        const u = group[alloc.user_id] as Array<Models.Allocation>;
        u.forEach((a) => {
            if (a.allocation_id === alloc.allocation_id){
                if (a.navigation_order !== alloc.navigation_order){
                    a.navigation_order = alloc.navigation_order;
                    users_to_sort.add(alloc.user_id)
                }
            }
        })
    }

    requests_to_sort.forEach((element) => {
        trasportRequests[element].allocations.sort((a, b) => a.navigation_order - b.navigation_order)
    })

    users_to_sort.forEach((element) => {
        const u = group[element] as Array<Models.Allocation>;
        u.sort((a, b) => a.navigation_order - b.navigation_order)
    })

    /*
    console.log('group for 1043')
    group[1043].forEach((x:Allocation) => {console.log(x.transport_id, x.allocation_id, x.navigation_order)})
    console.log('group for "1043"')
    group['1043'].forEach((x:Allocation) => {console.log(x.transport_id, x.allocation_id, x.navigation_order)})
    */

    return [date, trasportRequests, group]
}

export const cloneTransportRequests = (current_transport_requests: Array<TransportRequest>,
                                        current_allocations_group_by_user_id: MyMap<string, Array<Models.Allocation>>) : [Array<TransportRequest>, MyMap<string, Array<Models.Allocation>>, MyMap<string, UserAllocationSummary>] => {
    
    const trasportRequests = current_transport_requests.map(x=> {
        return {
            ...x,
            details: {...x.details},
            allocations: x.allocations.map(a => {return {...a} })
        }
    });

    var group: MyMap<string, Array<Models.Allocation>> = {};
    var user_allocation_summary: MyMap<string, UserAllocationSummary> = {};

    //pt ca e Map de array o clonam manual; nu punem alocarile care tin de transport_id pt ca se poate sa nu mai existe (daca s-a finalizat request-ul atunci nu mai exita); daca totusi exista alocari atunci chiar daca le stergem le adaugam mai jos - nu merge altfel    

    //cautam in alocarile userilor transport_request-ul din notificare si il stergem
    //daca va fi o notificare  de update/insert o sa adaugam alocarile noi mai jos
    Object.keys(current_allocations_group_by_user_id).forEach((key) => {
        group[key] = [...current_allocations_group_by_user_id[key]] as Models.Allocation[];
        user_allocation_summary[key] = computeUsersAllocationSummary(group[key])
    })

    return [trasportRequests, group, user_allocation_summary];
}


export const proccessNotification = (event: string, desynchronizationDetected: desynchronizationDetected, shippersCurrent: MyMap<number, Shipper>, currentTransportRequests: Array<TransportRequest>, current_allocations_group_by_user_id: MyMap<string, Array<Models.Allocation>>) : [ boolean, MyMap<number, Shipper> | null, string | null, boolean, MyMap<number, ShipperLocation> | null, string | null, boolean, Array<TransportRequest> | null, MyMap<string, Array<Models.Allocation>> | null, MyMap<string, UserAllocationSummary> | null, string | null, boolean ] => {
    
    
    var shippers_updated: boolean = false;    
    var shippers: MyMap<number, Shipper> | null = null;
    var last_date_shippers_date: string | null = null;
    var locations : MyMap<number, ShipperLocation> | null = null;    
    var last_date_location_date: string | null = null;
    var locations_updated: boolean = false;
    var trasportRequests: Array<TransportRequest> | null = null;
    var last_date_transport_date: string | null = null;
    var transport_requests_updated: boolean = false;
    var group: MyMap<string, Array<Models.Allocation>> | null = null;
    var user_allocation_summary: MyMap<string, UserAllocationSummary> | null = null;
    var desynchronization: boolean;
    var notif_exists: boolean = false;
    
    const epoch_on_client = Date.now();

    last_proccessing = epoch_on_client;

    let log_console = false;

    while(notifications_tank.length) {

        notif_exists = true;
        const notification = notifications_tank.shift(); //get first element from array and remove it from the array
        if (notification === undefined) break;

        const Type = notification.Type as number;       
        
        if (Type !== 0 && Type !== 101){
            console.log('1. processing ', event, Type, notification)
            log_console = true;
        }
        
        var startTime = performance.now()
        

        if ((Type === 101 || Type === 102 || Type === 103 || Type === 104 || Type === 105) && (trasportRequests === null)){            
            [trasportRequests, group, user_allocation_summary] = cloneTransportRequests(currentTransportRequests, current_allocations_group_by_user_id);
        }
        
        if (Type === 0) {//locatiile            
            [last_date_location_date, locations] = proccessLocations(notification);
            locations_updated = true;
        }
        if (Type === 100) {//lista de shippers
            [last_date_shippers_date, shippers] = proccessNotification_100(notification, shippersCurrent);
            shippers_updated = true;
        }
        else if (Type === 101) {//estimarile        
            [ last_date_transport_date, trasportRequests, group, user_allocation_summary ] = proccessNotification_101(trasportRequests!, notification);        
            transport_requests_updated = true;
        }
        else if (Type === 102) { /* notification with all transport requests */
            [ last_date_transport_date, trasportRequests, group, user_allocation_summary ] = proccessNotification_102(trasportRequests!, notification);
            transport_requests_updated = true;
        }
        else if (Type === 103 || Type === 104) { /* incremental notification with one transport request */
            [ desynchronization, last_date_transport_date, trasportRequests, group, user_allocation_summary ] = proccessNotification_103_104(currentTransportRequests, trasportRequests!, group!, user_allocation_summary!, notification, desynchronizationDetected);
            if (desynchronization){
                //nu mai tratam alte notificari incrementale pt ca le cerem pe toate prin refresh
                notifications_tank = notifications_tank.filter(x => x.Type !== 103 && x.Type !== 104);
            }
            transport_requests_updated = true;
        }
        //mai trebuie lucrata - nu actualizeaza mereu ruta cu ce vede userul (deocamdata nu e folosita in sp_routing_solutions_merge nu fac task de tip 22 ci de tip 8 unde aduce toate requesturile)
        else if (Type === 105) { /* update navigation order */
            [ last_date_transport_date, trasportRequests, group ] = proccessNotification_105(trasportRequests!, group!, notification);
            transport_requests_updated = true;
        }        

        var endTime = performance.now()

        //console.log(`Processing ${Type} took ${endTime - startTime} milliseconds`)
    }

    if (notif_exists){

        if (log_console){
            if (trasportRequests && trasportRequests?.length > 1){
                console.log('6. trasportRequests[l-2]', trasportRequests[trasportRequests?.length - 2].transport_id, trasportRequests[trasportRequests?.length - 2].allocations_new)
            }
            if (trasportRequests && trasportRequests?.length > 0){
                console.log('7. trasportRequests[l-1]', trasportRequests[trasportRequests?.length - 1].transport_id, trasportRequests[trasportRequests?.length - 1].allocations_new)
            }
        }
   
    }

    return [ notif_exists, shippers, last_date_shippers_date, shippers_updated, locations, last_date_location_date, locations_updated, trasportRequests, group, user_allocation_summary, last_date_transport_date, transport_requests_updated ]
}