import AppBar from '@mui/material/AppBar';
import { Outlet } from 'react-router-dom';

// MUI
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

// Icons


// Components
import Footer from '../footer';
import MainHeader from '../mainHeader';
import { Breakpoint } from '@mui/material';



function AwbLayout({ container = 'lg', pb = true } : {container?: Breakpoint, pb?: boolean}) {	

	return (
		<Box display="flex" minHeight="100vh" flexDirection="column">
			<Header />
			<Container
				maxWidth={container}
				component="main"
				sx={{
					flex: '1 0 auto',
					...(pb && {
						pb: 5,
					}),
				}}
			>
				<Outlet />
			</Container>
			<Footer />
		</Box>
	);
}

function Header() {	

	return (
		<AppBar position={'sticky'}>
			<MainHeader/>			
		</AppBar>
	);
}

export default AwbLayout;
