import authService from '../components/api-authorization/AuthorizeService';
import { ApplicationPaths } from '../components/api-authorization/ApiAuthorizationConstants';
import { AppThunkAction } from './';
import { store } from './configureStore'
import axios, { AxiosResponse, } from 'axios'
import queryString from "query-string";
import { navigateToUrl } from '../components/Navigation'
import { setError, setInfo } from './ApiInterface2'
// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface ApiInterfaceState {
    isErrorOpen: boolean;
    isInfoOpen: boolean;
    error?: string;
    info?: string;
}


// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

export interface SetErrorAction {
    type: 'SET_ERROR';
    error: string;
}
export interface SetInfoAction {
    type: 'SET_INFO';
    info: string;
}


// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    setError: (error: string): AppThunkAction<any> => (dispatch, getState) => {
        dispatch(setError(error));
    },
    setInfo: (info: string): AppThunkAction<any> => (dispatch, getState) => {        
        dispatch(setInfo(info));
    },    

};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.


export async function checkTokenValidTryRefreshIfNot() : Promise<boolean>{
    var token_valid: boolean = false;

    if (authService.userManager) {
        const user = await authService.userManager.getUser();

        if (user != null) {
            if (!user.expired) {
                token_valid = true;
            }
            else {
                let retryStep = 0;
                while (retryStep < 5) {
                    retryStep += 1;
                    try {

                        await authService.ensureUserManagerInitialized();
                        const user = await authService.userManager.signinSilent();
                        authService.updateState(user);                        
                        token_valid = true;
                        break;
                    } catch (err) {
                        console.log(err);
                    }
                }
            }
        }
    }


    // nu mai facem redirect la logout in checkTokenValidTryRefreshIfNot
    // pt ca se poate intampla ca aplicatia sa fie offline si sa nu poata obtine token
    // lasam redirect la logout doar in functia parseAxiosError - daca returneaza 401
    // pt asta nu mai validam daca raspunsul checkTokenValidTryRefreshIfNot este true / false - lasam sa apeleze API-ul chiar daca nu are token valid si daca returneaza 401 API-ul atunci facem redirect la logout
    /*
    if (!token_valid){
        navigateToUrl(ApplicationPaths.LogOut);
    }
    */


    return token_valid;

}

async function parseError(err : any): Promise<string> {
    var errMsg = ''
    if (err.response && err.response.status === 401) {
        errMsg = 'You are not authorized';
        store.dispatch(setError(errMsg));
        navigateToUrl(ApplicationPaths.LogOut);
        return errMsg;
    }
    else if (err.response && err.response.status === 403) {
        errMsg = 'Access forbidden';
        store.dispatch(setError(errMsg));
        return errMsg;
    }
    else {

        const apiResponse = await err.response.data;
        
        if (apiResponse.error) //cazul in care eroarea este returnata prin ApiResponseUtil.BadRequestResponse
        {
            store.dispatch(setError(apiResponse.error));
            return apiResponse.error;
        }
        else if (apiResponse instanceof Blob && apiResponse.type === 'text/plain') //cazul apelurilor cu responseType: 'blob' si in care eroarea este retunata prin BadRequest(<error>); sunt apelurile in care se downlodeaza binar fisiere pdf sau xlsx
        {
            const errMsg = await apiResponse.text();            
            store.dispatch(setError(errMsg));
            return errMsg;
        }
        else if (apiResponse != null && typeof apiResponse == 'string') //cazul in care eroarea este retunata prin BadRequest(<error>)
        {
            store.dispatch(setError(apiResponse));
            return apiResponse;
        }
        else {
            errMsg = 'Error occured processing API request';
            store.dispatch(setError(errMsg));
            return 'Error occured processing API request';
        }
    }
}

async function parseResponse(response: AxiosResponse): Promise<any> {
    if (response.status === 200 /*OK*/ || response.status === 201 /*created*/) {
        const apiResponse = await response.data;

        if (apiResponse.error) {
            store.dispatch(setError(apiResponse.error));
            throw apiResponse.error;
        }

        if (apiResponse.info) {
            store.dispatch(setInfo(apiResponse.info));
        }

        if (response.headers['location']) {
            navigateToUrl(response.headers['location']);
        }

        return JSON.parse(apiResponse.payload);
    }
    else {
        throw new Error("Unknow status code");
    }
}

export const http_axios_get = (request: RequestInfo, params?: any) => new Promise<any>(async (resolve, reject) => {
    var token = null;
    var response: AxiosResponse;
    var qs = '';

    if (!areWeTestingWithJest()) {
        await checkTokenValidTryRefreshIfNot();
        token = await authService.getAccessToken();
    }

    if (params != null) {
        qs = '?' + queryString.stringify(params);
    }

    try {
        response = await axios.get(request.toString() + qs,
            {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
            });
        resolve(parseResponse(response));
    }
    catch (err) {
        reject(parseError(err));
    }
});


export const http_axios_post = (request: RequestInfo, data?: any, content_type: string = 'application/json') => new Promise<any>(async (resolve, reject) => {
    var token = null;
    var response: AxiosResponse;

    if (!areWeTestingWithJest()) {
        await checkTokenValidTryRefreshIfNot();
        token = await authService.getAccessToken();
    }
    try {
        response = await axios.post(request.toString(), data,
            {
                headers: {
                    'Content-Type': content_type,
                    'Authorization': `Bearer ${token}`
                }

            });
        resolve(parseResponse(response));
    }
    catch (err) {
        reject(parseError(err));
    }
});

export const http_axios_put = (request: RequestInfo, data?: any, content_type: string = 'application/json') => new Promise<any>(async (resolve, reject) => {
    var token = null;
    var response: AxiosResponse;

    if (!areWeTestingWithJest()) {
        await checkTokenValidTryRefreshIfNot();
        token = await authService.getAccessToken();
    }
    try {

        response = await axios.put(request.toString(), data,
            {
                headers: {
                    'Content-Type': content_type,
                    'Authorization': `Bearer ${token}`
                }

            });
        resolve(parseResponse(response));
    }
    catch (err) {
        reject(parseError(err));
    }
});


//export async function http_axios_delete(request: RequestInfo): Promise<any> {
export const http_axios_delete = (request: RequestInfo) => new Promise<any>(async (resolve, reject) => {
    var token = null;
    var response: AxiosResponse;

    if (!areWeTestingWithJest()) {
        await checkTokenValidTryRefreshIfNot();
        token = await authService.getAccessToken();
    }
    try {
        response = await axios.delete(request.toString(),
            {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            });
        resolve(parseResponse(response));
    }
    catch (err) {
        reject(parseError(err));
    }
});



export const http_axios_download = (request: RequestInfo, type: string, file_name: string, params?: any) => new Promise<any>(async (resolve, reject) => {
    var token = null;
    var response: AxiosResponse;
    var qs = '';

    if (!areWeTestingWithJest()) {
        await checkTokenValidTryRefreshIfNot();
        token = await authService.getAccessToken();
    }

    if (params != null) {
        qs = '?' + queryString.stringify(params);
    }

    try {        
        response = await axios.get(request.toString() + qs,
        {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
            responseType: 'blob', // Important
        });        

        var content_type = response.headers["content-type"];
        
        if (type === "xlsx") {
            const url = window.URL.createObjectURL(new Blob([response.data], { type: content_type }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('target', '_blank');
            link.setAttribute('rel', 'noopener noreferrer');
            link.setAttribute('download', file_name); //or any other extension
            document.body.appendChild(link);
            link.click();
        }
        else if (type === "pdf") {

            //Create a Blob from the PDF Stream
            const file = new Blob([response.data], { type: content_type });
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);

            //Open the URL on new Window
            const pdfWindow = window.open();
            
            if (pdfWindow != null) {
                pdfWindow.document.title = file_name;
                pdfWindow.location.href = fileURL;
            }
            
        }
    }
    catch (err: any) {
        reject(parseError(err));
    }
});

export const http_axios_get_as_object_url = (request: RequestInfo) => new Promise<any>(async (resolve, reject) => {
    var token = null;
    var response: AxiosResponse;
    var queryString = '';

    if (!areWeTestingWithJest()) {
        await checkTokenValidTryRefreshIfNot();
        token = await authService.getAccessToken();
    }

    try {        
        response = await axios.get(request.toString() + queryString,
        {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
            responseType: 'blob', // Important
        });        

        const file = new Blob([response.data], { type: response.headers["content-type"] });
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        resolve(fileURL);

    }
    catch (err: any) {
        reject(parseError(err));
    }
});




function areWeTestingWithJest() {
    //return process.env.JEST_WORKER_ID !== undefined;
    return false;
}

