import * as React from 'react';
import * as MapStore from '../store/Map';
import {
    Badge as MuiBadge,
    Checkbox,
    Box,
    Divider,
    Grid,    
    Button,
    IconButton,
    Slider,
    styled,
    Collapse,
    IconButtonProps,
    CardContent,    
} from '@mui/material';

import {
    Launch as LaunchIcon,
    AccessTime as AccessTimeIcon,
    ExpandMore as ExpandMoreIcon
} from '@mui/icons-material';
import { green, orange, red } from '@mui/material/colors';
import * as utils from '../store/Utils';
import { HtmlTooltip, Paper } from './StyledComponents'



const divStyleBadge: React.CSSProperties = {
    display: 'inline-block',
    padding: '0.25em 0.4em',
    fontSize: '75%',
    fontWeight: 'bolder',
    lineHeight: '1',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'baseline',
    borderRadius: '0.25rem'
    //transition: 'color 0.15s ease -in -out, background - color 0.15s ease -in -out, border - color 0.15s ease -in -out, box - shadow 0.15s ease -in -out',
};


interface TransportRequestItemProps {
    req: MapStore.TransportRequest;    
    handleShowStatusModal: (transport_id: string) => void;
    handleSetVisibleTranportRequest: (e: React.ChangeEvent<HTMLInputElement>, transport_id: string) => void;
    handleShowDetailsModal: (transport_id: string) => void;
    handleShowAllocationModal: (transport_id: string) => void;
    handlePriorityUpdate: (transport_id: number, priority: number) => void;

}

interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
}
  
  
const ExpandMore = styled((props: ExpandMoreProps) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }));

const marks = [
    {
      value: 1,
      label: 'Normal',
    },    
    {
        value: 5,
        label: 'High',
      },
  ];

function valuetext(value: number) {
    return `Priority: ${value}`;
}



const PrettoSlider = styled(Slider)({    
    color: '#52af77',
    height: 8,
    '& .MuiSlider-track': {
      border: 'none',
    },
    '& .MuiSlider-thumb': {
      height: 24,
      width: 24,
      backgroundColor: '#fff',
      border: '2px solid currentColor',
      '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
        boxShadow: 'inherit',
      },
      '&::before': {
        display: 'none',
      },
    },
    '& .MuiSlider-valueLabel': {
      lineHeight: 1.2,
      fontSize: 12,
      background: 'unset',
      padding: 0,
      width: 32,
      height: 32,
      borderRadius: '50% 50% 50% 0',
      backgroundColor: '#52af77',
      transformOrigin: 'bottom left',
      transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
      '&::before': { display: 'none' },
      '&.MuiSlider-valueLabelOpen': {
        transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
      },
      '& > *': {
        transform: 'rotate(45deg)',
      },
    },
  });

const TransportRequestItem = React.memo<TransportRequestItemProps>((props) => {
    const [expanded, setExpanded] = React.useState<boolean>(false);
    const [priority, setPriority] = React.useState<number>(props.req.priority);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const handleChangePriority = (event: Event, newValue: number | number[]) => {
        setPriority(newValue as number);
    };

    const handleChangePriorityCommited = (event: React.SyntheticEvent | Event, value: number | number[]) => {
        setPriority(value as number);
        props.handlePriorityUpdate(Number(props.req.transport_id), value as number)
    };

    return (
        <Paper elevation={24}>
            <Grid container spacing={0}>
                <Grid item xs={10}>
                    <IconButton color="primary"
                        aria-label="Change Status"
                        sx={{ margin: 0, padding: 0 }}
                        onClick={(e) => props.handleShowStatusModal(props.req.transport_id)}>
                        <LaunchIcon />
                    </IconButton>
                    <div style={Object.assign({}, divStyleBadge, { color: props.req.label_color, backgroundColor: props.req.label_background_color })}>
                        {props.req.status_desc}
                    </div>
                </Grid>
                <Grid item xs={2}>
                    <Checkbox
                        color="primary"
                        sx={{ margin: 0, padding: 0 }}
                        checked={props.req.visible}
                        onChange={(e) => props.handleSetVisibleTranportRequest(e, props.req.transport_id)}
                    />
                </Grid>

            </Grid>
            <Box m={1}>
                <Divider />
            </Box>

            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Box sx={{ px: 3 }}>
                        <PrettoSlider
                            aria-label="Custom marks"
                            value={priority}
                            onChange={handleChangePriority}
                            onChangeCommitted={handleChangePriorityCommited}                            
                            getAriaValueText={valuetext}
                            step={1}
                            valueLabelDisplay="auto"
                            min={1} 
                            max={5}
                            marks={marks}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <small>{props.req.time_since_creation}</small>
                </Grid>
                <Grid item xs={12}>
                    Id: {props.req.transport_id}
                </Grid>
                <Grid item xs={12}>
                    Date: {new Date(props.req.ins_date).toLocaleString()}
                </Grid>
                {
                    props.req.allocations && props.req.allocations.length > 0 &&
                    props.req.allocations?.filter(x => x.type === 1 || x.type === 4).map((w) => (
                        <Grid item xs={12} key={w.allocation_id}>
                            <HtmlTooltip
                                placement="right"
                                title={
                                    <React.Fragment>
                                        <Grid container spacing={1}>
                                            <Grid item>
                                                {w.in_time_status_description}
                                            </Grid>
                                        </Grid>
                                    </React.Fragment>
                                }
                            >
                                <span>
                                    {w.in_time_status === 0 &&
                                        <span>
                                            <AccessTimeIcon style={{ color: orange[500] }}></AccessTimeIcon>
                                            &nbsp;{w.action_description} time not available
                                        </span>
                                    }
                                    {w.in_time_status === 1 &&
                                        <span>
                                            <AccessTimeIcon style={{ color: green[500] }}></AccessTimeIcon>
                                            &nbsp;{w.action_description} in time
                                        </span>
                                    }
                                    {w.in_time_status === 2 &&
                                        <span>
                                            <AccessTimeIcon style={{ color: red[500] }}></AccessTimeIcon>
                                            &nbsp;{w.action_description} time exceeded
                                        </span>
                                    }
                                </span>
                            </HtmlTooltip>
                        </Grid>
                    ))}
                <Grid item xs={12}>
                    <HtmlTooltip
                        placement="right"
                        title={
                            <React.Fragment>
                                <Grid container spacing={1}>
                                    <Grid item>
                                        Sender name: {props.req.details.sender_name}
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1}>
                                    <Grid item>
                                        Sender contact person: {props.req.details.sender_contact_person}
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1}>
                                    <Grid item>
                                        Sender contact phone: {props.req.details.sender_contact_phone}
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1}>
                                    <Grid item>
                                        Receiver name: {props.req.details.receiver_name}
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1}>
                                    <Grid item>
                                        Receiver contact person: {props.req.details.receiver_contact_person}
                                    </Grid>
                                </Grid>
                                {props.req.pickup_estimated_date == null ?
                                    ""
                                    :
                                    <Grid container spacing={1}>
                                        <Grid item>
                                            Pickup estimated date: {new Date(props.req.pickup_estimated_date).toLocaleString()}
                                        </Grid>
                                    </Grid>
                                }
                                {props.req.delivery_estimated_date == null ?
                                    ""
                                    :
                                    <Grid container spacing={1}>
                                        <Grid item>
                                            Delivery estimated date: {new Date(props.req.delivery_estimated_date).toLocaleString()}
                                        </Grid>
                                    </Grid>
                                }
                            </React.Fragment>
                        }
                    >
                        <Button color="primary" onClick={(e) => props.handleShowDetailsModal(props.req.transport_id)}>Details</Button>
                    </HtmlTooltip>
                    <MuiBadge color="secondary" badgeContent={props.req.allocations_new} invisible={props.req.allocations_new === 0}>
                        <Button color="primary" onClick={(e) => props.handleShowAllocationModal(props.req.transport_id)}>Allocation</Button>
                    </MuiBadge>
                    <ExpandMore
                        expand={expanded}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                        >
                        <ExpandMoreIcon />
                    </ExpandMore>
                </Grid>
                <Grid item xs={12}>
                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                        <CardContent>
                            {props.req.summary}
                        </CardContent>
                    </Collapse>                    
                </Grid>
            </Grid>
        </Paper>
    );
},
    (prevProps, nextProps) => {

        var req_comp = utils.shallowCompare(prevProps.req, nextProps.req);
        var details_comp = utils.shallowCompare(prevProps.req.details, nextProps.req.details);
        var allocation_comp = true;
        if (prevProps.req.allocations.length !== nextProps.req.allocations.length) {
            allocation_comp = false;
        }
        else {
            for (var i = 0; i < prevProps.req.allocations.length; i++) {
                var alloc_item_comp = utils.shallowCompare(prevProps.req.allocations[i], nextProps.req.allocations[i]);
                if (!alloc_item_comp) {
                    allocation_comp = false;
                    break;
                }
            }
        }

        return (req_comp && allocation_comp && details_comp);

    }
);

export default TransportRequestItem;