import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import * as ApiInterface from './ApiInterface';
import { http_axios_get, http_axios_post, http_axios_delete } from './ApiInterface';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export class UploadState {
    isLoading: boolean = false;
    expeditions: CsvExpedition[] = [];
    triggerLoad: boolean = false;
}

export interface CsvExpedition {
    id: number;
    no: number;
    sender_name: number;
    sender_contact_person: number;
    receiver_name: number;
    receiver_contact_person: number;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.


export interface UploadFileRequestDataAction {
    type: 'UPLOAD_FILE_REQUEST';
}

export interface UploadFileReceivedDataAction {
    type: 'UPLOAD_FILE_RECEIVED';
}

export interface UploadLoadRequestDataAction {
    type: 'UPLOAD_LOAD_REQUEST';
}

export interface UploadLoadReceivedDataAction {
    type: 'UPLOAD_LOAD_RECEIVED';
    expeditions: Array<CsvExpedition>;
}

export interface UploadDeleteRequestDataAction {
    type: 'UPLOAD_DELETE_REQUEST';
}

export interface UploadDeleteReceivedDataAction {
    type: 'UPLOAD_DELETE_RECEIVED';
}

export interface UploadImportRequestDataAction {
    type: 'UPLOAD_IMPORT_REQUEST';
}

export interface UploadImportReceivedDataAction {
    type: 'UPLOAD_IMPORT_RECEIVED';
}

export interface UploadResetTriggerLoadAction {
    type: 'UPLOAD_RESET_TRIGGER_LOAD';
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = ApiInterface.SetErrorAction |
    ApiInterface.SetInfoAction |    
    UploadFileRequestDataAction |
    UploadFileReceivedDataAction |
    UploadLoadRequestDataAction |
    UploadLoadReceivedDataAction |
    UploadDeleteRequestDataAction |
    UploadDeleteReceivedDataAction |
    UploadImportRequestDataAction |
    UploadImportReceivedDataAction |
    UploadResetTriggerLoadAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    ...ApiInterface.actionCreators,
    load: (): AppThunkAction<KnownAction> => (dispatch, getState) => {

        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState) {
            Promise.all([                
                http_axios_get('api/Upload')
                    .then(response => {
                        return response;
                    }),                
            ]).then(([response]) => {
                
                const expeditions = response as CsvExpedition[];

                console.log(expeditions);

                dispatch({
                    type: 'UPLOAD_LOAD_RECEIVED',
                    expeditions: expeditions,
                });
            })
            .catch(function (e) {
                console.log(e);
            });

            dispatch({ type: 'UPLOAD_LOAD_REQUEST' });
        }

    },
    resetTriggerLoad: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState) {
            dispatch({ type: 'UPLOAD_RESET_TRIGGER_LOAD' });
        }
    },
    upload: (binary: string, file: File): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        var url = ''
        if (appState) {

            console.log(binary);

            url = 'api/Upload';

            var base64String = btoa(binary);

            var model = {
                contentType: file.type,
                contentAsBase64String: base64String,
                fileName: file.name
            };

            var model_json = JSON.stringify(model);
            
            http_axios_post(url, model_json)
                .then(response => {
                    dispatch({ type: 'UPLOAD_FILE_RECEIVED'});
                })
                .catch(function (e) {
                    console.log(e);
                });
            
            dispatch({ type: 'UPLOAD_FILE_REQUEST' });
        }
    },

    delete:(): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        var url = ''
        if (appState) {

            url = 'api/Upload';           

            http_axios_delete(url)
                .then(response => {
                    dispatch({ type: 'UPLOAD_DELETE_RECEIVED' });
                })
                .catch(function (e) {
                    console.log(e);
                });

            dispatch({ type: 'UPLOAD_DELETE_REQUEST' });
        }
    },

    import: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        var url = ''
        if (appState) {

            url = 'api/Upload/Import';

            http_axios_post(url)
                .then(response => {
                    dispatch({ type: 'UPLOAD_IMPORT_RECEIVED' });
                })
                .catch(function (e) {
                    console.log(e);
                });

            dispatch({ type: 'UPLOAD_IMPORT_REQUEST' });
        }
    },

};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const unloadedState: UploadState = {
    isLoading: false,
    triggerLoad: false,
    expeditions: []
};

export const reducer: Reducer<UploadState> = (state: UploadState | undefined, incomingAction: Action): UploadState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'UPLOAD_FILE_REQUEST':
            return {
                isLoading: true,
                triggerLoad: state.triggerLoad,
                expeditions: state.expeditions,
            };        
        case 'UPLOAD_FILE_RECEIVED':
            return {                
                isLoading: false,
                triggerLoad: true,
                expeditions: state.expeditions,
            };
        case 'UPLOAD_LOAD_REQUEST':
            return {
                isLoading: state.isLoading,
                triggerLoad: state.triggerLoad,
                expeditions: state.expeditions,
            };
        case 'UPLOAD_LOAD_RECEIVED':
            return {
                isLoading: state.isLoading,
                triggerLoad: state.triggerLoad,
                expeditions: action.expeditions,
            };
        case 'UPLOAD_DELETE_REQUEST':
            return {
                isLoading: state.isLoading,
                triggerLoad: state.triggerLoad,
                expeditions: state.expeditions,
            };
        case 'UPLOAD_DELETE_RECEIVED':
            return {
                isLoading: state.isLoading,
                triggerLoad: true,
                expeditions: state.expeditions,
            };
        case 'UPLOAD_IMPORT_REQUEST':
            return {
                isLoading: state.isLoading,
                triggerLoad: state.triggerLoad,
                expeditions: state.expeditions,
            };
        case 'UPLOAD_IMPORT_RECEIVED':
            return {
                isLoading: state.isLoading,
                triggerLoad: true,
                expeditions: state.expeditions,
            };
            
            
        case 'UPLOAD_RESET_TRIGGER_LOAD':
            return {
                isLoading: state.isLoading,
                triggerLoad: false,
                expeditions: state.expeditions,
            };
            
        default:
            return state;
    }
};
