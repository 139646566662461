import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import { GeoJSONSource } from 'mapbox-gl';
import { Feature, Geometry, FeatureCollection } from 'geojson'
import 'mapbox-gl/dist/mapbox-gl.css';

import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';
import { Box, Theme, useMediaQuery } from '@mui/material';

import CardHeader from './cardHeader';

import { green } from '@mui/material/colors';


import { 
	LocationOnOutlined as LocationOnOutlinedIcon,
	Person2Outlined as Person2OutlinedIcon,
	LocalPhoneOutlined as LocalPhoneOutlinedIcon,
	MonetizationOnOutlined as MonetizationOnOutlinedIcon,
	CellTower as CellTowerIcon,
	MoveToInboxOutlined as MoveToInboxOutlinedIcon,
	OutboxOutlined as OutboxOutlinedIcon,
	AccessTimeOutlined as AccessTimeOutlinedIcon,
} 
from '@mui/icons-material';


import * as Models from "../../models/Models";
import moment from 'moment';
import StatusCard from './statusBar/statusCard';
import { HttpTransportType, HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import { AwbTrackingRealTime } from '../../models/Models';
const _ = require('lodash');


const link = 'https://mui.com/system/typography/';

function AwbTypography(props : {awb: Models.Awb}) {

	const [awb, setAwb] = useState<Models.Awb>(props.awb);
	const is_mobile = useMediaQuery((theme:Theme) => theme.breakpoints.down('sm'));	

	const mapConnectionRef = useRef<{ value: HubConnection | null }>({ value: null });
	
	const [signalRState, setSignalRState] = useState<boolean>(false);

	const mapContainerRef = useRef<HTMLDivElement>(null);
	const mapRef = useRef<{ value: mapboxgl.Map | null }>({ value: null });
	

	const tracking_realtime = useMemo(() : boolean => {
		return awb.tracking_realtime;
	}, [awb.tracking_realtime]);
	
	const mapBounds = useMemo(() : mapboxgl.LngLatBounds | undefined => {		
			
		if (props.awb.shipper_latitude && props.awb.shipper_longitude)
		{
			const minLng = Math.min(props.awb.shipper_longitude, props.awb.receiver_longitude);
			const maxLng = Math.max(props.awb.shipper_longitude, props.awb.receiver_longitude);
			const minLat = Math.min(props.awb.shipper_latitude, props.awb.receiver_latitude);
			const maxLat = Math.max(props.awb.shipper_latitude, props.awb.receiver_latitude);

			const bounds = new mapboxgl.LngLatBounds(
				[minLng, minLat], // Southwest corner
				[maxLng, maxLat]  // Northeast corner
			);			
			return bounds;
		}
		
		return undefined;

	  }, [props.awb]);

	const connectSignalR = useCallback(() => {
        
		if (mapConnectionRef.current.value &&
			(mapConnectionRef.current.value.state === HubConnectionState.Connected || mapConnectionRef.current.value.state === HubConnectionState.Connecting))
		{
			console.log("SignalR connection already connected. Exit!");
			return;
		}
		
		console.log('connectSignalR');

		//https://docs.microsoft.com/en-us/aspnet/core/signalr/scale?view=aspnetcore-5.0
		//https://docs.microsoft.com/en-us/aspnet/core/signalr/configuration?view=aspnetcore-5.0&tabs=dotnet#configure-additional-options
		//skip negociation si folosim doar websokets ca sa evitam sticky sessions
		mapConnectionRef.current.value = new HubConnectionBuilder()
			.withUrl(`/hubs/awb?tn=${props.awb.tracking_number}`, {
				skipNegotiation: true,
				transport: HttpTransportType.WebSockets
			})
			.withAutomaticReconnect({
				nextRetryDelayInMilliseconds: retryContext => {
					console.log(`reconnecting for ${retryContext.elapsedMilliseconds} milliseconds`);
					return 5000 + Math.random() * 10000; //wait 5 seconds + random between 0 and 10 seconds
					/*
					if (retryContext.elapsedMilliseconds < 60000) {
						// If we've been reconnecting for less than 60 seconds so far,
						// wait between 0 and 10 seconds before the next reconnect attempt.
						return Math.random() * 10000;
					} else {
						// If we've been reconnecting for more than 60 seconds so far, stop reconnecting.
						return null;
					}*/
				}
			})
			.build();


			mapConnectionRef.current.value.start()
			.then(result => {
				
				console.log('Connected!');
				setSignalRState(true);
				if (mapConnectionRef.current.value) {
					mapConnectionRef.current.value.onclose(() => {
						setSignalRState(false);
						console.log('signalr disconected!');
						//console.log('signalr disconected! Reconnectinggg...');
						//this.connectSignalR();
					})
					mapConnectionRef.current.value.onreconnecting(error => {
						
						console.log(`signalr reconnecting due to error: ${error}`);                    
					});                    
					mapConnectionRef.current.value.on('Notification_Send', (notification_json) => {

						var notif = notification_json as AwbTrackingRealTime;

						setAwb((prevState) => ({
							...prevState,
							...notif,
							status_journal: _.cloneDeep(notif.status_journal)
						}));
					});
				}

			})
			.catch(e => {
				console.log('Connection failed: ', e);                
			});        
	}, [props.awb.tracking_number])

    const disconnectSignalR = useCallback(() => {
        
        if (mapConnectionRef.current.value != null &&
            (mapConnectionRef.current.value.state === HubConnectionState.Connected || mapConnectionRef.current.value.state === HubConnectionState.Connecting))
        {
            console.log('stop signalr connection')
            mapConnectionRef.current.value.stop();
        }
    }, []);

	useEffect(() => {
		const map = mapRef?.current?.value;
		if (map){
			const source = map.getSource('car-point') as GeoJSONSource;			

			if (source){
				var features: Array<Feature<Geometry>> = [];

				if (awb.shipper_latitude && awb.shipper_longitude){
					features.push({
						type: 'Feature',
						geometry: {
							type: 'Point',
						coordinates: [awb.shipper_longitude, awb.shipper_latitude]
						},
						properties: {
							title: 'Curier'
						}
					})
				}

				const featuresCollection: FeatureCollection = {
					type: 'FeatureCollection',
					features: features
				}

				source.setData(featuresCollection);
			}
		}
	}, [awb.shipper_latitude, awb.shipper_longitude]);

	const map_receiver_coordinates = useMemo(() : any => {

		return [props.awb.receiver_longitude, props.awb.receiver_latitude];

	}, [props.awb.receiver_longitude, props.awb.receiver_latitude]);

	const map_initial_shipper_coordinates = useMemo(() : any => {

		if (props.awb.shipper_longitude && props.awb.shipper_latitude){
			return [props.awb.shipper_longitude, props.awb.shipper_latitude];
		}
		else {
			return [];
		}

	}, [props.awb.shipper_longitude, props.awb.shipper_latitude]);

	useEffect(() => {
		// TO MAKE THE MAP APPEAR YOU MUST
		// ADD YOUR ACCESS TOKEN FROM
		// https://account.mapbox.com
		mapboxgl.accessToken = `${process.env.REACT_APP_MAPBOX_API_KEY}`;
	
		if (mapContainerRef.current ){
				mapRef.current.value = new mapboxgl.Map({
				container: mapContainerRef.current,
				style: 'mapbox://styles/mapbox/streets-v12',
				center: map_receiver_coordinates, // starting position [lng, lat]
				zoom: 11, // starting zoom
				bounds: mapBounds,				
				fitBoundsOptions: {
					padding: 40 // padding to keep the bounds away from the edge of the map
				},
				scrollZoom: false
			});

			mapRef.current.value.on('load', () => {
				mapRef?.current?.value?.loadImage(
				'https://docs.mapbox.com/mapbox-gl-js/assets/custom_marker.png',
				(error, image) => {
					if (error) throw error;
					if (image){
						mapRef?.current?.value?.addImage('custom-marker', image);
					}
				});

				mapRef?.current?.value?.loadImage(
					'/icons/car_icon_40px.png',
					(error, image) => {
						if (error) throw error;
						if (image){
							mapRef?.current?.value?.addImage('car-marker', image);
						}
					});
		
				mapRef?.current?.value?.addSource('delivery-point', {
					type: 'geojson',
					generateId: true,
					data: {
						type: 'FeatureCollection',
						features: [{
							type: 'Feature',
							geometry: {
								type: 'Point',
							coordinates: map_receiver_coordinates
							},
							properties: {
								title: 'Livrare'
							}
						}]
					}
				});

				mapRef?.current?.value?.addSource('car-point', {
					type: 'geojson',
					generateId: true,
					data: {
						type: 'FeatureCollection',
						features: [{
							type: 'Feature',
							geometry: {
								type: 'Point',
							coordinates: map_initial_shipper_coordinates
							},
							properties: {
								title: 'Curier'
							}
						}]
					}
				});
	
	
				mapRef?.current?.value?.addLayer({
					id: 'delivery-point',
					type: 'symbol',
					source: 'delivery-point',
					layout: {
						'icon-image': 'custom-marker',
						'text-field': ['get', 'title'],
						'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
						'text-offset': [0, 1.25],
						'text-anchor': 'top'
					}
				});

				mapRef?.current?.value?.addLayer({
					id: 'car-point',
					type: 'symbol',
					source: 'car-point',
					layout: {
						'icon-image': 'car-marker',
						'text-field': ['get', 'title'],
						'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
						'text-offset': [0, 1.25],
						'text-anchor': 'top'
					}
				});
			});
		}
	}, [mapBounds, map_receiver_coordinates, map_initial_shipper_coordinates]);

	useEffect(() => {

		if (props.awb.tracking_number && tracking_realtime){
			connectSignalR();
		}
		else {
			disconnectSignalR();
		}
	}, [props.awb.tracking_number, tracking_realtime, connectSignalR, disconnectSignalR]);

	const maxbox_map_height = is_mobile ? '400px' : '500px';

	return (
		<Stack spacing={3}>
			<Card>
				<CardHeader title={`# ${awb.awb} / ${awb.tracking_number}`} subtitle={`Date: ${moment(awb.awb_date).format('DD.MM.YYYY HH:mm:ss')}`}>				
					<MuiLogo link={link} />					
				</CardHeader>

				{awb.ramburs &&
					<Box sx={{ display: 'flex', justifyContent: 'center' }}>
						<RambursSection amount={awb.ramburs}/>
					</Box>
				}

				<Grid container spacing={2}>
					<Grid item xs={12} sm={12} md={12}>
						<StatusCard caller = 'awb' status_journal={awb.status_journal}>
							{(awb.delivery_estimated_date && awb.delivery_estimated_date !== 'N/A') &&
							<Typography variant="subtitle2" sx={{ mt: 2}}>
								<AccessTimeOutlinedIcon></AccessTimeOutlinedIcon>
								{`Livrare estimata in ${awb.delivery_estimated_date}`}
							</Typography>
							}
						</StatusCard>
					</Grid>

					<Grid item xs={12} sm={12} md={6}>
						<TypographyCard>
							<Typography variant="subtitle1"><OutboxOutlinedIcon/> Expeditor</Typography>
							<Divider
								sx={{
									my: 1,
								}}
							/>
							<Typography variant="subtitle1" gutterBottom>
								{awb.sender_name}
							</Typography>
							<Typography variant="subtitle2" gutterBottom>
								<LocationOnOutlinedIcon></LocationOnOutlinedIcon>
								{`Adresa: ${awb.sender_address}`}
							</Typography>
							<Typography variant="subtitle2" gutterBottom>
								<Person2OutlinedIcon></Person2OutlinedIcon>
								{`Nume: ${awb.sender_contact_person}`}
							</Typography>
							<Typography variant="subtitle2" gutterBottom>
								<LocalPhoneOutlinedIcon></LocalPhoneOutlinedIcon>
								{`Telefon: ${awb.sender_contact_phone}`}
							</Typography>
							
						</TypographyCard>
					</Grid>

					<Grid item xs={12} sm={12} md={6}>
						<TypographyCard>
							<Typography variant="subtitle1"><MoveToInboxOutlinedIcon/> Destinatar</Typography>
							<Divider
								sx={{
									my: 1,
								}}
							/>
							<Typography variant="subtitle1" gutterBottom>
								{awb.receiver_name}
							</Typography>
							<Typography variant="subtitle2" gutterBottom>
								<LocationOnOutlinedIcon></LocationOnOutlinedIcon>
								{`Adresa: ${awb.receiver_address}`}
							</Typography>
							<Typography variant="subtitle2" gutterBottom>
								<Person2OutlinedIcon></Person2OutlinedIcon>
								{`Nume: ${awb.receiver_contact_person}`}
							</Typography>
							<Typography variant="subtitle2" gutterBottom>
								<LocalPhoneOutlinedIcon></LocalPhoneOutlinedIcon>
								{`Telefon: ${awb.receiver_contact_phone}`}
							</Typography>
							
						</TypographyCard>
					</Grid>

					<Grid item xs={12} sm={12} md={12}>
						<TypographyCard>
							<Typography variant="subtitle1">
								{signalRState ?
								<>
									<CellTowerIcon sx={{ color: green[500] }}  className="pulse"/>
									&nbsp;Harta live
								</>
								:
								"Harta"
								}
							</Typography>
							{signalRState &&
								<>
									<Typography variant="subtitle2" gutterBottom>
										{`Livrare estimata in ${awb.delivery_estimated_date}.`}
										{awb.navigation_order === 1 ? ' Curierul se indreapta catre dumneavoastra.' : ' Inainte sa ajunga la dumneavoastra curierul face si alte opriri.'}
									</Typography>
								</>
							}
							<Divider
								sx={{
									my: 1,
								}}
							/>
							<div
								id="map"
								style={{ height: maxbox_map_height }}
								ref={mapContainerRef}
								className="map-container"
							/>
						</TypographyCard>
					</Grid>

					
				</Grid>
			</Card>

		</Stack>
	);
}

function TypographyCard({ children }: {children: React.ReactNode}) {
	return (
		<Card
			variant="outlined"
			sx={{
				'&:hover': {
					boxShadow: 26,					
				},
				pt: 1,
			}}
		>
			{children}
		</Card>
	);
}

function MuiLogo({ link } : {link: string}) {
	return (
		<Tooltip title="Reference" placement="left">
			<Avatar
				component={Link}
				href={link}
				target="_blank"
				alt="MUI Logo"
				sx={{
					bgcolor: 'transparent',
					border: '2px solid #2196F3',
				}}
			>
				<svg width="500" height="500" viewBox="0 0 500 500" fill="none" xmlns="http://www.w3.org/2000/svg">
					<g clipPath="url(#clip0)">
						<path d="M100 260.9V131L212.5 195.95V239.25L137.5 195.95V282.55L100 260.9Z" fill="#2196F3" />
						<path
							d="M212.5 195.95L325 131V260.9L250 304.2L212.5 282.55L287.5 239.25V195.95L212.5 239.25V195.95Z"
							fill="#347AC9"
						/>
						<path d="M212.5 282.55V325.85L287.5 369.15V325.85L212.5 282.55Z" fill="#2196F3" />
						<path
							d="M287.5 369.15L400 304.2V217.6L362.5 239.25V282.55L287.5 325.85V369.15ZM362.5 195.95V152.65L400 131V174.3L362.5 195.95Z"
							fill="#347AC9"
						/>
					</g>
					<defs>
						<clipPath id="clip0">
							<rect width="300" height="238.3" fill="white" transform="translate(100 131)" />
						</clipPath>
					</defs>
				</svg>
			</Avatar>
		</Tooltip>
	);
}


function RambursSection({ amount }: {amount:string}) {

	const color = 'success.main';

	return (
		<Stack direction="row" spacing={3} alignItems="center" sx={{ pb: 2 }}>
			<MonetizationOnOutlinedIcon
				sx={{
					fontSize: 60,
					color,
				}}
				color="disabled"
			/>
			<span>
				<Typography color={color} variant="h5">
					{'Ramburs'}
				</Typography>
				<Typography fontSize={30}>{`${amount}`}</Typography>
			</span>
		</Stack>
	);
}

export default AwbTypography;
